import React, { Component } from "react";
import { Col, Form, Row, Switch, InputNumber, Card } from "antd";
import _ from "lodash";
import TextArea from "antd/lib/input/TextArea";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";

const InteractionLimitEditor = ({ block, setBlock, disableWrite }: any) => {
    return (
        <KTWrapper feature="Interaction_Limit_Handling">
            <Card
                title={<h3>Interaction Limit Handling</h3>}
                extra={
                    <Switch
                        disabled={disableWrite}
                        checked={block.interactionLimit?.enabled}
                        onChange={(val) => {
                            let tmpBlock = _.cloneDeep(block);
                            if (tmpBlock.interactionLimit === undefined)
                                tmpBlock.interactionLimit = {};
                            tmpBlock.interactionLimit.enabled = val;
                            setBlock(tmpBlock);
                        }}
                    />
                }
                bodyStyle={{
                    padding: block.interactionLimit?.enabled ? "12px" : "0px",
                }}
            >
                {/*Switch field for enable disable */}

                {block.interactionLimit?.enabled && (
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Count position change">
                                <Switch
                                    disabled={disableWrite}
                                    checked={
                                        block.interactionLimit
                                            ?.countPositionChange ?? true
                                    }
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.countPositionChange =
                                            val;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Can swap  drag & drop">
                                <Switch
                                    disabled={disableWrite}
                                    checked={
                                        block.interactionLimit
                                            ?.canSwapDragDrop ?? true
                                    }
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.canSwapDragDrop =
                                            val;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Minimum Interaction Limit">
                                <InputNumber
                                    disabled={disableWrite}
                                    value={block.interactionLimit?.min}
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.min = val;
                                        setBlock(tmpBlock);
                                    }}
                                    min={0}
                                    max={block.interactionLimit?.max ?? 5}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Maximum Interaction Limit">
                                <InputNumber
                                    disabled={disableWrite}
                                    value={block.interactionLimit?.max}
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.max = val;
                                        setBlock(tmpBlock);
                                    }}
                                    min={block.interactionLimit?.min ?? 0}
                                    defaultValue={5}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="enable warning function">
                                <Switch
                                    disabled={disableWrite}
                                    checked={block.interactionLimit?.isFunction}
                                    onChange={(val) => {
                                        let tmpBlock = _.cloneDeep(block);
                                        if (
                                            tmpBlock.interactionLimit ===
                                            undefined
                                        )
                                            tmpBlock.interactionLimit = {};
                                        tmpBlock.interactionLimit.isFunction =
                                            val;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {block.interactionLimit?.isFunction && (
                            <Col span={24}>
                                <Form.Item label="warning function">
                                    <TextArea
                                        disabled={disableWrite}
                                        value={
                                            block.interactionLimit
                                                ?.warningFunction
                                        }
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            if (
                                                tmpBlock.interactionLimit ===
                                                undefined
                                            )
                                                tmpBlock.interactionLimit = {};
                                            tmpBlock.interactionLimit.warningFunction =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                        rows={4}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {!block.interactionLimit?.isFunction && (
                            <Col span={24}>
                                {/*Textarea antd field for warning message when user exceeds max attempt count */}
                                <Form.Item label="Warning Message">
                                    <TextArea
                                        disabled={disableWrite}
                                        value={
                                            block.interactionLimit
                                                ?.maxWarningMessage ??
                                            `function warningFunction() {
                                                    console.log("here in warning function");
                                                    return [
                                                      {
                                                        type: "PLAY_SOUND",
                                                        props: { url: "", isAudioUrl: false },
                                                      },
                                                      {
                                                        type: "SHOW_STICKER",
                                                        props: { sticker: "", stickerProps: {} },
                                                      },
                                                      {
                                                        type: "SHOW_TOAST",
                                                        props: { text: "" },
                                                      },
                                                    ];
                                                }`
                                        }
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            if (
                                                tmpBlock.interactionLimit ===
                                                undefined
                                            )
                                                tmpBlock.interactionLimit = {};
                                            tmpBlock.interactionLimit.maxWarningMessage =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                        rows={4}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {!(block.interactionLimit?.canSwapDragDrop ?? true) && (
                            <Col span={24}>
                                <Form.Item label="swap drag drop function">
                                    <TextArea
                                        disabled={disableWrite}
                                        value={
                                            block.interactionLimit
                                                ?.swapFunction ??
                                            `function warningFunction() {
                                                console.log("here in warning function");
                                                return [
                                                  {
                                                    type: "PLAY_SOUND",
                                                    props: { url: "", isAudioUrl: false },
                                                  },
                                                  {
                                                    type: "SHOW_STICKER",
                                                    props: { sticker: "", stickerProps: {} },
                                                  },
                                                  {
                                                    type: "SHOW_TOAST",
                                                    props: { text: "" },
                                                  },
                                                ];
                                            }`
                                        }
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            if (
                                                tmpBlock.interactionLimit ===
                                                undefined
                                            )
                                                tmpBlock.interactionLimit = {};
                                            tmpBlock.interactionLimit.swapFunction =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                        rows={4}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                )}
            </Card>
        </KTWrapper>
    );
};

export default InteractionLimitEditor;

import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Button, Input, Select, Popover } from "antd";
import {
    BorderTopOutlined,
    BorderBottomOutlined,
    BorderLeftOutlined,
    BorderRightOutlined,
    EditOutlined,
    BorderInnerOutlined,
    TableOutlined,
    BorderOuterOutlined,
} from "@ant-design/icons";
import { BlockPicker } from "react-color";
import _ from "lodash";
import { areAllElementsSame } from "./helper";

const BORDER_TYPES = [
    {
        value: "ALL_BORDERS",
        icon: TableOutlined,
        label: "All Borders",
    },
    {
        value: "OUTER_BORDERS",
        icon: BorderOuterOutlined,
        label: "Outer Borders",
    },
    {
        value: "INNER_BORDERS",
        icon: BorderInnerOutlined,
        label: "Inner Borders",
    },
    {
        value: "TOP_BORDERS",
        icon: BorderTopOutlined,
        label: "Top Borders",
    },
    {
        value: "BOTTOM_BORDERS",
        icon: BorderBottomOutlined,
        label: "Bottom Borders",
    },
    {
        value: "LEFT_BORDERS",
        icon: BorderLeftOutlined,
        label: "Left Borders",
    },
    {
        value: "RIGHT_BORDERS",
        icon: BorderRightOutlined,
        label: "Right Borders",
    },
];

const BORDER_STYLES = [
    { value: "SOLID", label: "Solid" },
    { value: "DASHED", label: "Dashed" },
    { value: "DOTTED", label: "Dotted" },
    { value: "DOUBLE", label: "Double" },
];

type Border = {
    color: string;
    style: string;
    width: number;
    radius: number;
    enabled: boolean;
    opacity: number;
};

type Cell = {
    border: {
        top?: Border;
        left?: Border;
        right?: Border;
        bottom?: Border;
        enabled: boolean;
        selective: boolean;
    };
};

function getMostCommonBorderStyle(cells: Cell[]): {
    color: string;
    width: number;
} {
    const borderCounts: { [key: string]: number } = {};
    const widthCounts: { [key: number]: number } = {};

    for (const cell of cells) {
        if (cell.border && cell.border.enabled) {
            const borders = ["top", "left", "right", "bottom"];
            for (const side of borders) {
                const border = cell.border[side as keyof typeof cell.border];
                if (border && border.enabled) {
                    const key = `${border.color}_${border.width}`;
                    borderCounts[key] = (borderCounts[key] || 0) + 1;
                    widthCounts[border.width] =
                        (widthCounts[border.width] || 0) + 1;
                }
            }
        }
    }

    let mostCommonKey = "";
    let maxCount = 0;

    for (const [key, count] of Object.entries(borderCounts)) {
        if (count > maxCount) {
            mostCommonKey = key;
            maxCount = count;
        }
    }

    const [mostCommonColor, mostCommonWidth] = mostCommonKey.split("_");

    return {
        color: mostCommonColor || "#000000",
        width: parseInt(mostCommonWidth, 10) || 1,
    };
}
const GranularBorderTable = ({
    setBorderProperty,
    table,
    setProperty,
    path,
    selectedCells,
    isGranularBorderOpen,
}: any) => {
    const originalTableDataRef = useRef<any>(null);

    useEffect(() => {
        if (table && !originalTableDataRef.current) {
            originalTableDataRef.current = _.cloneDeep(table);
        }
    }, [table]);

    useEffect(() => {
        if (selectedCells && selectedCells.length > 0) {
            const initialConfig = getMostCommonBorderStyle(selectedCells);
            if (initialConfig) {
                setBorderConfig({
                    type: "ALL_BORDERS",
                    color: initialConfig.color,
                    width: initialConfig.width,
                    style: "SOLID",
                    radius: 0,
                    opacity: 1,
                });
            }
        }
    }, [isGranularBorderOpen]);

    const [borderConfig, setBorderConfig] = useState({
        type: "ALL_BORDERS",
        color: "#000000",
        width: 1,
        style: "SOLID",
        radius: 0,
        opacity: 1,
    });
    const handleConfigChange = (key, value): any => {
        const updatedConfig = {
            ...borderConfig,
            [key]: value,
        };
        setBorderConfig(updatedConfig);
        setBorderProperty({
            subType: "GRANULAR",
            value: updatedConfig,
            is_enabled: true,
        });
    };
    const handleReset = () => {
        if (originalTableDataRef.current) {
            setBorderProperty({
                subType: "RESET",
                value: borderConfig,
                is_enabled: true,
            });
        }
    };
    return (
        <div className="advanced-border-control">
            {/* Border Type Selection */}
            <Row gutter={[8, 8]} style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "8px",
                            justifyContent: "center",
                        }}
                    >
                        {BORDER_TYPES.map((type) => (
                            <Button
                                key={type.value}
                                type={
                                    borderConfig.type === type.value
                                        ? "primary"
                                        : "default"
                                }
                                onClick={() =>
                                    handleConfigChange("type", type.value)
                                }
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "8px",
                                    height: "60px",
                                    width: "60px",
                                    flexGrow: 0,
                                    flexShrink: 0,
                                    flexBasis: "25.3333%",
                                }}
                            >
                                <type.icon style={{ fontSize: "25px" }} />
                                <span
                                    style={{ fontSize: "10px", marginTop: 4 }}
                                >
                                    {type.label}
                                </span>
                            </Button>
                        ))}
                    </div>
                </Col>
            </Row>

            {/* Border Configuration */}
            <Row gutter={[8, 8]} align="middle">
                <Col span={5}>Color</Col>
                <Col span={5}>Width</Col>
                <Col span={5}>Style</Col>
                <Col span={5}>Radius</Col>
                <Col span={4}>Opacity</Col>
            </Row>

            <Row gutter={[8, 8]} align="middle">
                {/* Color Picker */}
                <Col span={5}>
                    <Popover
                        content={
                            <BlockPicker
                                colors={[]}
                                color={borderConfig.color}
                                onChangeComplete={(color) =>
                                    handleConfigChange("color", color.hex)
                                }
                                triangle="hide"
                            />
                        }
                        title="Border Color"
                        trigger="click"
                    >
                        <Button
                            style={{
                                background: borderConfig.color,
                                width: "100%",
                            }}
                            icon={<EditOutlined />}
                            block
                        />
                    </Popover>
                </Col>

                {/* Width Input */}
                <Col span={5}>
                    <Input
                        type="number"
                        value={borderConfig.width}
                        placeholder="Width"
                        onChange={(e) =>
                            handleConfigChange("width", Number(e.target.value))
                        }
                    />
                </Col>

                {/* Border Style Select */}
                <Col span={5}>
                    <Select
                        value={borderConfig.style}
                        placeholder="Style"
                        onChange={(val) => handleConfigChange("style", val)}
                        style={{ width: "100%" }}
                    >
                        {BORDER_STYLES.map((style) => (
                            <Select.Option
                                key={style.value}
                                value={style.value}
                            >
                                {style.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>

                {/* Radius Input */}
                <Col span={5}>
                    <Input
                        type="number"
                        value={areAllElementsSame(selectedCells, [
                            "border",
                            ...path,
                            "radius",
                        ])}
                        placeholder="Radius"
                        onChange={(e) => {
                            let radius = Number(e.target.value);
                            setProperty({
                                type: "BORDER",
                                subType: "radius",
                                path,
                                value: radius,
                            });
                        }}
                    />
                </Col>

                {/* Opacity Input */}
                <Col span={4}>
                    <Input
                        type="number"
                        value={borderConfig.opacity}
                        placeholder="Opacity"
                        onChange={(e) =>
                            handleConfigChange(
                                "opacity",
                                Number(e.target.value),
                            )
                        }
                    />
                </Col>
            </Row>
            <Row justify="end" style={{ marginTop: 16 }}>
                <Col>
                    <Button type="default" onClick={handleReset}>
                        Reset
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default GranularBorderTable;

import { createPluginFactory } from "@udecode/plate-core";

export const ELEMENT_BUTTON = "button";

export const createButtonPlugin = createPluginFactory<any>({
    key: ELEMENT_BUTTON,
    isElement: true,
    isVoid: true,
    then: (editor, { type }) => ({
        deserializeHtml: {
            rules: [
                {
                    validNodeName: "BUTTON",
                },
            ],
            getNode: (el) => ({
                type,
                // url: el.getAttribute('src'),
            }),
        },
    }),
});

import React, { useState } from "react";
import { Col, Form, Input, Row, Select, Switch, message } from "antd";
import ComponentDetails from "../common/ComponentDetails";
import _, { cloneDeep, set } from "lodash";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CopyIcon, RenderUpload } from "src/components";

const Settings = (props) => {
    const {
        block,
        setBlock,
        disableWrite,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        renderLinkSelect,
    } = props;
    const {
        data: {
            visible,
            is_absolute,
            button_text,
            full_width,
            position,
            remove_on_submit,
        },
    } = block;

    return (
        <>
            <Col span={12}>
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                    showTopicEvents={false}
                />
            </Col>
            <Col span={12}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "4px",
                    }}
                >
                    <h4>
                        {`Settings : `}
                        <CopyIcon
                            disabled={disableWrite}
                            isButton={true}
                            onClick={() => {
                                message.info(
                                    `Copied Button data to clipboard!`,
                                );
                                navigator.clipboard.writeText(
                                    JSON.stringify(block.data),
                                );
                            }}
                        />
                    </h4>
                    <Form.Item label="Should remove after submit?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={remove_on_submit}
                            onChange={(val) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "remove_on_submit"],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                    }}
                >
                    <Form.Item label="Default visible?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={visible}
                            onChange={(val) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "visible"],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Is absolute?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={is_absolute}
                            onChange={(val) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "is_absolute"],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Full width?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={full_width}
                            onChange={(val) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "full_width"],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </div>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Button default text"
                            rules={[{ required: true }]}
                        >
                            <Input
                                disabled={disableWrite}
                                value={button_text}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "button_text"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Position of button">
                            <Select
                                value={position}
                                placeholder="Select type"
                                options={[
                                    {
                                        label: "Center",
                                        value: "center",
                                    },
                                    {
                                        label: "Left",
                                        value: "flex-start",
                                    },
                                    {
                                        label: "Right",
                                        value: "flex-end",
                                    },
                                ]}
                                style={{
                                    width: "150px",
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "position"],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default Settings;

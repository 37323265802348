import { compose, getLastStringFromUrl } from "src/helpers";
import { useLazyQuery } from "@apollo/client";
import { checkBlockValid } from "src/modules/worksheet/components/blocks";
import {
    WORKSHEET_SYNC_BLOCKS_QUERY,
    INSERT_SINGLE_WORKSHEET_BLOCK_MAP,
    GET_BLOCK_QUERY,
    SYNC_BLOCK_SEARCH_QUERY,
} from "../../graphql";
import { useMutation } from "@apollo/client";
import {
    withDeleteBlocks,
    withDeleteWorksheetBlockMap,
    withHookForWorksheet,
    withWorksheetStats,
    withHookForWorksheetStories,
    withInsertWorksheetBlockMap,
    withUpdateWorksheet,
    withUpdateWorksheetUserStats,
    withVersionModelMap,
    withWorksheetBlocks,
    withHookForWorksheetAudios,
    withUpdateWorksheetBlock,
    withInsertWorksheetBlock,
    withWorksheetPublishedBlocks,
} from "src/modules/worksheet/operations";
import { message, Modal, Typography } from "antd";
import _ from "lodash";
import { calculateMultiStepMovementVectorsWithCachedGaps } from "../../components/blocks/renderers/Figma/renderEditor/PuzzleSettings/utils";
import { useRouter } from "src/helpers";
import { useEffect, useState } from "react";
import CreateView from "../../components/WorksheetEditor";
import { useAuth0 } from "@auth0/auth0-react";
import {
    generateAudios,
    addGenerateLogicForVarAudio,
} from "../../components/WorksheetEditor/helpers/generateAudios";
import axios from "axios";
import { captureException } from "@sentry/react";
import {
    graphQLClientUS,
    publishToSecondaryHasuraEndpoints,
} from "../../helpers/publishToSecondaryHasuraEndpoints";
import { WORKSHEET_STATS } from "../../graphql";

const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getV4WorksheetBlockMapObject = ({
    blockData,
    worksheetData,
}: any) => {
    return blockData.map((block: any, idx: number) => {
        const { worksheet_block_map_id } = block;
        const children = block?.children?.length
            ? {
                  children: {
                      data: block.children?.map((child: any, index: number) => {
                          const gChildren = child?.children?.length
                              ? {
                                    children: {
                                        data: child.children?.map(
                                            (child: any, index: number) => {
                                                return {
                                                    ..._.omit(child, [
                                                        "tmpId",
                                                        "__typename",
                                                        "tags",
                                                        "children",
                                                    ]),
                                                    order: index + 1,
                                                };
                                            },
                                        ),
                                        on_conflict: {
                                            constraint: "block_pkey",
                                            update_columns: [
                                                "data",
                                                "type",
                                                "order",
                                                "backend",
                                            ],
                                        },
                                    },
                                }
                              : {};

                          return {
                              ...gChildren,
                              ..._.omit(child, [
                                  "tmpId",
                                  "__typename",
                                  "tags",
                                  "children",
                              ]),
                              order: index + 1,
                          };
                      }),
                      on_conflict: {
                          constraint: "block_pkey",
                          update_columns: ["data", "type", "order", "backend"],
                      },
                  },
              }
            : {};

        return {
            ...(worksheet_block_map_id ? { id: worksheet_block_map_id } : {}),
            order: idx + 1,
            ...worksheetData,
            block: {
                data: {
                    ...children,
                    ..._.omit(block, [
                        "tmpId",
                        "worksheet_block_map_id",
                        "__typename",
                        "children",
                        "tags",
                    ]),
                },
                on_conflict: {
                    constraint: "block_pkey",
                    update_columns: ["data", "type", "backend"],
                },
            },
        };
    });
};

export const getWorksheetBlockIdMap = ({ blockData, worksheetData }: any) => {
    return blockData.map((block: any, idx: number) => {
        const { worksheet_block_map_id, levelId } = block;

        return {
            ...(worksheet_block_map_id ? { id: worksheet_block_map_id } : {}),
            order: idx + 1,
            ...worksheetData,
            ...(levelId
                ? { block_id: levelId }
                : {
                      block: {
                          data: block,
                          on_conflict: {
                              constraint: "block_pkey",
                              update_columns: ["data", "type", "backend"],
                          },
                      },
                  }),
        };
    });
};

export const applyChanges = (block: any, changesList: any): any => {
    if (!changesList || typeof changesList !== "object") {
        return block;
    }

    const mutableBlock = _.cloneDeep(block);

    // Sort paths to ensure parent deletions are handled after child deletions
    const sortedPaths = Object.keys(changesList).sort(
        (a, b) => b.length - a.length,
    );

    sortedPaths.forEach((path) => {
        const value = changesList[path];
        try {
            const keys = path.split(/\.(?![^\[]*\])/);
            let target = mutableBlock;
            const lastKeyIndex = keys.length - 1;

            // Navigate to the parent object
            for (let i = 0; i < lastKeyIndex; i++) {
                const key = keys[i];
                const { baseKey, conditions } = parseKeySegment(key);

                if (!target[baseKey]) {
                    if (value === null) return; // Parent doesn't exist, nothing to delete
                    target[baseKey] = conditions.length > 0 ? [] : {};
                }

                target = target[baseKey];
                if (conditions.length > 0) {
                    for (const condition of conditions) {
                        const nextTarget = processCondition(
                            target,
                            condition,
                            value !== null,
                        );
                        if (!nextTarget) {
                            if (value === null) return; // Parent doesn't exist, nothing to delete
                            const newTarget =
                                i === lastKeyIndex - 1
                                    ? typeof value === "object"
                                        ? {}
                                        : null
                                    : [];
                            target = createOrUpdateArrayElement(
                                target,
                                condition,
                                newTarget,
                            );
                        } else {
                            target = nextTarget;
                        }
                    }
                }
            }

            // Handle the last key
            const lastKey = keys[lastKeyIndex];
            const { baseKey, conditions } = parseKeySegment(lastKey);

            if (value === null) {
                // Handle deletion
                if (conditions.length > 0) {
                    if (Array.isArray(target[baseKey])) {
                        if (conditions[0].includes(":")) {
                            const [condKey, condVal] = conditions[0].split(":");
                            target[baseKey] = target[baseKey].filter(
                                (item) =>
                                    String(item[condKey]) !== String(condVal),
                            );
                        } else {
                            const index = parseInt(conditions[0], 10);
                            if (index >= 0 && index < target[baseKey].length) {
                                target[baseKey].splice(index, 1);
                            }
                        }
                    }
                } else {
                    delete target[baseKey];
                }
            } else if (Array.isArray(value) && value.length === 0) {
                // Handle empty array assignment
                target[baseKey] = [];
            } else if (
                typeof value === "object" &&
                value !== null &&
                !Array.isArray(value) &&
                Object.keys(value).length === 0
            ) {
                // Handle empty object assignment
                target[baseKey] = {};
            } else {
                // Handle normal value assignment
                if (conditions.length > 0) {
                    let finalTarget = target[baseKey];
                    if (!Array.isArray(finalTarget)) {
                        target[baseKey] = [];
                        finalTarget = target[baseKey];
                    }

                    if (
                        shouldHandleAsNewArrayEntry(
                            finalTarget,
                            conditions[0],
                            value,
                        )
                    ) {
                        insertNewArrayEntry(finalTarget, conditions[0], value);
                    } else {
                        const lastCondition = conditions[conditions.length - 1];
                        if (
                            shouldHandleAsNewArrayEntry(
                                finalTarget,
                                lastCondition,
                                value,
                            )
                        ) {
                            insertNewArrayEntry(
                                finalTarget,
                                lastCondition,
                                value,
                            );
                        } else {
                            applyFinalCondition(
                                finalTarget,
                                lastCondition,
                                value,
                            );
                        }
                    }
                } else {
                    if (
                        typeof value === "object" &&
                        value !== null &&
                        !Array.isArray(value)
                    ) {
                        target[baseKey] = target[baseKey] || {};
                        Object.assign(target[baseKey], value);
                    } else {
                        target[baseKey] = value;
                    }
                }
            }
        } catch (error) {
            console.error(`Error processing path ${path}:`, error);
        }
    });

    return mutableBlock;
};

const shouldHandleAsNewArrayEntry = (
    target: any[],
    condition: string,
    value: any,
): boolean => {
    if (!Array.isArray(target)) return false;

    if (condition.includes(":")) {
        const [condKey, condVal] = condition.split(":");
        return !target.some(
            (item) => String(item[condKey]) === String(condVal),
        );
    } else if (!isNaN(Number(condition))) {
        const index = parseInt(condition, 10);
        return index >= target.length;
    }
    return false;
};

const insertNewArrayEntry = (
    target: any[],
    condition: string,
    value: any,
): void => {
    if (!Array.isArray(target)) return;

    if (condition.includes(":")) {
        const [condKey, condVal] = condition.split(":");
        target.push({ [condKey]: condVal, ...value });
    } else if (!isNaN(Number(condition))) {
        const index = parseInt(condition, 10);
        while (target.length < index) {
            target.push({});
        }
        target[index] = value;
    }
};

const parseKeySegment = (
    segment: string,
): { baseKey: string; conditions: string[] } => {
    const baseKey = segment.split("[")[0];
    const conditions = [...segment.matchAll(/\[([^\]]+)\]/g)].map(
        (match) => match[1],
    );
    return { baseKey, conditions };
};

const processCondition = (
    target: any[],
    condition: string,
    createIfMissing: boolean = false,
): any => {
    if (!Array.isArray(target)) {
        return null;
    }

    if (condition.includes(":")) {
        const [condKey, condVal] = condition.split(":");
        let element = target.find(
            (item) => String(item[condKey]) === String(condVal),
        );

        if (!element && createIfMissing) {
            element = { [condKey]: condVal };
            target.push(element);
        }
        return element;
    } else if (!isNaN(Number(condition))) {
        const index = parseInt(condition, 10);
        if (index >= 0) {
            if (createIfMissing) {
                while (target.length <= index) {
                    target.push({});
                }
            }
            return target[index];
        }
    }
    return null;
};

const createOrUpdateArrayElement = (
    array: any[],
    condition: string,
    defaultValue: any,
): any => {
    if (condition.includes(":")) {
        const [condKey, condVal] = condition.split(":");
        let element = array.find(
            (item) => String(item[condKey]) === String(condVal),
        );

        if (!element) {
            element = { [condKey]: condVal, ...defaultValue };
            array.push(element);
        } else if (defaultValue !== null) {
            Object.assign(element, defaultValue);
        }
        return element;
    } else if (!isNaN(Number(condition))) {
        const index = parseInt(condition, 10);
        while (array.length <= index) {
            array.push(defaultValue ? _.cloneDeep(defaultValue) : {});
        }
        if (defaultValue !== null) {
            Object.assign(array[index], defaultValue);
        }
        return array[index];
    }
    return null;
};

const applyFinalCondition = (
    target: any[],
    condition: string,
    value: any,
): void => {
    if (!Array.isArray(target)) return;

    if (condition.includes(":")) {
        const [condKey, condVal] = condition.split(":");
        const element = target.find(
            (item) => String(item[condKey]) === String(condVal),
        );
        if (element) {
            if (typeof value === "object" && value !== null) {
                Object.assign(element, value);
            } else {
                Object.assign(element, { [condKey]: condVal });
            }
        }
    } else if (!isNaN(Number(condition))) {
        const index = parseInt(condition, 10);
        if (index >= 0 && index < target.length) {
            if (typeof value === "object" && value !== null) {
                Object.assign(target[index], value);
            } else {
                target[index] = value;
            }
        }
    }
};

export const getV4ParsedBlocks = (blocksArray: any) => {
    return blocksArray
        ?.slice()
        .sort(function (a: any, b: any) {
            return a.order - b.order;
        })
        .map(({ id: worksheet_block_map_id, block: level }: any) => ({
            ...level,
            children: level.children
                ?.slice()
                ?.sort(function (a: any, b: any) {
                    return a.order - b.order;
                })
                ?.map((chunk: any) => ({
                    ...chunk,
                    children: chunk.children
                        ?.slice()
                        ?.sort(function (a: any, b: any) {
                            return a.order - b.order;
                        }),
                })),
            worksheet_block_map_id,
        }));
};

function createReferenceIdMap(blocks: any[]) {
    const referenceIdMap: any = {};

    function traverseBlock(block: any) {
        if (block.reference_id !== null) {
            referenceIdMap[block.reference_id] = block.id;
        }
        if (block.children) {
            block.children.forEach(traverseBlock);
        }
    }

    blocks.forEach((block) => traverseBlock(block?.block));
    return referenceIdMap;
}

const WorksheetEdit = (props) => {
    const {
        updateWorksheet,
        worksheetBlockMap,
        refetchWorksheetBlockMap,
        loadingWorksheetMap,
        insertWorksheetBlockMap,
        deleteWorksheetBlockMap,
        deleteBlocks,
        worksheet,
        versionModelMap,
        insertWorksheetBlock,
        updateWorksheetBlock,
        updateWorksheetUserStats,

        storyJobs,
        storyJobsLoading,
        audioJobs,
        audioJobsLoading,

        refetchWorksheetStats,
        worksheetStatsLoading,
        worksheetStats,
        loadingPublishedBlocks,
        publlishedBlocks,
        refetchPublishedBlocks,
    } = props;
    const router = useRouter();

    const { user } = useAuth0();

    let worksheet_id = worksheet?.id;
    const { redirect_to } = router.query;

    const [isSaving, toggleSaving] = useState(false);
    const [shouldBlockUI, toggleShouldBlockUI] = useState(false);

    const [currentBlock, setCurrentBlock] = useState<any>(null);
    const [currentSubBlock, setCurrentSubBlock] = useState<any>(null);
    const [currentChunkBlock, setCurrentChunkBlock] = useState<any>(null);

    const [hasChanged, toggleHasChanged] = useState(false);
    const [lastSavedAt, setLastSavedAt] = useState<any>(null);
    const [blocks, setBlocks] = useState<any>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [saveVal, setSaveVal] = useState(false);
    const [worksheetStatsState, setWorksheetStatsState] = useState<any>({});
    const [currentSavedCount, setCurrentSavedCount] = useState<any>(0);
    const [publishedBlocksMap, setPublishedBlocksMap] = useState<any>({});
    const [worksheetWorksheetStatsUS, setWorksheetWorksheetStatsUS] =
        useState<any>({});
    const [getSyncBlocks, { data }] = useLazyQuery(SYNC_BLOCK_SEARCH_QUERY);
    const [syncBlocks, setSyncBlocks] = useState([]);

    useEffect(() => {
        if (data?.blocks) {
            setSyncBlocks(data.blocks); // Set syncBlocks state when data is available
        }
    }, [data]); // Runs when data changes

    const [getBlock, { data: newData }] = useLazyQuery(GET_BLOCK_QUERY, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (worksheet && worksheet?.type !== "personalized_learning_v4") {
            let query = router.query;
            delete query.worksheet_id;
            router.push({
                pathname: `/worksheet/update_enhanced/${worksheet?.id}`,
                query,
            });
        }
    }, [worksheet, router]);

    useEffect(() => {
        if (
            !worksheetStatsLoading &&
            worksheetStats &&
            !worksheetStatsState?.other
        ) {
            setWorksheetStatsState(worksheetStats);
            setCurrentSavedCount(worksheetStats?.other?.save_count);
        }
    }, [worksheetStatsLoading, worksheetStats]);

    useEffect(() => {
        if (!loadingPublishedBlocks) {
            setPublishedBlocksMap(createReferenceIdMap(publlishedBlocks));
        }
    }, [loadingPublishedBlocks, publlishedBlocks]);

    useEffect(() => {
        const getUpdatedBlocks = async () => {
            const processLevelBlocks = (levelBlocks) => {
                // Recursive function to process objects
                const traverse = (obj) => {
                    if (Array.isArray(obj)) {
                        // Sequentially process array elements
                        const result = [];
                        let index = 0;

                        const processNext = () => {
                            if (index < obj.length) {
                                return traverse(obj[index]).then(
                                    (processedChild) => {
                                        result.push(processedChild);
                                        index++;
                                        return processNext();
                                    },
                                );
                            } else {
                                return Promise.resolve(result);
                            }
                        };

                        return processNext();
                    } else if (obj && typeof obj === "object") {
                        if (
                            obj.type === "chunk" &&
                            Array.isArray(obj.children)
                        ) {
                            // Process children one by one
                            let updatedChildren = [];
                            let childIndex = 0;

                            const processChildren = () => {
                                if (childIndex < obj.children.length) {
                                    const child = obj.children[childIndex];

                                    if (child?.data && child.data.id) {
                                        return getBlock({
                                            variables: {
                                                block_id: child.data.id,
                                            },
                                        }).then((newData) => {
                                            const matchingSyncBlock =
                                                newData?.data
                                                    ?.worksheet_block[0];

                                            if (matchingSyncBlock) {
                                                const updatedChild =
                                                    applyChanges(
                                                        matchingSyncBlock,
                                                        child?.data
                                                            ?.changes_list,
                                                    );

                                                updatedChildren.push({
                                                    ...child,
                                                    data: {
                                                        ...updatedChild.data,
                                                        changes_list:
                                                            child.data
                                                                .changes_list,
                                                    },
                                                    backend: {
                                                        ...updatedChild.backend,
                                                    },
                                                    is_modified: true,
                                                    master_block:
                                                        updatedChild.id,
                                                });
                                            } else {
                                                updatedChildren.push(child);
                                            }

                                            childIndex++;
                                            return processChildren();
                                        });
                                    } else {
                                        updatedChildren.push(child);
                                        childIndex++;
                                        return processChildren();
                                    }
                                } else {
                                    return Promise.resolve({
                                        ...obj,
                                        children: updatedChildren,
                                    });
                                }
                            };

                            return processChildren();
                        } else {
                            // Process object properties sequentially
                            const entries = Object.entries(obj);
                            const result = {};
                            let entryIndex = 0;

                            const processEntries = () => {
                                if (entryIndex < entries.length) {
                                    const [key, value] = entries[entryIndex];
                                    return traverse(value).then(
                                        (processedValue) => {
                                            result[key] = processedValue;
                                            entryIndex++;
                                            return processEntries();
                                        },
                                    );
                                } else {
                                    return Promise.resolve(result);
                                }
                            };

                            return processEntries();
                        }
                    } else {
                        // Return primitive values directly
                        return Promise.resolve(obj);
                    }
                };

                // Start traversal and return a promise
                return traverse(levelBlocks);
            };

            let updatedWorksheetBlockMap = await processLevelBlocks(
                worksheetBlockMap,
            );
            setParsedBlocks(updatedWorksheetBlockMap);
        };
        getUpdatedBlocks();
    }, [worksheetBlockMap]);

    useEffect(() => {
        // Every 10s, run a mutation to tell the backend that you're online
        let onlineIndicator: string | number | NodeJS.Timeout | undefined;
        let v = setTimeout(() => {
            updateLastSeen();
            onlineIndicator = setInterval(() => updateLastSeen(), 10000);
        }, 2000);
        graphQLClientUS
            .request(WORKSHEET_STATS, {
                worksheet_id: getLastStringFromUrl(),
            })
            .then(({ worksheet_worksheet_stats }: any) => {
                setWorksheetWorksheetStatsUS(
                    worksheet_worksheet_stats?.[0] ?? {},
                );
            });

        return () => {
            // Clean up
            onlineIndicator && clearInterval(onlineIndicator);
            clearTimeout(v);
        };
    }, []);

    useEffect(() => {
        if (saveVal) {
            onSave({});
            setSaveVal(false);
        }
    }, [saveVal]);

    useEffect(() => {
        if (!storyJobsLoading && storyJobs?.length) {
            message.info("Story fetched. Check them out!");
        }
    }, [storyJobs, storyJobsLoading]);

    useEffect(() => {
        if (!audioJobsLoading && audioJobs?.length) {
            message.info("Audio fetched. Check them out!");
        }
    }, [audioJobs, audioJobsLoading]);

    const updateLastSeen = async () => {
        if (
            user &&
            document?.visibilityState === "visible" &&
            navigator?.onLine
        ) {
            const {
                email,
                "https://hasura.io/jwt/claims/user_id": user_id,
                name,
            } = user;

            try {
                const {
                    data: { worksheet_worksheet_stats },
                } = await refetchWorksheetStats();
                let insertObject = _.cloneDeep(
                    worksheet_worksheet_stats?.[0]?.other || {},
                );

                if (insertObject?.active_users) {
                    const { active_users } = insertObject;

                    let isEditorActive = true;
                    let isUserPresent = false;

                    active_users.forEach(
                        (element: {
                            user_id: any;
                            is_editor: boolean;
                            last_seen: string | number | Date;
                        }) => {
                            if (user_id === element.user_id)
                                isUserPresent = true;
                            if (element.is_editor) {
                                const activeTime = new Date(
                                    element.last_seen,
                                ).getTime();
                                const currentTime = new Date().getTime();
                                const timeDifferenceSecs = Math.floor(
                                    (currentTime - activeTime) / 1000,
                                );

                                if (timeDifferenceSecs > 60) {
                                    isEditorActive = false;
                                }
                            }
                        },
                    );

                    let tempActiveUsers = _.cloneDeep(active_users);

                    if (!isUserPresent) {
                        tempActiveUsers.push({
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: false,
                        });
                    } else {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any; last_seen: any }) => {
                                return {
                                    ...item,
                                    last_seen:
                                        user_id === item.user_id
                                            ? new Date().toISOString()
                                            : item.last_seen,
                                };
                            },
                        );
                    }

                    tempActiveUsers = tempActiveUsers.sort(
                        (
                            a: {
                                last_seen: string | number | Date;
                                is_editor: boolean;
                            },
                            b: {
                                last_seen: string | number | Date;
                                is_editor: boolean;
                            },
                        ) => {
                            if (a.is_editor && !b.is_editor) {
                                return -1; // a is an editor, b is not an editor (a comes first)
                            } else if (!a.is_editor && b.is_editor) {
                                return 1; // a is not an editor, b is an editor (b comes first)
                            } else {
                                const timeA = new Date(a.last_seen).getTime();
                                const timeB = new Date(b.last_seen).getTime();
                                return timeB - timeA; // Sort based on last_seen in descending order (latest on top)
                            }
                        },
                    );

                    if (!isEditorActive) {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any }) => {
                                return {
                                    ...item,
                                    is_editor: user_id === item.user_id,
                                };
                            },
                        );
                    }

                    insertObject.active_users = tempActiveUsers;
                } else {
                    insertObject = {
                        ...insertObject,
                        active_users: [
                            {
                                user_id,
                                email,
                                name,
                                last_seen: new Date().toISOString(),
                                is_editor: true,
                            },
                        ],
                    };
                }

                let worksheetId = worksheet_id || getLastStringFromUrl();
                if (worksheetId) {
                    const response = await updateWorksheetUserStats({
                        other: insertObject,
                        worksheet_id: worksheetId,
                    });
                    setWorksheetStatsState(response);
                }
            } catch (e) {
                captureException(e);
                console.log(e);
            }
        }
    };

    const setActiveEditor = async () => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            if (user) {
                const {
                    email,
                    "https://hasura.io/jwt/claims/user_id": user_id,
                    name,
                } = user;

                const {
                    data: { worksheet_worksheet_stats },
                } = await refetchWorksheetStats();
                let insertObject = _.cloneDeep(
                    worksheet_worksheet_stats?.[0]?.other || {},
                );

                if (insertObject?.active_users) {
                    const { active_users } = insertObject;

                    let isUserPresent = active_users.some(
                        (element: {
                            user_id: any;
                            is_editor: boolean;
                            last_seen: string | number | Date;
                        }) => {
                            return user_id === element.user_id;
                        },
                    );

                    let tempActiveUsers = _.cloneDeep(active_users);

                    if (!isUserPresent) {
                        tempActiveUsers.push({
                            user_id,
                            email,
                            name,
                            last_seen: new Date().toISOString(),
                            is_editor: true,
                        });
                    } else {
                        tempActiveUsers = tempActiveUsers.map(
                            (item: { user_id: any; last_seen: any }) => {
                                return {
                                    ...item,
                                    is_editor: user_id === item.user_id,
                                };
                            },
                        );
                    }

                    insertObject.active_users = tempActiveUsers;
                } else {
                    insertObject = {
                        ...insertObject,
                        active_users: [
                            {
                                user_id,
                                email,
                                name,
                                last_seen: new Date().toISOString(),
                                is_editor: true,
                            },
                        ],
                    };
                }

                const response = await updateWorksheetUserStats({
                    other: insertObject,
                    worksheet_id,
                });
                setWorksheetStatsState(response);
            }
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };

    if (window && history) {
        window.onpopstate = function () {
            setIsOpen(true);
        };
        history.pushState({}, "");
    }

    const handleClose = async () => setIsOpen(false);

    const validateBlocks = () => {
        const arr = blocks.map((blockObj: any) => {
            const tmpArr = [];

            tmpArr.push(checkBlockValid(blockObj, worksheet));

            if (blockObj.children && blockObj.children.length) {
                blockObj.children.map((child: any) => {
                    tmpArr.push(checkBlockValid(child, worksheet));
                });
            }
            return tmpArr;
        });
        return arr.flat();
    };

    const onFinish = async (values: any) => {
        let blockData = blocks;
        const {
            data: { worksheet_worksheet_stats },
        } = await refetchWorksheetStats();
        let insertObject = _.cloneDeep(
            worksheet_worksheet_stats?.[0]?.other || {},
        );
        await updateWorksheet({
            object: _.omit(values, ["tags"]),
            id: values.id,
        });

        const {
            email,
            "https://hasura.io/jwt/claims/user_id": user_id,
            name,
        } = user || {};
        const { save_count } = insertObject;
        let currentSaved = {
            time: new Date(),
            user: {
                email,
                user_id,
                name,
            },
        };
        insertObject.save_count = (save_count || 0) + 1;
        insertObject.current_save = currentSaved;
        insertObject.block_count = blockData?.length;
        const response = await updateWorksheetUserStats({
            other: insertObject,
            worksheet_id,
        });
        setCurrentSavedCount(response?.other?.save_count);
        setWorksheetStatsState(response);
    };

    const setParsedBlocks = (blocksArray: any) => {
        if (blocksArray?.length) {
            const parsedBlocks = getV4ParsedBlocks(blocksArray);
            setBlocks(parsedBlocks || []);
            if (!currentBlock) {
                setCurrentBlock(0);
            }

            return parsedBlocks;
        }
        return [];
    };

    const saveBlocks = async ({
        currentBlocks,
        publish,
        levelReset,
        shouldPublishToSecondaryHasuraEndpoints = false,
    }: any) => {
        let retVal = true;
        let blockData = currentBlocks || blocks;
        const processBlocks = (levelBlocks) => {
            // Helper function to check if a variable name is used in a block
            const isVariableUsed = (block, variableName) => {
                const blockContent = JSON.stringify(block).toLowerCase(); // Convert block to string for easier search
                return blockContent.includes(variableName.toLowerCase());
            };

            // Recursive function to process each block
            const traverse = (block, globalContextVariables) => {
                if (block && typeof block === "object") {
                    if (block.type === "progress_level") {
                        // Set globalContextVariables only for progress_level
                        globalContextVariables =
                            block.backend?.global_context_variables;

                        // Process children recursively for progress_level
                        block.children?.forEach((child) =>
                            traverse(child, globalContextVariables),
                        );
                    } else if (
                        block.type === "chunk" &&
                        Array.isArray(block.children)
                    ) {
                        // Use globalContextVariables from the progress_level
                        const { base_variables = [], derived_variables = [] } =
                            globalContextVariables || {};

                        // Set `is_synced_block` if the worksheet is a synced block worksheet

                        // Process children of chunk
                        block.children.forEach((child) => {
                            if (worksheet?.is_synced_block_worksheet) {
                                child.is_synced_block = true;
                            }
                            if (child.master_block || child.parent_id) {
                                child.backend = null; // Always set backend to null

                                // Prepare data object with id and changes_list
                                child.data = {
                                    id: child.master_block || child.id,
                                    ...(child.data?.changes_list && {
                                        changes_list: child.data.changes_list,
                                    }),
                                };

                                if (child.parent_id) {
                                    delete child.id;
                                }
                            }

                            // Analyze variable usage
                            const usedBaseVariables = [];
                            const usedDerivedVariables = [];

                            // Check base variable usage
                            base_variables.forEach((baseVar) => {
                                if (isVariableUsed(child, baseVar.name)) {
                                    usedBaseVariables.push({
                                        name: `${baseVar.name}_${worksheet?.id}`,
                                        value: baseVar.value,
                                    });
                                }
                            });

                            traverse(child, globalContextVariables);
                        });
                    }
                }
            };

            // Start traversal from each progress_level object in levelBlocks
            levelBlocks.forEach((progressLevel) => {
                traverse(
                    progressLevel,
                    progressLevel?.backend?.global_context_variables,
                );
            });

            // Return the updated levelBlocks
            return levelBlocks;
        };

        let newBlockData = worksheet.is_synced_block_worksheet
            ? blockData
            : processBlocks(blockData);

        let resBlocks = await Promise.all(
            newBlockData?.map(async (level) => {
                if (!level?.id) {
                    const children = level?.children?.length
                        ? {
                              children: {
                                  data: level.children.map(
                                      (child: any, index: number) => {
                                          const gChildren = child?.children
                                              ?.length
                                              ? {
                                                    children: {
                                                        data: child.children.map(
                                                            (
                                                                grandchild: any,
                                                                gIndex: number,
                                                            ) => {
                                                                const isOnlyIdOrderType =
                                                                    Object.keys(
                                                                        grandchild,
                                                                    ).every(
                                                                        (key) =>
                                                                            [
                                                                                "id",
                                                                                "order",
                                                                                "type",
                                                                                "data",
                                                                            ].includes(
                                                                                key,
                                                                            ),
                                                                    ) &&
                                                                    grandchild.data &&
                                                                    Object.keys(
                                                                        grandchild.data,
                                                                    ).length ===
                                                                        1 &&
                                                                    grandchild
                                                                        .data
                                                                        .test !==
                                                                        undefined;

                                                                return {
                                                                    ..._.omit(
                                                                        grandchild,
                                                                        [
                                                                            "tmpId",
                                                                            "__typename",
                                                                            "tags",
                                                                            "children",
                                                                            "id",
                                                                            "is_modified",
                                                                            "master_block",
                                                                        ],
                                                                    ),
                                                                    data: isOnlyIdOrderType
                                                                        ? {
                                                                              id: grandchild.id,
                                                                          }
                                                                        : grandchild.is_modified
                                                                        ? {
                                                                              id: grandchild.master_block,
                                                                              changes_list:
                                                                                  grandchild
                                                                                      .data
                                                                                      .changes_list,
                                                                          }
                                                                        : {
                                                                              ...grandchild.data,
                                                                          },
                                                                    parent_id:
                                                                        child.id, // Link to the parent child block
                                                                    order:
                                                                        gIndex +
                                                                        1, // Set order based on the grandchild's index
                                                                    is_synced_block:
                                                                        worksheet?.is_synced_block_worksheet ||
                                                                        false, // Set sync flag
                                                                };
                                                            },
                                                        ),
                                                        on_conflict: {
                                                            constraint:
                                                                "block_pkey",
                                                            update_columns: [
                                                                "data",
                                                                "type",
                                                                "order",
                                                                "backend",
                                                                "is_synced_block",
                                                            ],
                                                        },
                                                    },
                                                }
                                              : {};

                                          return {
                                              ..._.omit(child, [
                                                  "tmpId",
                                                  "__typename",
                                                  "tags",
                                                  "children",
                                                  "id",
                                              ]),
                                              ...gChildren, // Include the nested children structure if present
                                              order: index + 1, // Set the order based on the index
                                              parent_id: level.id, // Link to the parent level block
                                              is_synced_block:
                                                  worksheet?.is_synced_block_worksheet ||
                                                  false, // Set sync flag
                                          };
                                      },
                                  ),
                                  on_conflict: {
                                      constraint: "block_pkey",
                                      update_columns: [
                                          "data",
                                          "type",
                                          "order",
                                          "backend",
                                          "is_synced_block",
                                      ],
                                  },
                              },
                          }
                        : {};

                    const varData = {
                        ...children,
                        ..._.omit(level, [
                            "tmpId",
                            "worksheet_block_map_id",
                            "__typename",
                            "children",
                            "tags",
                        ]),
                    };

                    return varData;
                } else {
                    const savedLevelMap = worksheetBlockMap?.find(
                        (v) => v.block?.id === level?.id,
                    );
                    const savedLevel = savedLevelMap?.block;
                    if (
                        !_.isEqual(
                            _.omit(level, [
                                "id",
                                "tmpId",
                                "__typename",
                                "children",
                                "tags",
                                "worksheet_block_map_id",
                            ]),
                            _.omit(savedLevel, [
                                "id",
                                "tmpId",
                                "__typename",
                                "children",
                                "tags",
                                "worksheet_block_map_id",
                            ]),
                        )
                    ) {
                        await updateWorksheetBlock({
                            object: _.omit(level, [
                                "tmpId",
                                "worksheet_block_map_id",
                                "__typename",
                                "children",
                                "tags",
                            ]),
                            id: level.id,
                        })
                            .then((response) => {
                                console.log(
                                    "Worksheet block updated successfully:",
                                    response,
                                );
                            })
                            .catch((error) => {
                                console.error(
                                    "Error updating worksheet block:",
                                    error,
                                );
                            });
                    }
                    await Promise.all(
                        level?.children?.map(async (chunk, cIndex) => {
                            if (!chunk?.id) {
                                // Handling new chunk insertion
                                const children = chunk?.children?.length
                                    ? {
                                          children: {
                                              data: chunk.children.map(
                                                  (child, index) => {
                                                      const isOnlyIdOrderType =
                                                          Object.keys(
                                                              child,
                                                          ).every((key) =>
                                                              [
                                                                  "id",
                                                                  "order",
                                                                  "type",
                                                                  "data",
                                                              ].includes(key),
                                                          ) &&
                                                          child.data &&
                                                          Object.keys(
                                                              child.data,
                                                          ).length === 1 &&
                                                          child.data.test !==
                                                              undefined;

                                                      return {
                                                          ..._.omit(child, [
                                                              "tmpId",
                                                              "worksheet_block_map_id",
                                                              "__typename",
                                                              "children",
                                                              "tags",
                                                              "id",
                                                              "is_modified",
                                                              "master_block",
                                                          ]),
                                                          data: isOnlyIdOrderType
                                                              ? { id: child.id }
                                                              : child.is_modified
                                                              ? {
                                                                    id: child.master_block,
                                                                    changes_list:
                                                                        child
                                                                            .data
                                                                            .changes_list,
                                                                }
                                                              : {
                                                                    ...child.data,
                                                                },
                                                          parent_id: chunk.id,
                                                          order: index + 1,
                                                          is_synced_block:
                                                              worksheet?.is_synced_block_worksheet ||
                                                              false,
                                                      };
                                                  },
                                              ),
                                              on_conflict: {
                                                  constraint: "block_pkey",
                                                  update_columns: [
                                                      "data",
                                                      "type",
                                                      "order",
                                                      "backend",
                                                      "is_synced_block",
                                                  ],
                                              },
                                          },
                                      }
                                    : {};

                                const varData = {
                                    ...children,
                                    ..._.omit(chunk, [
                                        "tmpId",
                                        "worksheet_block_map_id",
                                        "__typename",
                                        "children",
                                        "tags",
                                        "id",
                                    ]),
                                    order: cIndex + 1,
                                    parent_id: level.id,
                                };

                                const data = await insertWorksheetBlock(
                                    varData,
                                );
                                return data?.id;
                            } else {
                                // Handling existing chunk update
                                const savedChunk = savedLevel?.children?.find(
                                    (v) => v?.id === chunk?.id,
                                );
                                const updatedChunkData = {
                                    ..._.omit({ ...chunk, order: cIndex + 1 }, [
                                        "id",
                                        "tmpId",
                                        "__typename",
                                        "children",
                                        "tags",
                                    ]),
                                };

                                if (
                                    !_.isEqual(
                                        updatedChunkData,
                                        _.omit(savedChunk, [
                                            "id",
                                            "tmpId",
                                            "__typename",
                                            "children",
                                            "tags",
                                        ]),
                                    )
                                ) {
                                    await updateWorksheetBlock({
                                        object: updatedChunkData,
                                        id: chunk.id,
                                    })
                                        .then((response) => {
                                            console.log(
                                                "Chunk updated successfully:",
                                                response,
                                            );
                                        })
                                        .catch((error) => {
                                            console.error(
                                                "Error updating chunk:",
                                                error,
                                            );
                                        });
                                }

                                // Process children of the chunk
                                await Promise.all(
                                    chunk?.children?.map(
                                        async (block, bIndex) => {
                                            const isOnlyIdOrderType =
                                                Object.keys(block).every(
                                                    (key) =>
                                                        [
                                                            "id",
                                                            "order",
                                                            "type",
                                                            "data",
                                                        ].includes(key),
                                                ) &&
                                                block.data &&
                                                block.data.test !== undefined;

                                            const savedBlock =
                                                savedChunk?.children?.find(
                                                    (v) => v?.id === block?.id,
                                                );

                                            const updatedBlockData = {
                                                ..._.omit(block, [
                                                    "tmpId",
                                                    "worksheet_block_map_id",
                                                    "__typename",
                                                    "children",
                                                    "tags",
                                                    "id",
                                                    "is_modified",
                                                    "master_block",
                                                ]),
                                                /*  data: /* isOnlyIdOrderType
                                                    ? {
                                                          id: block.id,
                                                          ...(block.data
                                                              .changes_list !==
                                                              undefined && {
                                                              changes_list:
                                                                  block.data
                                                                      .changes_list,
                                                          }),
                                                      }
                                                    : block.master_block
                                                    ? {
                                                          id: block.master_block,
                                                          ...(block.data
                                                              .changes_list !==
                                                              undefined && {
                                                              changes_list:
                                                                  block.data
                                                                      .changes_list,
                                                          }),
                                                      }
                                                    : { 
                                                          ...block.data,
                                                      }, */
                                                data: block.data,
                                                parent_id: chunk.id,
                                                order: bIndex + 1,
                                                is_synced_block:
                                                    worksheet?.is_synced_block_worksheet ||
                                                    false,
                                                backend:
                                                    isOnlyIdOrderType ||
                                                    block.is_modified
                                                        ? null
                                                        : block.backend, // Set backend to null if the first two conditions are true
                                            };

                                            if (
                                                !block.id ||
                                                isOnlyIdOrderType ||
                                                block.is_modified === false
                                            ) {
                                                const data =
                                                    await insertWorksheetBlock(
                                                        updatedBlockData,
                                                    );
                                                return data?.id;
                                            } else {
                                                // Ensure properties like 'name' are not unintentionally modified

                                                const savedBlockData = _.omit(
                                                    savedBlock,
                                                    [
                                                        "tmpId",
                                                        "worksheet_block_map_id",
                                                        "__typename",
                                                        "children",
                                                        "tags",
                                                    ],
                                                );

                                                if (
                                                    !_.isEqual(
                                                        updatedBlockData?.backend,
                                                        savedBlockData?.backend,
                                                    ) ||
                                                    !_.isEqual(
                                                        updatedBlockData?.data,
                                                        savedBlockData?.data,
                                                    ) ||
                                                    !_.isEqual(
                                                        updatedBlockData?.order,
                                                        savedBlockData?.order,
                                                    )
                                                ) {
                                                    await updateWorksheetBlock({
                                                        object: updatedBlockData,
                                                        id: block.id,
                                                    })
                                                        .then((response) => {
                                                            console.log(
                                                                "Block updated successfully:",
                                                                response,
                                                            );
                                                        })
                                                        .catch((error) => {
                                                            console.error(
                                                                "Error updating block:",
                                                                error,
                                                            );
                                                        });
                                                }
                                            }
                                            return block?.id;
                                        },
                                    ),
                                );
                                return chunk?.id;
                            }
                        }),
                    );

                    return {
                        levelId: level?.id,
                        worksheet_block_map_id: savedLevelMap?.id,
                    };
                }
            }),
        );

        const objects: any = getV4WorksheetBlockMapObject({
            blockData,
            worksheetData: { worksheet_id },
        });

        const updateObjects: any = getWorksheetBlockIdMap({
            blockData: resBlocks,
            worksheetData: { worksheet_id },
        });

        const existingWorksheetBlockMapIds = objects
            .map(({ id }: any) => id)
            .filter((n: any) => n);
        const allWorksheetBlockMapIds = worksheetBlockMap.map(
            ({ id }: any) => id,
        );
        const missingWorksheetBlockMapIds = allWorksheetBlockMapIds.filter(
            (x: any) => !existingWorksheetBlockMapIds.includes(x),
        );

        const allChildrenBlockIds = worksheetBlockMap
            .flatMap(({ block: { children, id } }: any) => [
                id,
                ...children.flatMap(({ id, children: gChildren }: any) => {
                    const gChildrenIds = (gChildren || []).map(
                        ({ id }: any) => id,
                    );
                    return [id, ...gChildrenIds];
                }),
            ])
            .filter((n: any) => n);

        const existingChildrenBlockIds = blockData
            ?.flatMap(({ children = [], id }: any) => [
                id,
                ...children?.flatMap(
                    ({ id, children: gChildren = [] }: any) => [
                        id,
                        ...gChildren?.map(({ id }: any) => id),
                    ],
                ),
            ])
            .filter((n: any) => n);

        const missingChildrenBlockIds = allChildrenBlockIds.filter(
            (x: any) => !existingChildrenBlockIds.includes(x),
        );

        if (missingChildrenBlockIds.length) {
            await deleteBlocks({ ids: missingChildrenBlockIds });
        }

        if (missingWorksheetBlockMapIds.length)
            await deleteWorksheetBlockMap({
                ids: missingWorksheetBlockMapIds,
            });

        await insertWorksheetBlockMap({
            objects: updateObjects,
        });

        const {
            data: { worksheetBlockMap: newWorksheetBlockMap },
        } = await refetchWorksheetBlockMap();

        const processLevelBlocks = (levelBlocks, syncBlocks) => {
            // Traverse function to recursively process each object
            const traverse = (obj) => {
                if (Array.isArray(obj)) {
                    return obj.map(traverse); // Return a new array for processing children
                } else if (obj && typeof obj === "object") {
                    // Check if the object is a "chunk" with children
                    if (obj.type === "chunk" && Array.isArray(obj.children)) {
                        // Process children and create a new children array
                        const updatedChildren = obj.children.map(
                            (child, index) => {
                                if (child?.data && child.data.id) {
                                    const childId = child.data.id;

                                    // Find matching sync block by ID
                                    const matchingSyncBlock = syncBlocks.find(
                                        (syncBlock) => syncBlock.id === childId,
                                    );

                                    if (matchingSyncBlock) {
                                        // Apply changes function

                                        const updatedChild = applyChanges(
                                            matchingSyncBlock,
                                            child?.data?.changes_list,
                                        );

                                        // Create a new child object with the updated data and other properties
                                        return {
                                            ...child,
                                            data: {
                                                ...updatedChild.data,
                                                changes_list:
                                                    child.data.changes_list,
                                            },
                                            backend: {
                                                ...updatedChild.backend,
                                            },
                                            is_modified: true,
                                            master_block: updatedChild.id,
                                        };
                                    }
                                }
                                // Return the child unmodified if no matching block is found
                                return child;
                            },
                        );

                        // Return a new object with updated children
                        return {
                            ...obj,
                            children: updatedChildren,
                        };
                    }

                    // Recursively process other properties of the object if necessary
                    return Object.fromEntries(
                        Object.entries(obj).map(([key, value]) => [
                            key,
                            traverse(value),
                        ]),
                    );
                }
                // Return primitive values as is
                return obj;
            };

            // Start traversal from the root object

            const result = traverse(levelBlocks);

            // Return the updated levelBlocks after processing
            return result;
        };

        let updatedWorksheetBlockMap = processLevelBlocks(
            newWorksheetBlockMap,
            syncBlocks,
        );

        blockData = setParsedBlocks(updatedWorksheetBlockMap);

        // START: handle versioning
        const {
            data: { worksheet_worksheet_stats },
        } = await refetchWorksheetStats();
        let insertObject = _.cloneDeep(
            worksheet_worksheet_stats?.[0]?.other || {},
        );

        const { save_count } = insertObject;

        const {
            email,
            "https://hasura.io/jwt/claims/user_id": user_id,
            name,
        } = user || {};

        let currentSaved = {
            time: new Date(),
            user: {
                email,
                user_id,
                name,
            },
        };
        insertObject.save_count = (save_count || 0) + 1;
        insertObject.current_save = currentSaved;
        insertObject.block_count = blockData?.length;
        if (publish) {
            try {
                // const res =
                await axios
                    .post(
                        `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/publishWorksheetForAllRegion`,
                        {
                            data: {
                                worksheet_id: Number(worksheet_id),
                                level_reset: levelReset || [],
                                shouldPublishToSecondaryHasuraEndpoints,
                                userData: currentSaved,
                            },
                        },
                        { headers: { "Content-Type": "application/json" } },
                    )
                    .then(function (response) {
                        return response?.data;
                    });

                // console.log("RESPONSE FROM API", res);
                insertObject.publish_count = (save_count || 0) + 1;
                insertObject.current_publish = currentSaved;

                refetchPublishedBlocks();
                // await axios.post(
                //     `${process.env.REACT_APP_HOMEWORK_SERVER_API_ENDPOINT}/v3/personalizedLearning/deleteWorksheetMetadataCache`,
                //     { data: { id: worksheet_id } },
                // );
            } catch (e) {
                captureException(e);
                retVal = false;
                console.log("Publish Failed", e);
            }
        }

        const response = await updateWorksheetUserStats({
            other: insertObject,
            worksheet_id,
        });

        setCurrentSavedCount(response?.other?.save_count);
        setWorksheetStatsState(response);
        // END: handle versioning

        // publish to other endpoints
        // if (shouldPublishToSecondaryHasuraEndpoints)
        //     await publishToSecondaryHasuraEndpoints(
        //         worksheet,
        //         newWorksheetBlockMap,
        //         levelReset,
        //     );

        return retVal;
    };

    const onSave = async ({
        showMessage = true,
        publish = false,
        generate = true,
        levelReset,
        shouldPublishToSecondaryHasuraEndpoints,
    }: any) => {
        const removeSelectedFlag = (obj) =>
            Array.isArray(obj)
                ? obj.map(removeSelectedFlag)
                : obj && typeof obj === "object"
                ? Object.fromEntries(
                      Object.entries(obj)
                          .filter(([key]) => key !== "selected_flag") // Remove the 'selected_flag' key
                          .map(([key, value]) => [
                              key,
                              removeSelectedFlag(value),
                          ]), // Recursively remove from nested values
                  )
                : obj; // Return primitive values as is

        const processTableData = (obj) => {
            try {
                if (Array.isArray(obj)) {
                    // Process each element if it's an array
                    return obj.map(processTableData);
                } else if (obj && typeof obj === "object") {
                    // Process key-value pairs if it's an object
                    return Object.fromEntries(
                        Object.entries(obj).map(([key, value]) => {
                            if (
                                key === "name" &&
                                obj.name === "tableData" &&
                                obj.value?.puzzle?.slider?.enabled
                            ) {
                                // Extract the necessary fields from obj.value
                                const {
                                    cells,
                                    width,
                                    height,
                                    horizontalGap,
                                    verticalGap,
                                } =
                                    calculateMultiStepMovementVectorsWithCachedGaps(
                                        obj.value?.cells,
                                    );

                                // Update the value field with new values
                                obj.value = {
                                    ...obj.value,
                                    cells,
                                    puzzle: {
                                        ...obj.value?.puzzle,
                                        slider: {
                                            ...obj.value?.puzzle?.slider,
                                            width,
                                            height,
                                            horizontalGap,
                                            verticalGap,
                                        },
                                    },
                                };
                            }

                            // Recursively process nested values
                            return [key, processTableData(value)];
                        }),
                    );
                }

                // Return primitive values as is
                return obj;
            } catch (error) {
                console.error("Error processing table data:", error);
                // throw new Error("Trial error for testing exception handling");
            }
        };

        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        let retVal = true;
        if (!isSaving && blocks.length) {
            toggleSaving(true);

            let newBlocks = blocks.map(removeSelectedFlag);
            let newCurrentBlocksVisited = newBlocks.map(processTableData);

            try {
                if (showMessage) toggleShouldBlockUI(true);
                // NEEDS UPDATE
                const newCurrentBlocks = await addGenerateLogicForVarAudio(
                    newCurrentBlocksVisited,
                    worksheet?.type,
                );
                if (publish || generate) {
                    // NEEDS UPDATE
                    const { job_id, error } = await generateAudios(
                        newCurrentBlocks,
                        worksheet?.type,
                    );
                    if (job_id) {
                        setNewAudioJob({ job_id });
                    }
                    if (error) {
                        message.error(
                            `Failed to generate audio for worksheet!`,
                        );
                        console.log("Error audio:", error);
                        // return false;
                    }
                }

                let finalCurrentBlocks = newCurrentBlocks;

                let [res] = await Promise.all([
                    saveBlocks({
                        currentBlocks: finalCurrentBlocks,
                        publish,
                        worksheet_type: worksheet.type,
                        levelReset,
                        shouldPublishToSecondaryHasuraEndpoints,
                    }),
                    delay(1000),
                ]);

                if (!res) retVal = false;
                setLastSavedAt(new Date());
                if (showMessage) {
                    message.success(`Successfully updated worksheet!`);
                }
            } catch (e) {
                captureException(e);
                console.log("Error on save:", e);
                if (showMessage)
                    message.error(
                        `Something went wrong! try again in a couple of seconds`,
                    );
                retVal = false;
            }

            toggleHasChanged(false);
            toggleShouldBlockUI(false);
            toggleSaving(false);
        } else if (isSaving) {
            toggleHasChanged(false);
            toggleSaving(false);
            toggleShouldBlockUI(false);
        }
        return retVal;
    };

    const setNewJob = async ({ path, job_id, name }) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs.unshift({
                    name,
                    path,
                    job_id,
                    is_sync: false,
                });

                insertObject.story_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    story_jobs: [{ name, path, job_id, is_sync: false }],
                };
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };
    const updateStoriesJob = async ({ job_id, is_delete }: any) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.story_jobs) {
                const { story_jobs } = insertObject;

                let tempJobs = _.cloneDeep(story_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                          if (v.job_id == job_id) {
                              v.is_sync = true;
                          }
                          return v;
                      });
                insertObject.story_jobs = tempJobs;
            }
            if (!is_delete) setSaveVal(true);
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
            return true;
        } catch (e) {
            captureException(e);
            console.log(e);
            return false;
        }
    };

    const setNewAudioJob = async ({ job_id }) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs.unshift({
                    job_id,
                    is_sync: false,
                });

                insertObject.audio_jobs = tempJobs;
            } else {
                insertObject = {
                    ...insertObject,
                    audio_jobs: [
                        {
                            job_id,
                            is_sync: false,
                        },
                    ],
                };
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
        } catch (e) {
            captureException(e);
            console.log(e);
        }
    };
    const updateAudiosJob = async ({ job_id, is_delete }: any) => {
        if (!navigator.onLine) {
            message.error("No internet!");
            return false;
        }
        try {
            const {
                data: { worksheet_worksheet_stats },
            } = await refetchWorksheetStats();
            let insertObject = _.cloneDeep(
                worksheet_worksheet_stats?.[0]?.other || {},
            );
            if (insertObject?.audio_jobs) {
                const { audio_jobs } = insertObject;

                let tempJobs = _.cloneDeep(audio_jobs);

                tempJobs = is_delete
                    ? tempJobs.filter((v) => v.job_id != job_id)
                    : tempJobs.map((v) => {
                          if (v.job_id == job_id) {
                              v.is_sync = true;
                          }
                          return v;
                      });
                insertObject.audio_jobs = tempJobs;
            }
            const response = await updateWorksheetUserStats({
                other: insertObject,
                worksheet_id,
            });
            setWorksheetStatsState(response);
            if (!is_delete) setSaveVal(true);
            return true;
        } catch (e) {
            captureException(e);
            console.log(e);
            return false;
        }
    };

    // console.log(props);
    const version =
        versionModelMap &&
        versionModelMap[0]?.versions_aggregate?.aggregate?.max?.index + 1; // versionModelMap

    const createViewProps = {
        onFinish,
        onFinishFailed: () => {},
        redirect_to,
        isSaving,
        blocks,
        setBlocks: (blocksArray: any) => {
            setBlocks(blocksArray);
            toggleHasChanged(true);
        },
        onSave,
        currentBlock,
        setCurrentBlock: (blockIndex: number) => {
            setCurrentBlock(blockIndex);
            setCurrentSubBlock(null);
            setCurrentChunkBlock(null);
        },
        worksheet_id,
        currentSubBlock,
        setCurrentSubBlock,
        currentChunkBlock,
        setCurrentChunkBlock,
        lastSavedAt,
        currentDateTime: null,
        setSaveCounter: ({ currentBlocks }: any) => {
            onSave({ showMessage: true, currentBlocks });
        },
        shouldBlockUI,
        toggleShouldBlockUI,
        validateBlocks,
        version,
        hasChanged,
        toggleHasChanged,
        canPublish:
            worksheetStatsState?.other?.save_count !==
            worksheetStatsState?.other?.publish_count,
        onInputVariableUpdate: () => {},
        checkInputVarExist: () => {},
        activeUsers: worksheetStatsState?.other?.active_users,
        storyJobs,
        worksheetStoryJobs: worksheetStatsState?.other?.story_jobs || [],
        setActiveEditor,
        setNewJob,
        updateStoriesJob,
        audioJobs,
        worksheetAudioJobs: worksheetStatsState?.other?.audio_jobs || [],
        updateAudiosJob,
        setNewAudioJob,
        currentSavedCount,
        publishedBlocksMap,
        worksheetWorksheetStatsUS,
    };

    if (loadingWorksheetMap) return <></>;
    return (
        <>
            <Modal
                open={isOpen}
                title="Are you sure you want to go back?"
                onOk={() => {
                    handleClose();
                    router.push(redirect_to);
                }}
                onCancel={handleClose}
            >
                <Typography.Text>
                    You'll lose any unsaved changes
                </Typography.Text>
            </Modal>
            <CreateView {...props} {...createViewProps} />
        </>
    );
};

export default compose(
    withHookForWorksheet,
    withUpdateWorksheet,
    withWorksheetBlocks,
    withWorksheetPublishedBlocks,
    withInsertWorksheetBlockMap,
    withDeleteWorksheetBlockMap,
    withDeleteBlocks,
    withVersionModelMap,
    withUpdateWorksheetUserStats,
    withWorksheetStats,
    withHookForWorksheetStories,
    withHookForWorksheetAudios,
    withUpdateWorksheetBlock,
    withInsertWorksheetBlock,
)(WorksheetEdit);

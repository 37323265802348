import React from "react";
import { Form, Switch, Collapse, Button, Input } from "antd";

const { Panel } = Collapse;

interface InteractionEventsSettingsProps {
    table: any;
    setBlock: (block: any) => void;
    disableWrite: boolean;
}

const interactionKeys = [
    { key: "DRAG_DROP", label: "DRAG_DROP" },
    { key: "CLICK", label: "CLICK" },
    // Add more interaction keys here as needed
];

const InteractionEventsSettings = ({
    table,
    setBlock,
    disableWrite,
}: InteractionEventsSettingsProps) => {
    const interactionEvents = table?.interactionEvents || {
        enabled: false,
        interactions: {},
    };

    const handleToggleEnabled = (checked: boolean) => {
        setBlock({
            ...table,
            interactionEvents: {
                ...interactionEvents,
                enabled: checked,
            },
        });
    };

    const handleEventChange = (interactionKey: string, value: string[]) => {
        setBlock({
            ...table,
            interactionEvents: {
                ...interactionEvents,
                interactions: {
                    ...interactionEvents.interactions,
                    [interactionKey]: value.map((event) => ({ event })),
                },
            },
        });
    };

    const handleAddInteractionKey = (value: string) => {
        if (value && !interactionEvents.interactions[value]) {
            setBlock({
                ...table,
                interactionEvents: {
                    ...interactionEvents,
                    interactions: {
                        ...interactionEvents.interactions,
                        [value]: [],
                    },
                },
            });
        }
    };

    return (
        <Form layout="vertical">
            <Form.Item label="Enable Interaction Events">
                <Switch
                    disabled={disableWrite}
                    checked={!!interactionEvents.enabled}
                    onChange={handleToggleEnabled}
                />
            </Form.Item>
            {interactionEvents.enabled && (
                <>
                    <Form.Item label="Add New Interaction Key">
                        {interactionKeys.map(({ key, label }) => (
                            <Button
                                key={key}
                                onClick={() => handleAddInteractionKey(key)}
                                disabled={disableWrite}
                                style={{ marginRight: "8px" }}
                            >
                                {label}
                            </Button>
                        ))}
                    </Form.Item>
                    <Collapse>
                        {Object.keys(interactionEvents.interactions || {}).map(
                            (interactionKey) => (
                                <Panel
                                    header={interactionKey}
                                    key={interactionKey}
                                >
                                    <Form.Item
                                        label={`${interactionKey} Events`}
                                    >
                                        {interactionEvents.interactions[
                                            interactionKey
                                        ]?.map((item, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: "8px",
                                                }}
                                            >
                                                <Input
                                                    value={item.event}
                                                    onChange={(e) => {
                                                        const newEvents = [
                                                            ...interactionEvents
                                                                .interactions[
                                                                interactionKey
                                                            ],
                                                        ];
                                                        newEvents[index].event =
                                                            e.target.value;
                                                        handleEventChange(
                                                            interactionKey,
                                                            newEvents.map(
                                                                (event) =>
                                                                    event.event,
                                                            ),
                                                        );
                                                    }}
                                                    disabled={disableWrite}
                                                    style={{
                                                        marginRight: "8px",
                                                        flex: 1,
                                                    }}
                                                />
                                                <Button
                                                    onClick={() => {
                                                        const newEvents =
                                                            interactionEvents.interactions[
                                                                interactionKey
                                                            ].filter(
                                                                (_, i) =>
                                                                    i !== index,
                                                            );
                                                        handleEventChange(
                                                            interactionKey,
                                                            newEvents.map(
                                                                (event) =>
                                                                    event.event,
                                                            ),
                                                        );
                                                    }}
                                                    disabled={disableWrite}
                                                >
                                                    -
                                                </Button>
                                            </div>
                                        ))}
                                        <Button
                                            onClick={() => {
                                                const newEvents = [
                                                    ...interactionEvents
                                                        .interactions[
                                                        interactionKey
                                                    ],
                                                    { event: "" },
                                                ];
                                                handleEventChange(
                                                    interactionKey,
                                                    newEvents.map(
                                                        (event) => event.event,
                                                    ),
                                                );
                                            }}
                                            disabled={disableWrite}
                                        >
                                            +
                                        </Button>
                                    </Form.Item>
                                </Panel>
                            ),
                        )}
                    </Collapse>
                </>
            )}
        </Form>
    );
};

export default InteractionEventsSettings;

import React from "react";
import { usePlateEditorRef } from "@udecode/plate-core";
import { insertButton } from "../../node";
import { ToolbarButton, ToolbarButtonProps } from "@udecode/plate-ui-toolbar";

export interface ButtonToolbarButtonProps extends ToolbarButtonProps {}

export const ButtonToolbarButton = (props: ButtonToolbarButtonProps) => {
    const editor = usePlateEditorRef()!;

    return (
        <ToolbarButton
            onMouseDown={async (event) => {
                if (!editor) return;

                event.preventDefault();

                let key = "";

                insertButton(editor, key);
            }}
            {...props}
        />
    );
};

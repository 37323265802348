import _, { cloneDeep, set } from "lodash";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { BlockColumn } from "./BlockColumn";
import { BLOCK_TYPES } from "../../../common";
import ComponentsEditor from "./ComponentsEditor";
import { QuestionColumn } from "./QuestionColumn";
import SyncBlockSearchQuery from "src/modules/worksheet/graphql/SyncBlockSearchQuery";
import {
    WORKSHEET_FROM_BLOCK_ID,
    GET_BLOCK_QUERY,
} from "src/modules/worksheet/graphql";

import { WORKSHEET_BLOCKS_QUERY } from "src/modules/worksheet/graphql";
import { Modal } from "antd";
import ChangesModal from "./ChangesModal";

const { ALL_IN_ONE } = BLOCK_TYPES;

const Editor = (props: any) => {
    const {
        block,
        isEditor,
        setIsEditor,
        setIsNewBlock,
        isNewBlock,
        setBlock,
        setNewJob,
        updateStoriesJob,
        updateAudiosJob,
        setNewAudioJob,
        renderLinkSelect: renderLinkSelectGlobal,
        worksheet,
        chunkSlug,
    } = props;
    const {
        id,
        tmpId,
        data: {
            [ALL_IN_ONE]: {
                components = [],
                question_variables: qVars = [],
            } = {},
        },
        backend,
        selected_flag,
    } = block;

    // Only assign a new value to selected_flag if it doesn't already exist
    const initialSelectedFlag =
        block?.selected_flag !== undefined
            ? block?.selected_flag
            : block?.tmpId == null
            ? true
            : false;

    // State to manage selected_flag
    const [localSelectedFlag, setLocalSelectedFlag] =
        useState(initialSelectedFlag);

    const [getBlock, { data: newData }] = useLazyQuery(GET_BLOCK_QUERY, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        // Check if worksheet has `is_synced_block_worksheet` and update block state
        if (worksheet?.is_synced_block_worksheet) {
            const updatedBlock = {
                ...block,
                is_synced_block: true,
            };

            // Call the updateBlock function passed as prop to update the parent or global state
            setBlock(updatedBlock);
        }
    }, []); // This will run whenever worksheet changes

    const updateBlockState = (newSelectedFlag: any) => {
        const updatedBlock = {
            ...block,
            selected_flag: newSelectedFlag, // Update selected_flag
        };

        // Call the updateBlock function passed as prop to update the parent or global state
        setBlock(updatedBlock);
    };
    let question_variables =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.question_variables || []
            : qVars;

    const renderLinkSelect = (v) =>
        renderLinkSelectGlobal &&
        renderLinkSelectGlobal({ ...v, question_variables });

    const [currentComponentIndex, setCurrentComponentIndex] = useState(null);
    const [currentEditor, setCurrentEditor] = useState(`${id || tmpId}_text`);
    const [load, setLoad] = useState(true);
    const [reset, setReset] = useState(false);
    const [isOldFlow, setIsOldFlow] = useState<boolean | null>(null); // True if tmpId is null, false otherwise
    const [syncBlocks, setSyncBlocks] = useState([]);
    const [worksheetId, setWorksheetId] = useState("");
    const [isMasterBlockModalVisible, setIsMasterBlockModalVisible] =
        useState(false);
    const [isResetModalVisible, setIsResetModalVisible] = useState(false);
    const [blockId, setBlockId] = useState("");
    const [changesList, setChangesList] = useState(
        block?.data?.changes_list || {},
    );
    const [previousBlock, setPreviousBlock] = useState(block); // Using `any` for simplicity

    // Lazy query to get blocks based on the worksheetId
    const [getWorksheetBlocks, { data: worksheetData }] = useLazyQuery(
        WORKSHEET_BLOCKS_QUERY,
    );
    const [getSyncBlocks, { data }] = useLazyQuery(SyncBlockSearchQuery);

    const [getMasterWorksheetID, { data: masterWorksheetData }] = useLazyQuery(
        WORKSHEET_FROM_BLOCK_ID,
    );

    const handleInputChange = (e) => {
        setBlockId(e.target.value);
    };
    const handleSearch = () => {
        /*  if (worksheetId === "") {
            getWorksheetBlocks({
                variables: { worksheet_id: parseInt(worksheetId, 10) },
            });
            if (data?.blocks) {
                const filteredBlocks = data.blocks.filter(
                    (block) =>
                        block.type === "all_in_one" && block.parent_id !== null,
                );

                setSyncBlocks(filteredBlocks);
            }
        }
        if (worksheetId) {
            // Fetch the worksheet blocks

            getWorksheetBlocks({
                variables: { worksheet_id: parseInt(worksheetId, 10) },
            });
            // Fetch sync blocks separately
        } */

        if (blockId) {
            // If blockId is provided, filter syncBlocks
            const matchingBlock = syncBlocks.find(
                (block) => block?.id == parseInt(blockId),
            );

            setSyncBlocks(matchingBlock ? [matchingBlock] : []); // Set to array with the matching block or empty array
        } else {
            // If blockId is empty, fetch syncBlocks data
            getSyncBlocks({
                onCompleted: (data) => {
                    if (data?.blocks) {
                        // Filter blocks where type is "all_in_one" and parent_id is not null
                        const filteredBlocks = data.blocks.filter(
                            (block) =>
                                block.type === "all_in_one" &&
                                block.parent_id !== null,
                        );
                        setSyncBlocks(filteredBlocks); // Set filtered blocks to syncBlocks
                    }
                },
                onError: (error) => {
                    console.error("Failed to fetch sync blocks:", error);
                },
            });
        }
    };

    const handleMasterBlockButtonClick = () => {
        setIsMasterBlockModalVisible(true); // Show confirmation modal
    };

    const handleMasterBlockConfirm = () => {
        setIsMasterBlockModalVisible(false); // Close the modal
        if (block && block.master_block) {
            getMasterWorksheetID({
                variables: { blockId: block.master_block },
            });
        }
    };

    const handleClose = () => {
        if (isMasterBlockModalVisible) {
            setIsMasterBlockModalVisible(false); // Close the modal without any action
        }
        if (isResetModalVisible) {
            setIsResetModalVisible(false); // Close the modal without any action
        }
    };

    useEffect(() => {
        const findUniqueFields = (object) => {
            const candidates = Object.entries(object).filter(
                ([_, value]) =>
                    typeof value === "string" || typeof value === "number",
            );

            const parentArray = Array.isArray(object.parent)
                ? object.parent
                : [];
            return candidates.find(
                ([key, value]) =>
                    parentArray.filter((item) => item[key] === value).length ===
                    1,
            )?.[0];
        };

        const saveChangeForPath = (path, newValue, changes) => {
            changes[path] = newValue;
        };

        const trackNestedChanges = (
            previousBlock,
            newBlock,
            parentPath = "",
            parentArray = null,
        ) => {
            const changes = {};

            const processValue = (prevValue, newValue, path) => {
                // Special handling for data.other.description
                if (path.includes("data.other.description")) {
                    if (!_.isEqual(prevValue, newValue)) {
                        changes[path] = newValue;
                    }
                    return;
                }
                if (path.includes("value.cells")) {
                    if (!_.isEqual(prevValue, newValue)) {
                        changes[path] = newValue;
                    }
                    return;
                }
                if (path.includes("text")) {
                    if (!_.isEqual(prevValue, newValue)) {
                        changes[path] = newValue;
                    }
                    return;
                }

                // Skip if values are exactly equal
                if (_.isEqual(prevValue, newValue)) {
                    return;
                }

                // Handle deletions (when new value is undefined/null but previous value existed)
                if (
                    prevValue !== undefined &&
                    (newValue === undefined || newValue === null)
                ) {
                    changes[path] = null;
                    return;
                }

                // Handle new fields
                if (prevValue === undefined && newValue !== undefined) {
                    changes[path] = newValue;
                    return;
                }

                // Handle arrays
                if (Array.isArray(prevValue) || Array.isArray(newValue)) {
                    const prevArray = Array.isArray(prevValue) ? prevValue : [];
                    const newArray = Array.isArray(newValue) ? newValue : [];

                    // Only mark array as empty if it's actually changed
                    if (
                        prevArray.length > 0 &&
                        (newArray.length === 0 || newArray === undefined)
                    ) {
                        if (!_.isEqual(prevArray, newArray)) {
                            changes[path] = [];
                        }
                        return;
                    }

                    // Track changes in array elements
                    newArray.forEach((item, index) => {
                        if (!_.isObject(item)) {
                            const arrayPath = `${path}[${index}]`;
                            if (!_.isEqual(prevArray[index], item)) {
                                changes[arrayPath] = item;
                            }
                            return;
                        }

                        const itemWithContext = { ...item, parent: newArray };
                        const uniqueField = findUniqueFields(itemWithContext);

                        if (!uniqueField) {
                            const arrayPath = `${path}[${index}]`;
                            if (!prevArray[index]) {
                                changes[arrayPath] = item;
                            } else if (!_.isEqual(prevArray[index], item)) {
                                const nestedChanges = trackNestedChanges(
                                    prevArray[index],
                                    item,
                                    arrayPath,
                                    newArray,
                                );
                                if (Object.keys(nestedChanges).length > 0) {
                                    Object.assign(changes, nestedChanges);
                                }
                            }
                            return;
                        }

                        const identifier = item[uniqueField];
                        const prevItem = prevArray.find(
                            (p) => p[uniqueField] === identifier,
                        );
                        const arrayPath = `${path}[${uniqueField}:${identifier}]`;

                        if (!prevItem) {
                            changes[arrayPath] = item;
                        } else if (!_.isEqual(prevItem, item)) {
                            const nestedChanges = trackNestedChanges(
                                prevItem,
                                item,
                                arrayPath,
                                newArray,
                            );
                            if (Object.keys(nestedChanges).length > 0) {
                                Object.assign(changes, nestedChanges);
                            }
                        }
                    });

                    // Track deleted array elements
                    prevArray.forEach((prevItem, index) => {
                        if (_.isObject(prevItem)) {
                            const uniqueField = findUniqueFields({
                                ...prevItem,
                                parent: prevArray,
                            });
                            if (uniqueField) {
                                const identifier = prevItem[uniqueField];
                                if (
                                    !newArray.find(
                                        (n) => n[uniqueField] === identifier,
                                    )
                                ) {
                                    const arrayPath = `${path}[${uniqueField}:${identifier}]`;
                                    changes[arrayPath] = null;
                                }
                            } else if (index >= newArray.length) {
                                const arrayPath = `${path}[${index}]`;
                                changes[arrayPath] = null;
                            }
                        } else if (index >= newArray.length) {
                            const arrayPath = `${path}[${index}]`;
                            changes[arrayPath] = null;
                        }
                    });
                }
                // Handle objects
                else if (_.isObject(prevValue) || _.isObject(newValue)) {
                    const prevObj = _.isObject(prevValue) ? prevValue : {};
                    const newObj = _.isObject(newValue) ? newValue : {};

                    // Only mark object as empty if it's actually changed
                    if (
                        Object.keys(prevObj).length > 0 &&
                        Object.keys(newObj).length === 0
                    ) {
                        if (!_.isEqual(prevObj, newObj)) {
                            changes[path] = {};
                        }
                        return;
                    }

                    // Track all keys from both objects to catch deletions
                    const allKeys = new Set([
                        ...Object.keys(prevObj),
                        ...Object.keys(newObj),
                    ]);
                    allKeys.forEach((key) => {
                        const nextPath = path ? `${path}.${key}` : key;
                        processValue(prevObj[key], newObj[key], nextPath);
                    });
                }
                // Handle primitive values - already checked for equality at the start
                else {
                    changes[path] = newValue;
                }
            };

            processValue(previousBlock, newBlock, parentPath);
            return changes;
        };

        const fetchAndTrackChanges = async () => {
            try {
                const { data: newData } = await getBlock({
                    variables: {
                        block_id: block?.master_block,
                    },
                });
                const fetchedBlock = newData?.worksheet_block[0];
                if (fetchedBlock) {
                    const filterBlockFields = (block) => {
                        const { data, backend } = block || {};
                        return { data, backend };
                    };

                    const excludeChangesList = (block) => {
                        const { changes_list, ...rest } = block?.data || {};
                        return { ...block, data: rest };
                    };

                    if (
                        JSON.stringify(excludeChangesList(previousBlock)) !==
                        JSON.stringify(excludeChangesList(block))
                    ) {
                        setPreviousBlock(block);
                        const changes = trackNestedChanges(
                            filterBlockFields(fetchedBlock),
                            filterBlockFields(excludeChangesList(block)),
                        );
                        if (Object.keys(changes).length > 0) {
                            setChangesList(changes);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching block data:", error);
            }
        };

        fetchAndTrackChanges();
    }, [block]);

    useEffect(() => {
        if (block && block.data) {
            setBlock({
                ...block,
                data: { ...block.data, changes_list: changesList },
            });
        }
    }, [changesList]);

    useEffect(() => {
        if (worksheetData !== undefined) {
            const extractAllInOneBlocks = (worksheetData) => {
                let allBlocks = [];

                const traverse = (block) => {
                    if (block) {
                        if (
                            block.type === "all_in_one" &&
                            block.is_synced_block === true
                        ) {
                            allBlocks.push(block);
                        }

                        if (Array.isArray(block.children)) {
                            block.children.forEach(traverse);
                        }

                        // Recursively process nested properties if necessary
                        Object.values(block).forEach((value) => {
                            if (value && typeof value === "object") {
                                traverse(value);
                            }
                        });
                    }
                };

                traverse(worksheetData);

                // Filter unique blocks based on their ID
                const uniqueBlocks = Array.from(
                    new Map(
                        allBlocks.map((block) => [block.id, block]),
                    ).values(),
                );

                return uniqueBlocks;
            };

            const filteredBlocks = extractAllInOneBlocks(worksheetData);

            setSyncBlocks(filteredBlocks);
        }
    }, [worksheetData]);

    useEffect(() => {
        if (!localSelectedFlag || reset) {
            // Only fetch sync blocks if localSelectedFlag is false
            getSyncBlocks();
        }
    }, [getSyncBlocks, localSelectedFlag, reset]); // Only runs if getSyncBlocks or localSelectedFlag changes

    useEffect(() => {
        if (data?.blocks) {
            const filteredBlocks = data.blocks.filter(
                (block) =>
                    block.type === "all_in_one" && block.parent_id !== null,
            );

            setSyncBlocks(filteredBlocks);
        }
    }, [data]); // Runs when data changes

    useEffect(() => {
        if (syncBlocks && reset) {
            const matchingSyncBlock = syncBlocks.find(
                (syncBlock) => syncBlock?.id === block?.master_block,
            );

            // Update the state with the found block
            if (matchingSyncBlock) {
                setBlock(matchingSyncBlock); // Update the state with the found block
                setChangesList({});
                setReset(false);
            }
        }
    }, [syncBlocks, reset]); // Runs when data changes
    useEffect(() => {
        if (
            masterWorksheetData &&
            masterWorksheetData.worksheet_worksheet_block_map?.[0]?.worksheet_id
        ) {
            const newWorksheetId =
                masterWorksheetData.worksheet_worksheet_block_map[0]
                    .worksheet_id;

            const currentUrl = window.location.href;

            const baseUrl = currentUrl.split("/update/")[0];
            const updatedUrl = `${baseUrl}/update/${newWorksheetId}`;

            // Save block.master_block to local storage before navigation
            const masterBlockId = block?.master_block; // Replace `block` with the variable holding the current block data
            if (masterBlockId)
                localStorage.setItem("masterBlockId", masterBlockId);

            // Navigate to the updated URL
            window.location.href = updatedUrl;
        }
    }, [masterWorksheetData, block]);

    const handleDefaultClick = () => {
        setLocalSelectedFlag(true);

        // Update block object with the new selected_flag
        updateBlockState(true);
        setIsOldFlow(true); // If the default block is clicked, switch to the old flow
    };

    useEffect(() => {
        if (block !== null && !block?.tmpId) {
            // Only run the following when block is updated

            // Perform actions that depend on block change
            setLocalSelectedFlag(true); // Set local flag
            updateBlockState(true); // Update block state
            setIsOldFlow(true); // Switch to old flow, for example
        }
    }, [block?.tmpId]); // The effect will run when `block` state changes

    const handleCardClick = (clickedBlock: any) => {
        setBlock(clickedBlock); // Update the state with the clicked block
    };
    const handleReset = () => {
        setReset(true);
        setIsResetModalVisible(false); // Close the modal without any action
        //setBlock(); // Update the state with the clicked block
    };

    // Return early if the flow has not been determined yet (avoid flicker)

    useEffect(() => {
        if (block?.tmpId) {
            setIsOldFlow(false);
        } else setIsOldFlow(true);
    }, [block?.tmpId]);

    useEffect(() => {
        if (load)
            setTimeout(() => {
                setLoad(false);
            }, 50);
    }, [load]);

    useEffect(() => {
        setCurrentEditor(`${id || tmpId}_text`);
    }, [id, tmpId]);

    useEffect(() => {
        if (!isEditor) {
            !isNewBlock && setCurrentEditor("");
            isNewBlock && setIsNewBlock(false);
        }
    }, [id, tmpId, isEditor]);

    useEffect(() => {
        if (currentEditor != "") {
            setIsEditor(true);
        }
    }, [id, tmpId, currentEditor]);

    const blockColumnProps = {
        currentComponentIndex,
        setCurrentComponentIndex: (v) => {
            if (currentComponentIndex !== v) {
                setLoad(true);
                setCurrentComponentIndex(v);
            }
        },
        ...props,
        currentEditor,
        setCurrentEditor,
        setLoad,
        setNewJob,
        updateStoriesJob,
        updateAudiosJob,
        setNewAudioJob,
        renderLinkSelect,
        chunkSlug,
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleButtonClick = () => {
        if (
            block.data?.changes_list &&
            Object.keys(block.data.changes_list).length > 0
        ) {
            setIsModalOpen(true);
        }
    };
    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        // If tmpId is present in blockColumnProps.block, we should show the new flow
        if (block?.tmpId !== null && block?.tmpId !== undefined) {
            setIsOldFlow(false); // If tmpId is available, we show the new flow
        }
    }, [block?.tmpId]); // Re-evaluate whenever tmpId changes

    const componentsEditorProps = {
        ...props,
        currentEditor,
        setCurrentEditor,
        currentComponentIndex,
        setCurrentComponentIndex,
        block:
            currentComponentIndex !== null && components[currentComponentIndex],
        setBlock: (v: any) => {
            if (currentComponentIndex !== null) {
                let tmpBlock = cloneDeep(block);
                tmpBlock = set(
                    tmpBlock,
                    ["data", ALL_IN_ONE, "components", currentComponentIndex],
                    v,
                );
                setBlock(tmpBlock);
            }
        },
        renderLinkSelect,
    };

    return (
        isOldFlow !== null && (
            <div
                style={{
                    paddingBottom: "10px",
                    display: "flex",
                    flexShrink: 1,
                }}
            >
                {block?.tmpId !== null &&
                !localSelectedFlag &&
                !worksheet.is_synced_block_worksheet &&
                worksheet.type === "personalized_learning_v4" ? (
                    // If blockColumnProps.block.tmpId is not null and isOldFlow is false, show the search bar and cards
                    <div
                        style={{
                            width: "100%",
                            minWidth: "320px",
                            flexShrink: 1,
                            flexGrow: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center the content
                            justifyContent: "center", // Vertically center content
                            textAlign: "center",
                        }}
                    >
                        {/* Search bar */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                padding: "20px 0",
                            }}
                        >
                            <input
                                type="text"
                                id="worksheet-id"
                                placeholder="Search With Block ID"
                                onChange={handleInputChange}
                                style={{
                                    width: "80%",
                                    maxWidth: "500px",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    fontSize: "16px",
                                }}
                            />
                            <button
                                onClick={handleSearch}
                                style={{
                                    padding: "10px",
                                    border: "none",
                                    borderRadius: "4px",
                                    backgroundColor: "#007BFF",
                                    color: "#fff",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                🔍 {/* Unicode search icon */}
                            </button>
                        </div>

                        {/* Cards display */}
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "20px",
                                justifyContent: "center",
                            }}
                        >
                            {/* Default Block Card (button) */}
                            {!worksheetData && (
                                <button
                                    onClick={handleDefaultClick}
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "20px",
                                        backgroundColor: "#f9f9f9",
                                        boxSizing: "border-box",
                                        textAlign: "center",
                                        cursor: "pointer", // Makes it clear that it's a button
                                        outline: "none", // Remove the default focus outline
                                        transition:
                                            "background-color 0.3s ease", // Smooth background transition
                                    }}
                                >
                                    <h3>Default Block</h3>
                                    <p>This is a default block</p>
                                </button>
                            )}

                            {/* Example Blocks (buttons) */}
                            {syncBlocks.map((block: any) => (
                                <button
                                    key={block?.id}
                                    onClick={() => handleCardClick(block)}
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        border: "1px solid #ccc",
                                        borderRadius: "8px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "20px",
                                        backgroundColor: "#f0f0f0",
                                        boxSizing: "border-box",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        outline: "none",
                                        transition:
                                            "background-color 0.3s ease",
                                    }}
                                >
                                    <h3>{block?.data?.all_in_one?.name}</h3>{" "}
                                    {/* Display only the name */}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : (
                    // If blockColumnProps.block.tmpId is null or isOldFlow is true, render the original layout
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            flexShrink: 1,
                        }}
                    >
                        {!("tmpId" in block) &&
                            !("is_synced_block" in block) && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent overlay
                                        zIndex: 1, // Ensure it sits above other content
                                    }}
                                />
                            )}
                        <div
                            style={{
                                maxWidth: "400px",
                                flexShrink: 0,
                                zIndex: block.is_modified ? 0 : "auto", // Ensure components are below overlay if modified
                            }}
                        >
                            {/* Conditional overlay */}

                            {("is_modified" in block ||
                                (!("tmpId" in block) &&
                                    !("is_synced_block" in block))) && (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <button
                                        style={{
                                            padding: "5px 5px",
                                            backgroundColor: "#1890FF",
                                            color: "#fff",
                                            boxSizing: "border-box",
                                            border: "none",
                                            borderRadius: "2px",
                                            cursor: "pointer",
                                        }}
                                        onClick={handleMasterBlockButtonClick}
                                    >
                                        Edit Master Block
                                    </button>
                                    <Modal
                                        title="Confirmation"
                                        open={isMasterBlockModalVisible}
                                        onOk={handleMasterBlockConfirm}
                                        onCancel={handleClose}
                                        okText="OK"
                                        cancelText="Close"
                                    >
                                        <p>
                                            Are you sure you want to go and edit
                                            the Master Block,you will lose
                                            unsaved changes
                                        </p>
                                    </Modal>
                                    <Modal
                                        title="Confirmation"
                                        open={isResetModalVisible}
                                        onOk={handleReset}
                                        onCancel={handleClose}
                                        okText="OK"
                                        cancelText="Close"
                                    >
                                        <p>
                                            Are you sure you want to reset to
                                            Master block, new changes will be
                                            lost.
                                        </p>
                                    </Modal>
                                    <button
                                        style={{
                                            padding: "5px 5px",
                                            backgroundColor:
                                                !block.data?.changes_list ||
                                                Object.keys(
                                                    block.data.changes_list,
                                                ).length === 0
                                                    ? "#D9D9D9"
                                                    : "#1890FF", // Change color when disabled
                                            color:
                                                !block.data?.changes_list ||
                                                Object.keys(
                                                    block.data.changes_list,
                                                ).length === 0
                                                    ? "#A0A0A0"
                                                    : "#fff", // Change text color when disabled
                                            boxSizing: "border-box",
                                            border: "none",
                                            borderRadius: "2px",
                                            cursor:
                                                !block.data?.changes_list ||
                                                Object.keys(
                                                    block.data.changes_list,
                                                ).length === 0
                                                    ? "not-allowed"
                                                    : "pointer", // Change cursor when disabled
                                        }}
                                        onClick={handleButtonClick}
                                        disabled={
                                            !block.data?.changes_list ||
                                            Object.keys(block.data.changes_list)
                                                .length === 0
                                        }
                                    >
                                        View Changes
                                    </button>
                                    <button
                                        style={{
                                            padding: "5px 5px",
                                            backgroundColor:
                                                !block.data?.changes_list ||
                                                Object.keys(
                                                    block.data.changes_list,
                                                ).length === 0
                                                    ? "#D9D9D9"
                                                    : "#1890FF", // Change color when disabled
                                            color:
                                                !block.data?.changes_list ||
                                                Object.keys(
                                                    block.data.changes_list,
                                                ).length === 0
                                                    ? "#A0A0A0"
                                                    : "#fff", // Change text color when disabled
                                            boxSizing: "border-box",
                                            border: "none",
                                            borderRadius: "2px",
                                            cursor:
                                                !block.data?.changes_list ||
                                                Object.keys(
                                                    block.data.changes_list,
                                                ).length === 0
                                                    ? "not-allowed"
                                                    : "pointer", // Change cursor when disabled
                                        }}
                                        onClick={() => {
                                            setIsResetModalVisible(true);
                                        }}
                                        disabled={
                                            !block.data?.changes_list ||
                                            Object.keys(block.data.changes_list)
                                                .length === 0
                                        }
                                    >
                                        Reset to Master block
                                    </button>

                                    {isModalOpen && (
                                        <ChangesModal
                                            changesList={
                                                block.data.changes_list
                                            }
                                            isModalOpen={isModalOpen}
                                            setIsModalOpen={setIsModalOpen} // Pass the state handler
                                            setChangesList={setChangesList}
                                        />
                                    )}
                                </div>
                            )}

                            <BlockColumn {...blockColumnProps} />
                        </div>
                        <div
                            style={{
                                width: "100%",
                                minWidth: "320px",
                                flexShrink: 1,
                                flexGrow: 0,
                                zIndex: block.is_modified ? 0 : "auto", // Ensure components are below overlay if modified
                            }}
                        >
                            {currentComponentIndex !== null &&
                            components[currentComponentIndex] ? (
                                load ? (
                                    <></>
                                ) : (
                                    <ComponentsEditor
                                        {...componentsEditorProps}
                                    />
                                )
                            ) : (
                                <QuestionColumn {...blockColumnProps} />
                            )}
                        </div>
                    </div>
                )}
            </div>
        )
    );
};

export default Editor;

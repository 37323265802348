import {
    EditOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    AppstoreAddOutlined,
    MenuOutlined,
    EyeInvisibleOutlined,
} from "@ant-design/icons";
import CellInputPopup from "./CellInputPopup";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Popconfirm,
    Popover,
    Row,
    Select,
    Space,
    Switch,
    Table,
    message,
    Collapse,
    notification,
} from "antd";
import _, { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import TableBorderEditor from "./TableBorder";
import TablePaddingEditor from "./TablePadding";
import { CopyIcon } from "src/components";
import FeedbackAndEvaluation from "./FeedbackAndEvaluation";
import Canvas from "./Canvas";
import InteractionLimitEditor from "./InteractionLimitEditor";
import Tappable from "./Tappable";
import AlignmentComponent from "./Alignment";
import MinMaxWidthComponent from "./MinMaxWidth";
import MinMaxHeight from "./MinMaxHeight";
import CellInnerContentPreview from "./CellInnerContentPreview";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TableToolbar from "../../AllInOne/renderEditor/ComponentsEditor/GenericTable/Toolbar/TableToolbar";
import { v4 as uuid } from "uuid";
import TableEffects from "./TableEffects";
import CellForm from "./CellForm";
import { getAllSelections } from "../../AllInOne/renderEditor/ComponentsEditor/GenericTable/Toolbar/utils";
import Animations from "./Animations/row";
import Gamify from "./Gamify";
import TablePreviewComponent from "../../AllInOne/renderEditor/ComponentsEditor/GenericTable/preview";
import { DEFAULT_TABLE } from ".";
import emitter from "src/helpers/emitter";
import {
    MAIN_TABLE_CELL_SELECTED,
    RESET_SELECTION,
} from "src/helpers/events/table/constants";
import PuzzleSettings from "./PuzzleSettings";
import PopulateDropArea from "../../AllInOne/renderEditor/ComponentsEditor/GenericTable/Toolbar/PopulateDropArea";
import KTWrapper from "src/modules/worksheet/components/custom/KTComponent";
import DragAndDropSettings from "./DragAndDropSettings";
import InteractionEventsSettings from "./InteractionEventsSettings";

const { Panel } = Collapse;

export const DEFAULT_CELL = {
    tappable: {
        enabled: false,
        default: {
            selected: "0",
        },
    },
    text: {
        enabled: false,
        fontSize: {
            default: 16,
        },
        alignment: {
            default: "CENTER",
        },
        value: {
            default: "",
        },
        color: {
            default: "#000000",
        },
    },
    input: {
        enabled: false,
        default: "",
        fill: "#ffffff",
        min: "0",
        max: "",
        min_feedback_text: "",
        max_feedback_text: "",
        min_feedback_audio: "",
        max_feedback_audio: "",
    },
    dropdown: {
        enabled: false,
        default: "",
        optionVariable: "",
    },
    image: {
        enabled: false,
        src: {
            default: "",
        },
    },
    fillColor: {
        enabled: false,
        default: "#F5F5F5",
    },
    clickable: {
        enabled: false,
        // linked_compute_function: "",
    },
    inputChangeFeedback: "",
};

const modifyCellForDuplicate = (
    cell: any,
    rowIndex: number,
    columnIndex: number,
    hadTags: boolean = false,
    hadDrawing: boolean = false,
) => {
    let newCell = _.cloneDeep(cell);
    newCell.id = `${rowIndex}-${columnIndex}-${Math.random()
        .toString(36)
        .substring(7)}`;
    if (newCell.linked_global_context_variable) {
        newCell.linked_global_context_variable.name = null;
        newCell.value = null;
    }
    if (!hadTags && cell.dragDrop?.enabled && cell.dragDrop?.droppedSource) {
        newCell.dragDrop.droppedSource = null;
    }
    if (!hadDrawing) {
        newCell.drawing = null;
    }
    return newCell;
};

function applyBorders(selectedCellsIndices, borderObject, tableData) {
    const borderMap = new Map();
    const { type, ...borderConfig } = borderObject;
    const defaultBorderProperties = {
        ...borderConfig,
    };

    function addBorderToMap(row, col, side) {
        const id = `${row}-${col}`;

        const existingEntry = borderMap.get(id) || { id, border: {} };

        existingEntry.border[side] = { ...defaultBorderProperties };

        borderMap.set(id, existingEntry);
    }

    function hasAdjacentCell(row, col, direction) {
        let targetRow = row;
        let targetCol = col;

        switch (direction) {
            case "top":
                targetRow--;
                break;
            case "bottom":
                targetRow++;
                break;
            case "left":
                targetCol--;
                break;
            case "right":
                targetCol++;
                break;
        }

        return selectedCellsIndices.some(
            ([selectedRow, selectedCol]) =>
                selectedRow === targetRow && selectedCol === targetCol,
        );
    }

    const columns = [...new Set(selectedCellsIndices.map(([row, col]) => col))];
    const rows = [...new Set(selectedCellsIndices.map(([row, col]) => row))];

    const cellsByColumn = new Map();
    const cellsByRow = new Map();

    selectedCellsIndices.forEach(([row, col]) => {
        if (!cellsByColumn.has(col)) {
            cellsByColumn.set(col, []);
        }
        cellsByColumn.get(col).push(row);

        if (!cellsByRow.has(row)) {
            cellsByRow.set(row, []);
        }
        cellsByRow.get(row).push(col);
    });

    cellsByColumn.forEach((rows) => rows.sort((a, b) => a - b));
    cellsByRow.forEach((cols) => cols.sort((a, b) => a - b));

    switch (type) {
        case "ALL_BORDERS":
            selectedCellsIndices.forEach(([row, col]) => {
                addBorderToMap(row, col, "top");
                addBorderToMap(row, col, "bottom");
                addBorderToMap(row, col, "left");
                addBorderToMap(row, col, "right");
            });
            break;
        case "INNER_BORDERS":
            selectedCellsIndices.forEach(([row, col]) => {
                const hasTop = hasAdjacentCell(row, col, "top");
                const hasBottom = hasAdjacentCell(row, col, "bottom");
                const hasLeft = hasAdjacentCell(row, col, "left");
                const hasRight = hasAdjacentCell(row, col, "right");

                const adjacentCount = [
                    hasTop,
                    hasBottom,
                    hasLeft,
                    hasRight,
                ].filter(Boolean).length;

                if (adjacentCount === 4) {
                    addBorderToMap(row, col, "top");
                    addBorderToMap(row, col, "bottom");
                    addBorderToMap(row, col, "left");
                    addBorderToMap(row, col, "right");
                } else {
                    if (hasTop) addBorderToMap(row, col, "top");
                    if (hasBottom) addBorderToMap(row, col, "bottom");
                    if (hasLeft) addBorderToMap(row, col, "left");
                    if (hasRight) addBorderToMap(row, col, "right");
                }
            });
            break;
        case "OUTER_BORDERS":
            selectedCellsIndices.forEach(([row, col]) => {
                const hasTop = hasAdjacentCell(row, col, "top");
                const hasBottom = hasAdjacentCell(row, col, "bottom");
                const hasLeft = hasAdjacentCell(row, col, "left");
                const hasRight = hasAdjacentCell(row, col, "right");

                const adjacentCount = [
                    hasTop,
                    hasBottom,
                    hasLeft,
                    hasRight,
                ].filter(Boolean).length;
                if (adjacentCount === 4) return;

                if (!hasTop) addBorderToMap(row, col, "top");
                if (!hasBottom) addBorderToMap(row, col, "bottom");
                if (!hasLeft) addBorderToMap(row, col, "left");
                if (!hasRight) addBorderToMap(row, col, "right");
            });
            break;
        case "TOP_BORDERS":
            cellsByColumn.forEach((rows, col) => {
                let previousRow = null;

                rows.forEach((row, index) => {
                    if (index === 0 || row > previousRow + 1) {
                        addBorderToMap(row, col, "top");
                    }

                    previousRow = row;
                });
            });
            break;
        case "BOTTOM_BORDERS":
            cellsByColumn.forEach((rows, col) => {
                let previousRow = null;

                rows.forEach((row, index) => {
                    if (
                        index === rows.length - 1 ||
                        rows[index + 1] > row + 1
                    ) {
                        addBorderToMap(row, col, "bottom");
                    }

                    previousRow = row;
                });
            });
            break;
        case "LEFT_BORDERS":
            cellsByRow.forEach((cols, row) => {
                let previousCol = null;

                cols.forEach((col, index) => {
                    // If it's the first column in the row, or there's a gap
                    if (index === 0 || col > previousCol + 1) {
                        addBorderToMap(row, col, "left");
                    }

                    previousCol = col;
                });
            });
            break;
        case "RIGHT_BORDERS":
            cellsByRow.forEach((cols, row) => {
                let previousCol = null;

                cols.forEach((col, index) => {
                    if (
                        index === cols.length - 1 ||
                        cols[index + 1] > col + 1
                    ) {
                        addBorderToMap(row, col, "right");
                    }

                    previousCol = col;
                });
            });
            break;
        default:
            console.log("Invalid border type specified!");
    }

    const correctedMap = new Map();

    for (const [id, entry] of borderMap.entries()) {
        const [row, col] = id.split("-").map(Number);

        for (const [borderSide, borderConfig] of Object.entries(entry.border)) {
            switch (borderSide) {
                case "top":
                    if (row > 0) {
                        const cellAbove = tableData.cells[row - 1]?.[col];
                        if (cellAbove) {
                            const newId = `${row - 1}-${col}`;
                            const existingEntry = correctedMap.get(newId) || {
                                id: newId,
                                border: {},
                            };
                            existingEntry.border.bottom = { ...borderConfig };
                            correctedMap.set(newId, existingEntry);
                            continue;
                        }
                    }

                    // eslint-disable-next-line no-case-declarations
                    const existingEntryTop = correctedMap.get(id) || {
                        id,
                        border: {},
                    };
                    existingEntryTop.border.top = { ...borderConfig };
                    correctedMap.set(id, existingEntryTop);
                    break;

                case "left":
                    if (col > 0) {
                        const cellLeft = tableData.cells[row]?.[col - 1];
                        if (cellLeft) {
                            const newId = `${row}-${col - 1}`;
                            const existingEntry = correctedMap.get(newId) || {
                                id: newId,
                                border: {},
                            };
                            existingEntry.border.right = { ...borderConfig };
                            correctedMap.set(newId, existingEntry);
                            continue;
                        }
                    }

                    const existingEntryLeft = correctedMap.get(id) || {
                        id,
                        border: {},
                    };
                    existingEntryLeft.border.left = { ...borderConfig };
                    correctedMap.set(id, existingEntryLeft);
                    break;

                case "right":
                    const existingEntryRight = correctedMap.get(id) || {
                        id,
                        border: {},
                    };
                    existingEntryRight.border.right = { ...borderConfig };
                    correctedMap.set(id, existingEntryRight);
                    break;

                case "bottom":
                    const existingEntryBottom = correctedMap.get(id) || {
                        id,
                        border: {},
                    };
                    existingEntryBottom.border.bottom = { ...borderConfig };
                    correctedMap.set(id, existingEntryBottom);
                    break;

                default:
                    console.log(`Unknown border side: ${borderSide}`);
            }
        }
    }

    return correctedMap;
}

const reorderSelectionLeftToRight = (selection) => {
    // Sort the selection by rows (first element of the coordinate) and then by columns (second element)
    return selection.sort(([rowA, colA], [rowB, colB]) => {
        if (rowA === rowB) {
            // If rows are the same, sort by column (left to right)
            return colA - colB;
        }
        // Otherwise, sort by row (top to bottom)
        return rowA - rowB;
    });
};

const getRowAndColCount = (selection) => {
    if (!selection || selection.length === 0)
        return { rowCount: 0, colCount: 0 };

    // Extract rows and columns from the selection
    const rows = selection.map((coord) => coord[0]);
    const cols = selection.map((coord) => coord[1]);

    // Get unique rows and columns
    const uniqueRows = [...new Set(rows)];
    const uniqueCols = [...new Set(cols)];

    // Calculate row and column count
    const rowCount = uniqueRows.length;
    const colCount = uniqueCols.length;

    return { rowCount, colCount };
};
const TableEditor = ({
    disableWrite,
    table,
    tableVariableName,
    setBlock,
    dropdownGlobalVariables,
    currentEditor,
    setCurrentEditor,
    hasMentions,
    mentionsList = [],
    renderLinkSelect,
    tagsList,
    optionsSchema,
    tagsClusterList,
}: any) => {
    const { cells = [] } = table;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [idsGenerated, setIdsGenerated] = useState(false);
    const [curCellRow, setCurCellRow] = useState(-1);
    const [curCellCol, setCurCellCol] = useState(-1);
    const [inputRow, setInputRow] = useState(1);
    const [inputCol, setInputCol] = useState(1);
    const [curCell, setCurCell] = useState({});
    const [tempConfig, setTempConfig] = useState("");
    const [selectedCells, setSelectedCells] = useState<[number, number][]>([]);

    const [isPreview, setPreview] = useState(false);
    const [showMeasures, setShowMeasures] = useState(false);
    const [
        previewMainTableSelectionActive,
        setPreviewMainTableSelectionActive,
    ] = useState(false);

    useEffect(() => {
        const listener = (data) => {
            const { coordinates, isAdded } = data;
            const { rowIndex, columnIndex } = coordinates;
            if (isAdded) {
                setSelectedCells([...selectedCells, [rowIndex, columnIndex]]);
            } else {
                setSelectedCells(
                    selectedCells.filter(
                        ([x, y]) => x !== rowIndex || y !== columnIndex,
                    ),
                );
            }
        };
        emitter.on(MAIN_TABLE_CELL_SELECTED, listener);
        return () => {
            emitter.off(MAIN_TABLE_CELL_SELECTED, listener);
        };
    }, [selectedCells]);

    useEffect(() => {
        if (!idsGenerated) {
            let tmpBlock = _.cloneDeep(table);
            let tempData = tmpBlock?.cells;
            let idsExist = true;
            tempData?.forEach((row: any, i: number) => {
                row?.forEach((cell: any, j: number) => {
                    // Generate a randomized string prefix for the cell id
                    // to avoid id conflicts
                    const idPrefix = Math.random().toString(36).substring(7);
                    if (cell.id === undefined) {
                        cell.id = i + "-" + j + "-" + idPrefix;
                        idsExist = false;
                    }
                });
            });
            setIdsGenerated(true);
            if (!idsExist) {
                tmpBlock.cells = tempData;
                setBlock(tmpBlock);
            }
        }

        originalTableDataRef.current = _.cloneDeep(tableData);
    }, [table, idsGenerated]);

    const showModal = (row: number, column: number, cell: any) => {
        setCurCellRow(row);
        setCurCellCol(column);
        setCurCell(cell);
        setIsModalOpen(true);
    };

    const closeForm = () => {
        setCurCellRow(-1);
        setCurCellCol(-1);
        setCurCell({});
        setIsModalOpen(false);
    };

    const handleDuplicateRow = (row: number) => {
        let tmpBlock = _.cloneDeep(table);
        let tempData = tmpBlock?.cells;
        tempData.splice(
            row + 1,
            0,
            cells[row].map((cell: any, columnIndex: number) => {
                return modifyCellForDuplicate(cell, row + 1, columnIndex);
            }),
        );
        tmpBlock.cells = tempData;
        setBlock(tmpBlock);
    };

    const handleDuplicateColumn = (column: number) => {
        let tmpBlock = _.cloneDeep(table);
        let tempData = tmpBlock?.cells;
        tempData.forEach((row: any, i: number) => {
            row.splice(
                column + 1,
                0,
                modifyCellForDuplicate(row[column], i, column + 1),
            );
        });
        tmpBlock.cells = tempData;
        setBlock(tmpBlock);
    };

    const allIndicesPresent = useMemo(() => {
        if (!cells?.length || !cells[0]?.length) return false;
        const cellIndices = cells.flatMap((row, i) =>
            row.map((_, j) => [i, j]),
        );
        return cellIndices.every(([i, j]) =>
            selectedCells.some(([x, y]) => x === i && y === j),
        );
    }, [selectedCells, cells]);

    const [selectedRows, selectedCols] = useMemo(() => {
        if (!selectedCells?.length) return [[], []];
        const maxRow = cells.length - 1;
        const maxCol = cells[0].length - 1;

        // Check if all elements of each row are present in selectedCells
        const rows = [];
        for (let i = 0; i <= maxRow; i++) {
            const row = cells[i];
            const rowCells = row.every((_, j) =>
                selectedCells.some(([x, y]) => x === i && y === j),
            );
            if (rowCells) {
                rows.push(i);
            }
        }

        // Check if all elements of each column are present in selectedCells
        const cols = [];
        for (let j = 0; j <= maxCol; j++) {
            const colCells = cells
                .map((row, i) =>
                    selectedCells.some(([x, y]) => x === i && y === j),
                )
                .every(Boolean);
            if (colCells) {
                cols.push(j);
            }
        }

        return [rows, cols];
    }, [selectedCells]);

    useEffect(() => {
        const handlePaste = (event) => {
            if (disableWrite) return;
            const activeElement = document?.activeElement;
            const tagName = activeElement?.tagName
                ? activeElement.tagName.toLowerCase()
                : "";
            const isEditable =
                (activeElement instanceof HTMLElement &&
                    activeElement.isContentEditable) ||
                tagName === "textarea" ||
                tagName === "input";

            if (!isEditable) {
                if (event.ctrlKey && event.key === "v") {
                    event.preventDefault(); // Prevent default paste behavior
                    handlePasteToCells();
                }
            }
        };

        document.body.addEventListener("keydown", handlePaste);

        return () => {
            document.body.removeEventListener("keydown", handlePaste);
        };
    }, [selectedCells, cells]);

    const columns = [
        {
            dataIndex: "drag",
            key: "drag",
            title: (
                <Button
                    disabled={disableWrite}
                    shape="circle"
                    type={allIndicesPresent ? "primary" : "default"}
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={() => {
                        if (allIndicesPresent) setSelectedCells([]);
                        else {
                            setSelectedCells(
                                cells.flatMap((row, i) =>
                                    row.map((_, j) => [i, j]),
                                ),
                            );
                        }
                    }}
                />
            ),
            render: (val: number, __: any, index: number) => (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <Button
                        disabled={disableWrite}
                        shape="circle"
                        type={
                            selectedRows.includes(index) ? "primary" : "default"
                        }
                        size="small"
                        icon={<CheckOutlined />}
                        onClick={() => {
                            if (selectedRows.includes(index))
                                setSelectedCells(
                                    selectedCells?.filter(
                                        (v) => v[0] !== index,
                                    ),
                                );
                            else {
                                setSelectedCells([
                                    ...selectedCells?.filter(
                                        (v) => v[0] !== index,
                                    ),
                                    ...cells[0]?.map((__, i) => [index, i]),
                                ]);
                            }
                        }}
                    />
                    <MenuOutlined />
                </div>
            ),
        },
        ...(cells[0] || [])?.map((item: any, i: number) => {
            return {
                width: "auto",
                dataIndex: i,
                key: i,
                title: (
                    <>
                        <Space>
                            <Popconfirm
                                title={"Delete this column?"}
                                icon={
                                    <QuestionCircleOutlined
                                        style={{ color: "red" }}
                                    />
                                }
                                disabled={disableWrite}
                                onConfirm={() => {
                                    if (selectedCells.length != 0) {
                                        notification.error({
                                            message:
                                                "Please unselect the cells first",
                                        });
                                        return;
                                    }
                                    let tmpBlock = _.cloneDeep(table);
                                    let tempData = tmpBlock?.cells?.map(
                                        (val: any) =>
                                            val.filter(
                                                (_: any, idx: number) =>
                                                    idx !== i,
                                            ),
                                    );
                                    tmpBlock.cells = tempData;
                                    setBlock(tmpBlock);
                                }}
                                okText={"Yes"}
                                cancelText={"Cancel"}
                            >
                                <Button
                                    disabled={disableWrite}
                                    shape="circle"
                                    danger
                                    size="small"
                                    icon={
                                        <DeleteOutlined
                                            style={{ color: "red" }}
                                        />
                                    }
                                />
                            </Popconfirm>
                            <Popconfirm
                                title={"Add a new column?"}
                                disabled={disableWrite}
                                onConfirm={() => {
                                    let tmpBlock = _.cloneDeep(table);

                                    let tempData = tmpBlock?.cells?.map(
                                        (val: any) => {
                                            val.splice(
                                                i + 1,
                                                0,
                                                cloneDeep(DEFAULT_CELL),
                                            );

                                            return val;
                                        },
                                    );
                                    tmpBlock.cells = tempData;
                                    setBlock(tmpBlock);
                                }}
                                okText={"Yes"}
                                cancelText={"Cancel"}
                            >
                                <Button
                                    disabled={disableWrite}
                                    shape="circle"
                                    type="primary"
                                    size="small"
                                    icon={<PlusOutlined />}
                                />
                            </Popconfirm>
                            <Popover
                                placement="left"
                                content="Duplicate Column"
                            >
                                <Popconfirm
                                    title={"Duplicate Column?"}
                                    onConfirm={() => {
                                        handleDuplicateColumn(i);
                                    }}
                                    okText={"Yes"}
                                    cancelText={"Cancel"}
                                    disabled={disableWrite}
                                >
                                    <Button
                                        disabled={disableWrite}
                                        size="small"
                                        shape="circle"
                                        type="primary"
                                        icon={<AppstoreAddOutlined />}
                                    />
                                </Popconfirm>
                            </Popover>
                            <Button
                                disabled={disableWrite}
                                shape="circle"
                                type={
                                    selectedCols.includes(i)
                                        ? "primary"
                                        : "default"
                                }
                                size="small"
                                icon={<CheckOutlined />}
                                onClick={() => {
                                    if (selectedCols.includes(i))
                                        setSelectedCells(
                                            selectedCells?.filter(
                                                (v) => v[1] !== i,
                                            ),
                                        );
                                    else {
                                        setSelectedCells([
                                            ...selectedCells?.filter(
                                                (v) => v[1] !== i,
                                            ),
                                            ...cells.map((__, index) => [
                                                index,
                                                i,
                                            ]),
                                        ]);
                                    }
                                }}
                            />
                        </Space>
                        <MinMaxWidthComponent
                            block={
                                table.minMaxWidth ? table.minMaxWidth[i] : {}
                            }
                            disableWrite={disableWrite}
                            setBlock={(val: any) => {
                                let tmpBlock = _.cloneDeep(table);
                                if (!tmpBlock.minMaxWidth)
                                    tmpBlock.minMaxWidth = {};
                                tmpBlock.minMaxWidth[i] = val;
                                setBlock(tmpBlock);
                            }}
                        />
                    </>
                ),
                onCell: (record: any, index: number) => {
                    const cellData = cells[index][i];
                    if (cellData?.mergeHide) {
                        return {
                            rowSpan: 0,
                            colSpan: 0,
                        };
                    }
                    if (cellData?.merge?.enabled) {
                        return {
                            rowSpan: cellData?.merge?.rowSpan,
                            colSpan: cellData?.merge?.colSpan,
                        };
                    } else return {};
                },
                render: (text: any, __: any, index: number) => {
                    const cellData =
                        index === cells?.length ? null : cells[index][i];

                    const invalidStyle =
                        typeof text === "string" &&
                        text.length < 1 &&
                        cellData?.type === "text"
                            ? { background: "rgba(255,0,0,0.1)" }
                            : {};
                    // if(cellData?.mergeHide) return;
                    return {
                        props: {
                            style:
                                index === cells.length
                                    ? {}
                                    : {
                                          borderLeft: "1px solid black",
                                          borderBottom: "1px solid black",
                                          ...invalidStyle,
                                      },
                        },
                        children: (
                            <CellForm
                                index={index}
                                inde={index}
                                i={i}
                                cellData={cellData}
                                disableWrite={disableWrite}
                                selectedCells={selectedCells}
                                setSelectedCells={setSelectedCells}
                                showModal={showModal}
                                tagsList={tagsList}
                                optionsSchema={optionsSchema}
                                tagsClusterList={tagsClusterList}
                                curCell={curCell}
                                table={table}
                                setBlock={setBlock}
                                currentEditor={currentEditor}
                                setCurrentEditor={setCurrentEditor}
                                hasMentions={hasMentions}
                                mentionsList={mentionsList}
                                cells={cells}
                                isPuzzleEnabled={table?.puzzle?.enabled}
                                isSliderEnabled={table?.puzzle?.slider?.enabled}
                            />
                        ),
                    };
                },
            };
        }),
        {
            dataIndex: "index",
            key: "index",
            title: "Row Actions",
            render: (val: number) => {
                return {
                    props: {
                        style:
                            val >= 0
                                ? {
                                      borderLeft: "1px solid black",
                                  }
                                : null,
                    },

                    children:
                        val >= 0 ? (
                            <>
                                {!disableWrite && (
                                    <Space>
                                        {cells?.length > 1 && (
                                            <Popconfirm
                                                title={"Delete this row?"}
                                                icon={
                                                    <QuestionCircleOutlined
                                                        style={{ color: "red" }}
                                                    />
                                                }
                                                onConfirm={() => {
                                                    if (
                                                        selectedCells.length !=
                                                        0
                                                    ) {
                                                        notification.error({
                                                            message:
                                                                "Please unselect the cells first",
                                                        });
                                                        return;
                                                    }
                                                    let tmpBlock =
                                                        _.cloneDeep(table);
                                                    let tempData =
                                                        tmpBlock?.cells;
                                                    tempData.splice(val, 1);
                                                    tmpBlock.cells = tempData;
                                                    setBlock(tmpBlock);
                                                }}
                                                okText={"Yes"}
                                                cancelText={"Cancel"}
                                            >
                                                <Button
                                                    shape="circle"
                                                    danger
                                                    icon={
                                                        <DeleteOutlined
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Popconfirm>
                                        )}
                                        <Popconfirm
                                            title={"Add a row below?"}
                                            onConfirm={() => {
                                                let tmpBlock =
                                                    _.cloneDeep(table);
                                                let tempData = tmpBlock?.cells;
                                                tempData.splice(
                                                    val + 1,
                                                    0,
                                                    cells[0].map(() =>
                                                        cloneDeep(DEFAULT_CELL),
                                                    ),
                                                );
                                                tmpBlock.cells = tempData;
                                                setBlock(tmpBlock);
                                            }}
                                            okText={"Yes"}
                                            cancelText={"Cancel"}
                                        >
                                            <Button
                                                shape="circle"
                                                type="primary"
                                                icon={<PlusOutlined />}
                                            />
                                        </Popconfirm>
                                        <Popover
                                            placement="left"
                                            content="Duplicate Row"
                                        >
                                            <Popconfirm
                                                title={"Duplicate Row?"}
                                                onConfirm={() => {
                                                    handleDuplicateRow(val);
                                                }}
                                                okText={"Yes"}
                                                cancelText={"Cancel"}
                                            >
                                                <Button
                                                    shape="circle"
                                                    type="primary"
                                                    icon={
                                                        <AppstoreAddOutlined />
                                                    }
                                                />
                                            </Popconfirm>
                                        </Popover>
                                    </Space>
                                )}
                                <MinMaxHeight
                                    disableWrite={disableWrite}
                                    block={
                                        table.minHeight
                                            ? table.minHeight[val]
                                            : {}
                                    }
                                    setBlock={(value: any) => {
                                        let tmpBlock = _.cloneDeep(table);
                                        if (!tmpBlock.minHeight)
                                            tmpBlock.minHeight = {};
                                        tmpBlock.minHeight[val] = value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </>
                        ) : null,
                };
            },
        },
    ];

    const tableData = [
        ...(cells || [])?.map((item: any, i: number) => {
            const cell: any = { key: i + 1, index: i };

            item?.forEach((_: any, j: number) => {
                cell[j] = "";
            });
            return cell;
        }),
    ];

    const cellInputProps = {
        isModalOpen,
        closeForm,
        curCellRow,
        curCellCol,
        curCell,
        block: table,
        setBlock,
        disableWrite,
        dropdownGlobalVariables,
        categoryLists: table?.dragDrop?.categoryLists,
        tableVariableName,
        currentEditor,
        setCurrentEditor,
        hasMentions,
        mentionsList,
        renderLinkSelect,
        tagsList,
    };

    const handlePasteToCells = async (
        has_tags = false,
        has_drawing = false,
    ) => {
        try {
            if (!selectedCells?.length) return;
            const clipboardData = await navigator.clipboard.readText();
            if (!clipboardData) return;
            let data = JSON.parse(clipboardData);
            if (data?.type !== "cell") return;
            delete data.type;
            const tmpBlock = _.cloneDeep(table);
            const newTags: any = [];
            const oldTags: any = [];
            selectedCells.forEach(([row, col]) => {
                let tag = modifyCellForDuplicate(
                    data,
                    row,
                    col,
                    has_tags,
                    has_drawing,
                );
                if (has_tags && tag?.dragDrop?.droppedSource) {
                    if (
                        tmpBlock.cells[row][col]?.dragDrop?.droppedSource
                            ?.genericId
                    ) {
                        tag.dragDrop.droppedSource.genericId =
                            tmpBlock.cells[row][
                                col
                            ].dragDrop.droppedSource.genericId;
                        oldTags.push(tag.dragDrop.droppedSource);
                    } else {
                        tag.dragDrop.droppedSource.genericId = uuid();
                        newTags.push(tag.dragDrop.droppedSource);
                    }
                }
                if (!has_drawing) {
                    if (tmpBlock.cells[row][col]?.drawing) {
                        tag.drawing = tmpBlock.cells[row][col].drawing;
                    }
                }
                tmpBlock.cells[row][col] = tag;
            });
            setBlock(tmpBlock, { oldTags, newTags });
            message.success("Cells Updated!");
        } catch (error) {}
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            let tmpBlock = _.cloneDeep(table);
            let dragWidth = tmpBlock?.minHeight?.[dragIndex];
            let dropWidth = tmpBlock?.minHeight?.[hoverIndex];
            tmpBlock.minHeight = {
                ...(tmpBlock?.minHeight || {}),
                [dragIndex]: dropWidth || { enabled: false },
                [hoverIndex]: dragWidth || { enabled: false },
            };
            const dragRow = tmpBlock.cells[dragIndex];
            const newData = [...tmpBlock.cells];
            newData.splice(dragIndex, 1);
            newData.splice(hoverIndex, 0, dragRow);
            tmpBlock.cells = newData;
            setBlock(tmpBlock);
        },
        [table],
    );

    const moveColumn = useCallback(
        (dragIndex, hoverIndex) => {
            let tmpBlock = _.cloneDeep(table);
            let dragWidth = tmpBlock?.minMaxWidth?.[dragIndex - 1];
            let dropWidth = tmpBlock?.minMaxWidth?.[hoverIndex - 1];
            tmpBlock.minMaxWidth = {
                ...(tmpBlock?.minMaxWidth || {}),
                [dragIndex - 1]: dropWidth || { enabled: false },
                [hoverIndex - 1]: dragWidth || { enabled: false },
            };
            const tempData = tmpBlock?.cells?.map((val: any) => {
                const dragColumn = val[dragIndex - 1];
                const newColumns = [...val];
                newColumns.splice(dragIndex - 1, 1);
                newColumns.splice(hoverIndex - 1, 0, dragColumn);
                return newColumns;
            });
            tmpBlock.cells = tempData;
            setBlock(tmpBlock);
        },
        [table],
    );
    const originalTableDataRef = useRef<any[] | null>(null);

    const toolbarProps = {
        curCellRow,
        curCellCol,
        table,
        setBlock,
        selectedCells: selectedCells
            .map(([row, col]) => table?.cells[row]?.[col])
            .filter(Boolean),
        selectedCellsIndices: selectedCells,
        setBorderProperty: (v) => {
            const {
                type,
                value,
                is_enabled,
                subType,
                path = [],
                name,
                tableData,
            } = v;

            let tmpBlock = _.cloneDeep(table);
            const borderMap = applyBorders(selectedCells, value, tmpBlock);
            const selectedMapCells: any = [];

            borderMap.forEach((cellData, key) => {
                const [row, col] = key.split("-").map(Number);
                selectedMapCells.push([row, col]);
            });

            if (subType == "RESET") {
                borderMap.forEach((cellData, key) => {
                    const [row, col] = key.split("-").map(Number);

                    if (!tmpBlock.cells?.[row]?.[col]) return;

                    const existingBorder =
                        tmpBlock.cells[row][col]?.border || {};

                    Object.entries(cellData.border).forEach(
                        ([side, borderData]) => {
                            if (existingBorder[side]) {
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["cells", row, col, "border", side],
                                    {
                                        color: "",
                                        width: 0,
                                        style: "",
                                        radius: 0,
                                        opacity: 0,
                                        enabled: false,
                                    },
                                );
                            }
                        },
                    );
                    ["top", "right", "bottom", "left"].forEach((side) => {
                        if (!cellData.border[side] && existingBorder[side]) {
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", row, col, "border", side],
                                existingBorder[side],
                            );
                        }
                    });
                });
                setBlock(tmpBlock);
                return;
            }
            selectedCells.forEach((v) => {
                if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                    tmpBlock = _.set(
                        tmpBlock,
                        ["cells", v[0], v[1], "border", "enabled"],
                        is_enabled,
                    );
                }
            });

            borderMap.forEach((cellData, key) => {
                const [row, col] = key.split("-").map(Number);

                if (!tmpBlock.cells?.[row]?.[col]) return;

                const existingBorder = tmpBlock.cells[row][col]?.border || {};

                Object.entries(cellData.border).forEach(
                    ([side, borderData]) => {
                        if (existingBorder[side]) {
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", row, col, "border", side],
                                {
                                    color: "",
                                    width: 0,
                                    style: "",
                                    radius: 0,
                                    opacity: 0,
                                    enabled: is_enabled,
                                },
                            );
                        }
                    },
                );

                ["top", "right", "bottom", "left"].forEach((side) => {
                    if (!cellData.border[side] && existingBorder[side]) {
                        tmpBlock = _.set(
                            tmpBlock,
                            ["cells", row, col, "border", side],
                            existingBorder[side],
                        );
                    }
                });
            });

            if (subType == "GRANULAR") {
                const borderStyle = {
                    color: value.color,
                    width: value.width,
                    style: value.style,
                    radius: value.radius,
                    opacity: value.opacity,
                    enabled: is_enabled,
                };

                borderMap.forEach((cellData, key) => {
                    const [row, col] = key.split("-").map(Number);

                    if (!tmpBlock.cells?.[row]?.[col]) return;

                    const existingBorder =
                        tmpBlock.cells[row][col]?.border || {};

                    tmpBlock = _.set(tmpBlock, ["cells", row, col, "border"], {
                        selective: is_enabled,
                        enabled: is_enabled,
                    });

                    Object.entries(cellData.border).forEach(
                        ([side, borderData]) => {
                            const existingSideBorder =
                                existingBorder[side] || {};

                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", row, col, "border", side],
                                {
                                    ...existingSideBorder,
                                    ...borderStyle,
                                    ...borderData,
                                },
                            );
                        },
                    );

                    ["top", "right", "bottom", "left"].forEach((side) => {
                        if (!cellData.border[side] && existingBorder[side]) {
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", row, col, "border", side],
                                existingBorder[side],
                            );
                        }
                    });
                });
            }
            setBlock(tmpBlock);
        },
        setProperty: (v) => {
            const { type, value, is_enabled, subType, path = [], name } = v;
            let tmpBlock = _.cloneDeep(table);

            switch (type) {
                case "CORRECT_VALUES":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]])
                            tmpBlock.cells[v[0]][v[1]].correctInputValues =
                                value;
                    });
                    break;
                case "HIDDEN":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            tmpBlock.cells[v[0]][v[1]].hidden = value;
                        }
                    });
                    break;
                case "ALIGN":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]])
                            tmpBlock.cells[v[0]][v[1]].align = value;
                    });
                    break;
                case "ALIGN_VERTICAL":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]])
                            tmpBlock.cells[v[0]][v[1]].alignVertical = value;
                    });
                    break;
                case "TEXT":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            switch (subType) {
                                case "color":
                                case "size":
                                case "value":
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "cells",
                                            v[0],
                                            v[1],
                                            "text",
                                            subType,
                                            "default",
                                        ],
                                        value,
                                    );
                                    break;
                                case "type":
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["cells", v[0], v[1], "text", "type"],
                                        value,
                                    );
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "cells",
                                            v[0],
                                            v[1],
                                            "text",
                                            "value",
                                            "default",
                                        ],
                                        null,
                                    );
                                    break;
                                default:
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "cells",
                                            v[0],
                                            v[1],
                                            "text",
                                            "enabled",
                                        ],
                                        is_enabled,
                                    );
                                    break;
                            }
                        }
                    });
                    break;
                case "FILL_COLOR":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "fillColor", "default"],
                                is_enabled ? value : "#f5f5f5",
                            );
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "fillColor", "enabled"],
                                is_enabled,
                            );
                        }
                    });
                    break;
                case "FILL_IMAGE":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            let imageBackgroundBlock = _.cloneDeep(
                                tmpBlock.cells?.[v[0]]?.[v[1]]
                                    ?.imageBackground || {},
                            );
                            if (subType === "size") {
                                imageBackgroundBlock.size = value;
                            } else {
                                imageBackgroundBlock.src = { default: value };
                            }
                            imageBackgroundBlock.enabled = is_enabled;
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "imageBackground"],
                                imageBackgroundBlock,
                            );
                        }
                    });
                    break;
                case "IMAGE":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            tmpBlock = _.set(
                                tmpBlock,
                                [
                                    "cells",
                                    v[0],
                                    v[1],
                                    "image",
                                    "src",
                                    "default",
                                ],
                                value,
                            );
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "image", "enabled"],
                                is_enabled,
                            );
                        }
                    });
                    break;
                case "PADDING":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            switch (subType) {
                                case "horizontal":
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "cells",
                                            v[0],
                                            v[1],
                                            "padding",
                                            "horizontal",
                                        ],
                                        value,
                                    );
                                    break;
                                case "vertical":
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        [
                                            "cells",
                                            v[0],
                                            v[1],
                                            "padding",
                                            "vertical",
                                        ],
                                        value,
                                    );
                                    break;
                                default:
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["cells", v[0], v[1], "padding"],
                                        { horizontal: 0, vertical: 0 },
                                    );
                                    break;
                            }
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "padding", "enabled"],
                                is_enabled,
                            );
                        }
                    });
                    break;
                case "BORDER":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            if (subType) {
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "cells",
                                        v[0],
                                        v[1],
                                        "border",
                                        ...path,
                                        subType,
                                    ],
                                    value,
                                );
                                if (subType === "selective") {
                                    const defaultBorderBlock =
                                        tmpBlock?.cells?.[v[0]]?.[v[1]]?.border;
                                    const defaultBorder = {
                                        color: defaultBorderBlock?.color,
                                        opacity: defaultBorderBlock?.opacity,
                                        width: defaultBorderBlock?.width,
                                        style: defaultBorderBlock?.style,
                                        radius: defaultBorderBlock?.radius,
                                    };
                                    if (!tmpBlock?.border?.top) {
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "cells",
                                                v[0],
                                                v[1],
                                                "border",
                                                "top",
                                            ],
                                            {
                                                ...defaultBorder,
                                            },
                                        );
                                    }
                                    if (!tmpBlock?.border?.bottom) {
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "cells",
                                                v[0],
                                                v[1],
                                                "border",
                                                "bottom",
                                            ],
                                            {
                                                ...defaultBorder,
                                            },
                                        );
                                    }
                                    if (!tmpBlock?.border?.left) {
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "cells",
                                                v[0],
                                                v[1],
                                                "border",
                                                "left",
                                            ],
                                            {
                                                ...defaultBorder,
                                            },
                                        );
                                    }
                                    if (!tmpBlock?.border?.right) {
                                        tmpBlock = _.set(
                                            tmpBlock,
                                            [
                                                "cells",
                                                v[0],
                                                v[1],
                                                "border",
                                                "right",
                                            ],
                                            {
                                                ...defaultBorder,
                                            },
                                        );
                                    }
                                }
                            }
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "border", "enabled"],
                                subType ? true : is_enabled,
                            );

                            let currentBorderBlock =
                                tmpBlock?.cells?.[v[0]]?.[v[1]]?.border;
                            if (currentBorderBlock?.selective && !subType) {
                                const borders = [
                                    "top",
                                    "bottom",
                                    "left",
                                    "right",
                                ];
                                borders.forEach((border) => {
                                    if (currentBorderBlock[border]?.enabled) {
                                        currentBorderBlock[border] = {
                                            ...currentBorderBlock[border],
                                            color: currentBorderBlock.color,
                                            opacity: currentBorderBlock.opacity,
                                            width: currentBorderBlock.width,
                                            style: currentBorderBlock.style,
                                            radius: currentBorderBlock.radius,
                                        };
                                    }
                                });
                            }
                        }
                    });
                    break;
                case "INPUT":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]])
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "input"],
                                value,
                            );
                    });
                    break;
                case "TAPPABLE":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            if (path?.length > 0)
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["cells", v[0], v[1], "tappable", ...path],
                                    value,
                                );
                            else {
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "cells",
                                        v[0],
                                        v[1],
                                        "tappable",
                                        "enabled",
                                    ],
                                    is_enabled,
                                );
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "cells",
                                        v[0],
                                        v[1],
                                        "clickable",
                                        "enabled",
                                    ],
                                    is_enabled,
                                );
                            }
                            tmpBlock = _.set(
                                tmpBlock,
                                [
                                    "cells",
                                    v[0],
                                    v[1],
                                    "tappable",
                                    "tap_count",
                                    "default",
                                ],
                                0,
                            );
                        }
                    });
                    break;
                case "DROPDOWN":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]])
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "dropdown"],
                                value,
                            );
                    });
                    break;
                case "DRAG_DROP":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]]) {
                            if (path?.length)
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["cells", v[0], v[1], "dragDrop", ...path],
                                    value,
                                );
                            else
                                tmpBlock = _.set(
                                    tmpBlock,
                                    [
                                        "cells",
                                        v[0],
                                        v[1],
                                        "dragDrop",
                                        "enabled",
                                    ],
                                    is_enabled,
                                );
                        }
                    });
                    break;
                case "CLICKABLE":
                    selectedCells.forEach((v) => {
                        if (tmpBlock.cells?.[v[0]]?.[v[1]])
                            tmpBlock = _.set(
                                tmpBlock,
                                ["cells", v[0], v[1], "clickable", "enabled"],
                                is_enabled,
                            );
                    });
                    break;
                case "MERGE":
                    const { mergeEnable, mergedCellIndex } = value;

                    const selectedCellsReordered = mergeEnable
                        ? getAllSelections(selectedCells, tmpBlock?.cells)
                        : tmpBlock?.cells[mergedCellIndex[0]][
                              mergedCellIndex[1]
                          ]?.merge?.mergedCells ?? [];
                    const primaryCellIndex = mergeEnable
                        ? selectedCellsReordered[0]
                        : mergedCellIndex;

                    const [primaryRow, primaryCol] = primaryCellIndex;
                    if (tmpBlock.cells?.[primaryRow]?.[primaryCol]) {
                        let mergeObj = {};
                        if (mergeEnable) {
                            const { rowCount, colCount } = getRowAndColCount(
                                selectedCellsReordered,
                            );
                            mergeObj = {
                                enabled: mergeEnable,
                                mergedCells: selectedCellsReordered,
                                rowSpan: rowCount,
                                colSpan: colCount,
                            };
                        } else {
                            mergeObj = {
                                enabled: mergeEnable,
                            };
                        }
                        tmpBlock.cells[primaryRow][primaryCol].merge = mergeObj;
                    }
                    // For rest of the selectedCellsReordered set cell.hidden to true
                    selectedCellsReordered.slice(1).forEach(([row, col]) => {
                        if (tmpBlock.cells?.[row]?.[col]) {
                            // tmpBlock.cells[row][col].hidden = mergeEnable;
                            tmpBlock.cells[row][col].mergeHide = mergeEnable;
                            // disable input, dropdown, tappable, dragDrop. set text.type to normal, check if respective object exists first
                            if (mergeEnable) {
                                if (tmpBlock.cells[row][col].input)
                                    tmpBlock.cells[row][col].input.enabled =
                                        false;
                                if (tmpBlock.cells[row][col].dropdown)
                                    tmpBlock.cells[row][col].dropdown.enabled =
                                        false;
                                if (tmpBlock.cells[row][col].tappable)
                                    tmpBlock.cells[row][col].tappable.enabled =
                                        false;
                                if (tmpBlock.cells[row][col].dragDrop)
                                    tmpBlock.cells[row][col].dragDrop.enabled =
                                        false;
                                if (tmpBlock.cells[row][col].text)
                                    tmpBlock.cells[row][col].text.type =
                                        "normal";
                            }
                        }
                    });
                    setSelectedCells([selectedCellsReordered[0]]);
                    break;
                case "ROTATION_FLOW":
                    switch (subType) {
                        case "enabled":
                            selectedCells.forEach(([row, col]) => {
                                if (tmpBlock.cells?.[row]?.[col]) {
                                    tmpBlock.cells[row][col].rotationFlow = {
                                        ...(tmpBlock.cells[row][col]
                                            .rotationFlow ?? {}),
                                        enabled: value,
                                    };
                                }
                            });
                            break;
                        default:
                            selectedCells.forEach(([row, col]) => {
                                if (tmpBlock.cells?.[row]?.[col]) {
                                    tmpBlock.cells[row][col].rotationFlow = {
                                        ...(tmpBlock.cells[row][col]
                                            .rotationFlow ?? {}),
                                        settings: {
                                            ...(tmpBlock.cells[row][col]
                                                .rotationFlow?.settings ?? {}),
                                            [subType]: value,
                                        },
                                    };
                                }
                            });
                            break;
                    }
                default:
                    break;
            }
            setBlock(tmpBlock);
        },
        handleClearSelections: () => setSelectedCells([]),
        handlePasteToCells,
        currentEditor,
        setCurrentEditor,
        disableWrite,
        hasMentions,
        mentionsList,
        allIndicesPresent,
        selectedRows,
        selectedCols,
        tagsList,
    };

    return (
        <div style={{ padding: "10px" }}>
            {!isPreview && (
                <Button
                    type="primary"
                    shape="round"
                    onClick={() => setPreview(!isPreview)}
                    style={{ marginBottom: "10px" }}
                >
                    Show Preview
                </Button>
            )}
            {isPreview && (
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        background: "white",
                        border: "3px solid black",
                        top: 0,
                        padding: "20px",
                        paddingTop: "10px",
                        minWidth: "400px",
                        zIndex: 5,
                        maxWidth: "100%",
                        maxHeight: "100vh",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <h2>Table Preview</h2>
                        <div>
                            <Button.Group>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setPreview(!isPreview);
                                        setShowMeasures(false);
                                    }}
                                >
                                    Hide Preview
                                </Button>
                                {!table?.drawing?.enabled && (
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={() => {
                                            setShowMeasures(!showMeasures);
                                        }}
                                    >
                                        {showMeasures ? "Hide " : "Show "}
                                        Measures
                                    </Button>
                                )}
                            </Button.Group>
                            {/*New button group */}
                            <br />
                            <Button.Group>
                                <Button
                                    type={
                                        previewMainTableSelectionActive
                                            ? "primary"
                                            : "dashed"
                                    }
                                    danger={previewMainTableSelectionActive}
                                    onClick={() => {
                                        if (previewMainTableSelectionActive) {
                                            emitter.emit(RESET_SELECTION);
                                            setSelectedCells([]);
                                            setPreviewMainTableSelectionActive(
                                                false,
                                            );
                                        } else {
                                            setPreviewMainTableSelectionActive(
                                                true,
                                            );
                                        }
                                    }}
                                >
                                    {previewMainTableSelectionActive
                                        ? "Reset Selection"
                                        : "Enable Selection"}
                                </Button>
                            </Button.Group>
                        </div>
                    </div>
                    <div
                        style={{
                            padding: "0 30px",
                            paddingTop: "10px",
                        }}
                    >
                        <TablePreviewComponent
                            tableData={table || DEFAULT_TABLE}
                            showMeasures={showMeasures}
                            mainTableSelectionActive={
                                previewMainTableSelectionActive
                            }
                        />
                    </div>
                </div>
            )}
            <h4>
                Table Settings:
                <CopyIcon
                    disabled={disableWrite}
                    isButton={true}
                    onClick={() => {
                        message.info(`Copied Table data to clipboard!`);
                        navigator.clipboard.writeText(JSON.stringify(table));
                    }}
                />
            </h4>
            <CellInputPopup {...cellInputProps} />
            {selectedCells?.length > 0 && <TableToolbar {...toolbarProps} />}
            {selectedCells?.length == 1 && (
                <PopulateDropArea
                    selectedCells={selectedCells}
                    tagsList={tagsList}
                    table={table}
                    tagsClusterList={tagsClusterList}
                    setBlock={setBlock}
                />
            )}
            <DndProvider backend={HTML5Backend}>
                <Table
                    bordered
                    columns={columns.map((col, index) => ({
                        ...col,
                        onHeaderCell: (column) => ({
                            index,
                            moveColumn,
                            width: column.width,
                            endCol: index == columns?.length - 1,
                            disableWrite,
                        }),
                    }))}
                    dataSource={tableData}
                    pagination={false}
                    showHeader={true}
                    scroll={{ x: "max-content" }}
                    footer={() =>
                        disableWrite ? null : (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                }}
                            >
                                <Input.Group compact>
                                    <Input
                                        type="number"
                                        onChange={(e) =>
                                            setInputRow(Number(e.target.value))
                                        }
                                        value={inputRow}
                                        style={{
                                            width: "80px",
                                            border: "1px solid block",
                                        }}
                                    />
                                    <Button
                                        disabled={inputRow < 1}
                                        type="primary"
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(table);
                                            const numRowsToAdd = inputRow;
                                            const tempData =
                                                !tmpBlock?.cells?.length ||
                                                !tmpBlock?.cells[0]?.length
                                                    ? []
                                                    : tmpBlock?.cells;

                                            for (
                                                let i = 0;
                                                i < numRowsToAdd;
                                                i++
                                            ) {
                                                const newRow = (
                                                    cloneDeep(cells[0]) || [
                                                        ...Array(3),
                                                    ]
                                                ).map(() =>
                                                    cloneDeep(DEFAULT_CELL),
                                                );
                                                tempData.push(newRow);
                                            }
                                            tmpBlock.cells = tempData;
                                            setBlock(tmpBlock);
                                            setInputRow(1);
                                        }}
                                    >
                                        Add Row
                                    </Button>
                                    <Input
                                        type="number"
                                        onChange={(e) =>
                                            setInputCol(Number(e.target.value))
                                        }
                                        value={inputCol}
                                        style={{
                                            width: "80px",
                                        }}
                                    />
                                    <Button
                                        disabled={inputCol < 1}
                                        type="primary"
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(table);

                                            const numColsToAdd = inputCol;
                                            tmpBlock?.cells?.forEach(
                                                (row, rowIndex) => {
                                                    for (
                                                        let i = 0;
                                                        i < numColsToAdd;
                                                        i++
                                                    ) {
                                                        const newCell =
                                                            _.cloneDeep(
                                                                DEFAULT_CELL,
                                                            );
                                                        tmpBlock.cells[
                                                            rowIndex
                                                        ].push(newCell);
                                                    }
                                                },
                                            );
                                            setBlock(tmpBlock);
                                            setInputCol(1);
                                        }}
                                    >
                                        Add Column
                                    </Button>
                                </Input.Group>
                                {(!cells?.length || !cells[0]?.length) && (
                                    <span
                                        style={{
                                            color: "red",
                                            fontSize: "16px",
                                        }}
                                    >
                                        No Cells Added
                                    </span>
                                )}
                            </div>
                        )
                    }
                    components={{
                        header: {
                            cell: DraggableHeader,
                        },
                        body: {
                            row: DraggableRow,
                        },
                    }}
                    onRow={(_, index) => ({
                        index,
                        moveRow,
                        disableWrite,
                    })}
                />
            </DndProvider>
            <Collapse>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Feedback and Evaluation Settings</span>
                            <Button
                                disabled={disableWrite}
                                shape="circle"
                                type={"primary"}
                                size="small"
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Feedback and Evaluation Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "feedback_eval_table",
                                            data: {
                                                evaluation: table.evaluation,
                                                feedback: table.feedback,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="0"
                >
                    <FeedbackAndEvaluation
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Canvas Settings</span>
                            <Button
                                disabled={disableWrite}
                                shape="circle"
                                type={"primary"}
                                size="small"
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Canvas Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "canvas_table",
                                            data: {
                                                evaluation: table.evaluation,
                                                feedback: table.feedback,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="1"
                >
                    <Canvas
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Reorder Settings</span>
                            <Button
                                shape="circle"
                                type={"primary"}
                                size="small"
                                disabled={disableWrite}
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Reorder Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "reorder_table",
                                            data: {
                                                reorder: table.reorder,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="2"
                >
                    <KTWrapper feature="reorder_type">
                        <Form.Item label="Reorder Type">
                            <Select
                                disabled={disableWrite}
                                value={table?.reorder?.type}
                                onChange={(val) => {
                                    let tmpBlock = _.cloneDeep(table);
                                    if (tmpBlock.reorder === undefined)
                                        tmpBlock.reorder = { type: "none" };
                                    tmpBlock.reorder.type = val;
                                    setBlock(tmpBlock);
                                }}
                                style={{
                                    width: "200px",
                                }}
                            >
                                <Select.Option value={null}>None</Select.Option>
                                <Select.Option value="ROW">Row</Select.Option>
                                <Select.Option value="COLUMN">
                                    Column
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </KTWrapper>
                    {(table.reorder?.type === "ROW" ||
                        table.reorder?.type === "COLUMN") && (
                        <Form.Item
                            label={`Disable reorder for following ${
                                table.reorder.type === "ROW"
                                    ? "rows"
                                    : "columns"
                            }`}
                        >
                            <Select
                                disabled={disableWrite}
                                mode="multiple"
                                value={table?.reorder?.disabled}
                                onChange={(val) => {
                                    let tmpBlock = _.cloneDeep(table);
                                    // delete the disabled key from the cells that are disabled
                                    if (table.reorder.type === "ROW") {
                                        tmpBlock.cells.forEach((row: any) => {
                                            row.forEach((cell: any) => {
                                                cell.blockReorder = false;
                                            });
                                        });
                                    } else if (
                                        table.reorder.type === "COLUMN"
                                    ) {
                                        tmpBlock.cells.forEach((row: any) => {
                                            row.forEach((col: any) => {
                                                col.blockReorder = false;
                                            });
                                        });
                                    }
                                    tmpBlock.reorder.disabled = val;
                                    // Update the respective cells
                                    if (table.reorder.type === "ROW") {
                                        val.forEach((row: number) => {
                                            tmpBlock.cells[row].forEach(
                                                (cell: any) => {
                                                    cell.blockReorder = true;
                                                },
                                            );
                                        });
                                    } else if (
                                        table.reorder.type === "COLUMN"
                                    ) {
                                        tmpBlock.cells.forEach((row: any) => {
                                            val.forEach((col: number) => {
                                                row[col].blockReorder = true;
                                            });
                                        });
                                    }
                                    setBlock(tmpBlock);
                                }}
                            >
                                {table.reorder.type === "ROW"
                                    ? table?.cells?.map((_: any, i: number) => (
                                          <Select.Option value={i}>
                                              {"Row" + " : " + String(i + 1)}
                                          </Select.Option>
                                      ))
                                    : table?.cells[0]?.map(
                                          (_: any, i: number) => (
                                              <Select.Option value={i}>
                                                  {"Column" +
                                                      " : " +
                                                      String(i + 1)}
                                              </Select.Option>
                                          ),
                                      )}
                            </Select>
                        </Form.Item>
                    )}
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Padding Settings</span>
                            <Button
                                disabled={disableWrite}
                                shape="circle"
                                type={"primary"}
                                size="small"
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Padding Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "padding_table",
                                            data: {
                                                padding: table.padding,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="3"
                >
                    <TablePaddingEditor
                        blockData={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Gap Settings</span>
                            <Button
                                disabled={disableWrite}
                                shape="circle"
                                type={"primary"}
                                size="small"
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Gap Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "gap_table",
                                            data: {
                                                gap: table.gap,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="4"
                >
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <KTWrapper feature="Gap_Settings">
                                <Form.Item label="Enable Custom Gaps">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={table?.gap?.enabled}
                                        onChange={(value) => {
                                            let tmpCell = _.cloneDeep(table);
                                            tmpCell = _.set(
                                                tmpCell,
                                                ["gap", "enabled"],
                                                value,
                                            );
                                            setBlock(tmpCell);
                                        }}
                                    />
                                </Form.Item>
                            </KTWrapper>
                        </Col>
                        {table?.gap?.enabled && (
                            <>
                                <Col span={12}>
                                    <Form.Item label="Horizontal Gap">
                                        {/*Number field */}
                                        <Input
                                            disabled={disableWrite}
                                            type="number"
                                            value={table?.gap?.horizontal}
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(table);
                                                if (tmpBlock.gap === undefined)
                                                    tmpBlock.gap = {
                                                        horizontal: 0,
                                                    };
                                                tmpBlock.gap.horizontal =
                                                    Number(e.target.value);
                                                setBlock(tmpBlock);
                                            }}
                                            width={200}
                                            min={0}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Vertical Gap">
                                        {/*Number field */}
                                        <Input
                                            disabled={disableWrite}
                                            type="number"
                                            value={table?.gap?.vertical}
                                            onChange={(e) => {
                                                let tmpBlock =
                                                    _.cloneDeep(table);
                                                if (tmpBlock.gap === undefined)
                                                    tmpBlock.gap = {
                                                        vertical: 0,
                                                    };
                                                tmpBlock.gap.vertical = Number(
                                                    e.target.value,
                                                );
                                                setBlock(tmpBlock);
                                            }}
                                            width={200}
                                            min={0}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Border Settings</span>
                            <Button
                                disabled={disableWrite}
                                shape="circle"
                                type={"primary"}
                                size="small"
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Border Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "border_table",
                                            data: {
                                                border: table.border,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="5"
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Border Collapse">
                                <Switch
                                    disabled={disableWrite}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={table?.borderCollapse}
                                    onChange={(value) => {
                                        let tmpBlock = _.cloneDeep(table);
                                        tmpBlock.borderCollapse = value;
                                        setBlock(tmpBlock);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <TableBorderEditor
                        blockData={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Interaction Settings</span>
                            <Button
                                shape="circle"
                                type={"primary"}
                                size="small"
                                disabled={disableWrite}
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Interaction Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "interactionLimit_table",
                                            data: {
                                                interactionLimit:
                                                    table.interactionLimit,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="6"
                >
                    <InteractionLimitEditor
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Alignment, Tap and Swipe Settings</span>
                            <Button
                                shape="circle"
                                type={"primary"}
                                size="small"
                                disabled={disableWrite}
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Alignment, Tap and Swipe Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "align_table",
                                            data: {
                                                swipable: table.swipable,
                                                align: table.align,
                                                tappable: table.tappable,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </div>
                    }
                    key="x"
                >
                    <AlignmentComponent
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                    <Tappable
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                    <KTWrapper feature="swipable_enabled">
                        <h3>Swipable</h3>
                        <Form.Item label="Enabled?">
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={table?.swipable?.enabled}
                                onChange={(value) => {
                                    let tmpCell = _.cloneDeep(table);
                                    tmpCell = _.set(
                                        tmpCell,
                                        ["swipable", "enabled"],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </KTWrapper>
                    <Animations
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Table Effects</span>
                            {/* <Button
                                shape="circle"
                                type={"primary"}
                                size="small"
                                disabled={disableWrite}
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Interaction Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "interactionLimit_table",
                                            data: {
                                                interactionLimit:
                                                    table.interactionLimit,
                                            },
                                        }),
                                    );
                                }}
                            /> */}
                        </div>
                    }
                    key="8"
                >
                    <TableEffects
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Gamify</span>
                            {/* <Button
                                shape="circle"
                                type={"primary"}
                                size="small"
                                disabled={disableWrite}
                                icon={<CopyIcon />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    message.info(
                                        `Copied Interaction Settings to clipboard!`,
                                    );
                                    navigator.clipboard.writeText(
                                        JSON.stringify({
                                            type: "interactionLimit_table",
                                            data: {
                                                interactionLimit:
                                                    table.interactionLimit,
                                            },
                                        }),
                                    );
                                }}
                            /> */}
                        </div>
                    }
                    key="9"
                >
                    <Gamify
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                        hasMentions={hasMentions}
                        mentionsList={mentionsList}
                        currentEditor={currentEditor}
                        setCurrentEditor={setCurrentEditor}
                    />
                </Panel>

                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <span>Puzzle and rotation Settings</span>
                        </div>
                    }
                    key="10"
                >
                    <PuzzleSettings
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel header={<span>Drag and Drop Settings</span>} key="11">
                    <DragAndDropSettings
                        block={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
                <Panel
                    header={<span>Interaction Events Settings</span>}
                    key="12"
                >
                    <InteractionEventsSettings
                        table={table}
                        setBlock={setBlock}
                        disableWrite={disableWrite}
                    />
                </Panel>
            </Collapse>
            <Divider />
            {!disableWrite && (
                <Form.Item label={"Paste Table config here"}>
                    <Input.TextArea
                        disabled={disableWrite}
                        value={tempConfig}
                        placeholder="Paste Config here"
                        onChange={(e) => setTempConfig(e.target.value)}
                    />
                    <Button
                        disabled={disableWrite}
                        style={{ marginTop: "10px" }}
                        type="primary"
                        onClick={() => {
                            try {
                                const parsedJson: any = JSON.parse(tempConfig);

                                if (
                                    [
                                        "align_table",
                                        "interactionLimit_table",
                                        "border_table",
                                        "gap_table",
                                        "padding_table",
                                        "reorder_table",
                                        "feedback_eval_table",
                                    ].includes(parsedJson?.type)
                                ) {
                                    setBlock({
                                        ...table,
                                        ...parsedJson?.data,
                                    });
                                } else setBlock(parsedJson);
                                setTempConfig("");
                                message.info("Updated!");
                            } catch (e) {
                                //  captureException(e)
                                message.error("Error in JSON!");
                            }
                        }}
                    >
                        Update table config
                    </Button>
                </Form.Item>
            )}
        </div>
    );
};

const DraggableRow = ({ index, moveRow, disableWrite, ...restProps }) => {
    const ref = useRef();
    const [, drop] = useDrop({
        accept: "row",
        drop(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            // Position checking logic (as in previous example) can be added here for better UX
            moveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [, drag] = useDrag({
        type: "row",
        item: { index },
    });

    drag(drop(ref));
    return <tr ref={disableWrite ? null : ref} {...restProps} />;
};

const DraggableHeader = ({
    index,
    moveColumn,
    children,
    endCol,
    disableWrite,
    ...restProps
}) => {
    const ref = useRef();
    const [, drop] = useDrop({
        accept: "header",
        drop(item) {
            if (endCol || index === 0 || !ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex !== hoverIndex) {
                moveColumn(dragIndex, hoverIndex);
                item.index = hoverIndex;
            }
        },
    });

    const [, drag] = useDrag({
        type: "header",
        item: { index },
    });

    drag(drop(ref));
    return (
        <th
            ref={disableWrite || endCol || index === 0 ? null : ref}
            {...restProps}
        >
            {children}
        </th>
    );
};

export default TableEditor;

import { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Form, List, Row } from "antd";
import _, { cloneDeep, pullAt, set } from "lodash";
import { SlateEditor } from "src/components";
import ComponentDetails from "../common/ComponentDetails";
import { LogicModal } from "../common/LogicModal";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { FeedbackPool } from "../common/FeedbackPool";
import { KEYMAP } from "../DragNDrop/mapping";
import { StickerFunctions } from "../../../defaultValue";

export const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            flexShrink: 0,
            marginTop: "30px",
            cursor: "pointer",
        }}
    />
));

const SortableItem = SortableElement(
    ({
        text,
        itemIndex: index,
        onDelete,
        onChange,
        currentEditor,
        disableWrite,
        hasMentions,
        mentionsList,
        setCurrentEditor,
    }) => (
        <List.Item
            key={index}
            actions={[
                <Button
                    icon={<DeleteOutlined style={{ color: "red" }} />}
                    shape="circle"
                    danger
                    disabled={disableWrite}
                    onClick={() => onDelete(index)}
                />,
            ]}
        >
            <Badge.Ribbon text={index + 1} color="#faad14" placement="start">
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        gap: "20px",
                        minWidth: "200px",
                    }}
                >
                    <DragHandle />
                    <div
                        style={{
                            width: "100%",
                            flexShrink: 0,
                            marginTop: "10px",
                        }}
                    >
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`component_text${index}`}
                            onChange={(value) => onChange(value, index)}
                            isFocused={
                                currentEditor === `component_text${index}`
                            }
                            setEditor={(id) => setCurrentEditor(id)}
                            value={text}
                            placeholder="Type here..."
                        />
                    </div>
                </div>
            </Badge.Ribbon>
        </List.Item>
    ),
);

const SortableList = SortableContainer(
    ({
        items,
        onDelete,
        onChange,
        currentEditor,
        disableWrite,
        hasMentions,
        mentionsList,
        onAdd,
        setCurrentEditor,
    }) => (
        <List
            size="large"
            bordered
            header={<h3>Conditions</h3>}
            dataSource={items}
            renderItem={({ text }, index) => (
                <SortableItem
                    text={text}
                    index={index}
                    key={index}
                    itemIndex={index}
                    onDelete={onDelete}
                    onChange={onChange}
                    currentEditor={currentEditor}
                    disableWrite={disableWrite}
                    hasMentions={hasMentions}
                    setCurrentEditor={setCurrentEditor}
                    mentionsList={mentionsList}
                />
            )}
            footer={
                <Button
                    onClick={() => onAdd()}
                    type="primary"
                    ghost
                    disabled={disableWrite}
                >
                    Add Condition
                </Button>
            }
        />
    ),
);

const ConditionComponentEditor = ({
    disableWrite,
    hasMentions,
    mentionsList,
    block,
    setBlock,
    currentEditor,
    setCurrentEditor,
}: any) => {
    const {
        data: { conditions = [], compute_functions = [], variables = [] },
    } = block;
    const blockVarsMap: any = useRef({});
    const [load, setLoad] = useState(false);
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");

    useEffect(() => {
        setLoad(true);
        variables?.forEach((element: { name: string | number }, i: any) => {
            blockVarsMap.current[element.name] = i;
        });
        let v = setTimeout(() => {
            setLoad(false);
        }, 50);
        return () => {
            clearTimeout(v);
        };
    }, []);

    const missingComponentFunctions = StickerFunctions?.Condition?.filter(
        (stickerFunction) =>
            !compute_functions?.some(
                (item: any) => item.name === stickerFunction.name,
            ),
    );

    if (missingComponentFunctions.length > 0) {
        const tmpBlock = _.cloneDeep(block);
        _.set(
            tmpBlock,
            ["data", "compute_functions"],
            [...compute_functions, ...missingComponentFunctions],
        );

        setBlock(tmpBlock);
    }
    console.log("missingComponentFunctions", missingComponentFunctions, block);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        const newOptions = arrayMoveImmutable(
            conditions,
            oldIndex,
            newIndex,
        ).filter((el) => !!el);
        let tmpBlock = cloneDeep(block);
        tmpBlock = _.set(tmpBlock, ["data", "conditions"], newOptions);
        setBlock(tmpBlock);
    };
    return load ? (
        <></>
    ) : (
        <div style={{ paddingBottom: "10px", display: "flex" }}>
            <Card
                style={{
                    minWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    maxHeight: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
                title={<h3>Configurations</h3>}
            >
                <Row>
                    <Col span={12}>
                        <ComponentDetails
                            block={block}
                            setBlock={setBlock}
                            disableWrite={disableWrite}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Update Logic">
                            <LogicModal
                                isAllGlobal={true}
                                disableWrite={disableWrite}
                                computeFUnctions={compute_functions}
                                onSave={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "compute_functions"],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        {blockVarsMap.current[KEYMAP.start_feedback] >= 0 && (
                            <Form.Item label="Feedback Pool">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setOpenFeedbackModal(
                                            KEYMAP.start_feedback,
                                        );
                                    }}
                                    size="small"
                                >
                                    Add
                                </Button>
                            </Form.Item>
                        )}
                    </Col>
                    <Col span={24}>
                        {!conditions?.length && (
                            <span
                                style={{
                                    color: "red",
                                    marginBottom: "20px",
                                    fontSize: "16px",
                                }}
                            >
                                No Conditions added!
                            </span>
                        )}
                        <SortableList
                            items={conditions}
                            onSortEnd={onSortEnd}
                            onDelete={(index) => {
                                let tmpBlock = cloneDeep(block);
                                let tmpChildren = tmpBlock?.data?.conditions;
                                pullAt(tmpChildren, index);
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "conditions"],
                                    tmpChildren,
                                );
                                setBlock(tmpBlock);
                            }}
                            onChange={(value: any, index) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "conditions", index, "text"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            onAdd={() => {
                                let tmpBlock = cloneDeep(block);
                                let tmpChildren =
                                    tmpBlock?.data?.conditions || [];
                                tmpBlock = set(
                                    tmpBlock,
                                    ["data", "conditions", tmpChildren?.length],
                                    {
                                        text: [],
                                    },
                                );
                                setBlock(tmpBlock);
                            }}
                            currentEditor={currentEditor}
                            disableWrite={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            setCurrentEditor={setCurrentEditor}
                            useDragHandle
                            lockAxis="y"
                        />
                    </Col>
                </Row>
            </Card>
            {openFeedbackModal && openFeedbackModal !== "" && (
                <FeedbackPool
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenFeedbackModal(
                            value === true ? openFeedbackModal : "",
                        );
                    }}
                    name={openFeedbackModal}
                    feedbackPool={
                        blockVarsMap.current[openFeedbackModal] >= 0 &&
                        variables[blockVarsMap.current[openFeedbackModal]].value
                    }
                    disableWrite={disableWrite}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            [
                                "data",
                                "variables",
                                blockVarsMap.current[openFeedbackModal],
                                "value",
                            ],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                />
            )}
        </div>
    );
};

export default ConditionComponentEditor;

import lodash from "lodash";
import deepdash from "deepdash-es";
import { v4 as uuid } from "uuid";
import { deepOmit } from "../../blocks/renderers/ProgressLevel/renderEditor/helper";

const _ = deepdash(lodash);
function updateKey(
    obj: any,
    keyToFind: string,
    newKeyValue: { is_enabled: boolean; value: string },
    updateGenricId: boolean,
) {
    const updatedObj: any = _.cloneDeep(obj);

    for (let key in obj) {
        if (
            updateGenricId &&
            ["genericId", "tagId", "itemId", "storiesId"].includes(key)
        )
            updatedObj[key] = uuid();
        else if (key === keyToFind) {
            updatedObj[key] = newKeyValue; // Update the key's value
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
            if (updateGenricId && ["dragDrop"].includes(key)) {
                if (obj[key]?.droppedSource) {
                    obj[key].droppedSource = null;
                }
            }
            updatedObj[key] = updateKey(
                obj[key],
                keyToFind,
                newKeyValue,
                updateGenricId,
            ); // Recursively update the key in nested objects

            if (["variants"].includes(key) && updatedObj[key]?.length > 0) {
                updatedObj.id = uuid();
                updatedObj.tmpId = uuid();
                updatedObj[key] = updatedObj[key].map((item: any) => ({
                    ...item,
                    id: uuid(),
                    tmpId: uuid(),
                }));
            }

            if (
                [
                    "components",
                    "paths",
                    "variant_chunks",
                    "stories",
                    "blocks",
                ].includes(key) &&
                updatedObj[key]?.length > 0
            ) {
                updatedObj[key] = updatedObj[key].map((item: any) => ({
                    ...item,
                    id: uuid(),
                    tmpId: uuid(),
                }));
            }

            if (key === "block" && updatedObj[key]) {
                updatedObj[key].tmpId = uuid();
                updatedObj[key].id = uuid();
            }
        }
        // else {
        //     updatedObj[key] = obj[key]; // Copy non-matching keys as is
        // }
    }

    return updatedObj;
}

export function updateKeysAndCopy(
    obj: any,
    ignoreId: boolean,
    updateGenricId = false,
) {
    let updatedObj: any = _.cloneDeep(obj);

    updatedObj = updateKey(
        updatedObj,
        "input_variable",
        {
            is_enabled: false,
            value: "",
        },
        updateGenricId,
    );

    updatedObj.tmpId = uuid();
    if (!ignoreId) updatedObj.id = uuid();

    return updatedObj;
}

export const getActionMenuItems = ({
    idx,
    type,
    blocks,
    disableDuplicateAction,
    addBlock,
    deleteBlock,

    addBlockCustom,
    block,

    // for progression stage
    updateId,
    ignoreId,
    updateGenricId,
}: any) => {
    let tmpBlock = deepOmit(block, [
        "is_modified",
        "changes_list",
        "master_block",
    ]);
    return (
        disableDuplicateAction
            ? []
            : [
                  {
                      key: 1,
                      label: <span>Duplicate</span>,
                      onClick: () => {
                          if (updateId) {
                              if (addBlockCustom) {
                                  addBlockCustom(
                                      type,
                                      idx + 1,
                                      updateKeysAndCopy(
                                          _.omitDeep(
                                              _.omit(tmpBlock, [
                                                  "id",
                                                  "worksheet_block_map_id",
                                                  "tmpId",
                                              ]),
                                              ["storiesId"],
                                          ),
                                          ignoreId,
                                          updateGenricId,
                                      ),
                                  );
                              } else {
                                  addBlock(
                                      type,
                                      idx + 1,
                                      updateKeysAndCopy(
                                          _.omitDeep(
                                              _.omit(
                                                  {
                                                      ...tmpBlock,
                                                      children:
                                                          tmpBlock.children?.map(
                                                              (child: any) =>
                                                                  _.omit(
                                                                      {
                                                                          ...child,
                                                                          tmpId: uuid(),
                                                                      },
                                                                      ["id"],
                                                                  ),
                                                          ),
                                                  },
                                                  [
                                                      "id",
                                                      "worksheet_block_map_id",
                                                      "tmpId",
                                                  ],
                                              ),
                                              ["storiesId"],
                                          ),
                                          true,
                                          updateGenricId,
                                      ),
                                  );
                              }
                          } else {
                              if (addBlockCustom)
                                  addBlockCustom(
                                      type,
                                      idx + 1,
                                      _.omitDeep(
                                          _.omit(tmpBlock, [
                                              "id",
                                              "worksheet_block_map_id",
                                              "tmpId",
                                          ]),
                                          ["storiesId"],
                                      ),
                                  );
                              else
                                  addBlock(
                                      type,
                                      idx + 1,
                                      _.omitDeep(
                                          _.omit(
                                              {
                                                  ...tmpBlock,
                                                  children:
                                                      tmpBlock.children?.map(
                                                          (child: any) =>
                                                              _.omit(
                                                                  {
                                                                      ...child,
                                                                      tmpId: uuid(),
                                                                      children:
                                                                          child?.children?.map(
                                                                              (
                                                                                  gChild: any,
                                                                              ) =>
                                                                                  _.omit(
                                                                                      {
                                                                                          ...gChild,
                                                                                          tmpId: uuid(),
                                                                                      },
                                                                                      [
                                                                                          "id",
                                                                                      ],
                                                                                  ),
                                                                          ),
                                                                  },
                                                                  ["id"],
                                                              ),
                                                      ),
                                              },
                                              [
                                                  "id",
                                                  "worksheet_block_map_id",
                                                  "tmpId",
                                              ],
                                          ),
                                          ["storiesId"],
                                      ),
                                  );
                          }
                      },
                  },
              ]
    ).concat([
        {
            key: 2,
            danger: true,
            label: <span>Delete</span>,
            onClick: () => {
                deleteBlock(idx);
            },
        },
    ]);
};

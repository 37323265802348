import React, { useState } from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    Form,
    Input,
    Row,
    Select,
    Switch,
    message,
} from "antd";
import ComponentDetails from "../common/ComponentDetails";
import { LogicModal } from "../common/LogicModal";
import { cloneDeep, get, pullAt, set } from "lodash";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import { FeedbackPool } from "../common/FeedbackPool";
import { CopyIcon, RenderUpload } from "src/components";
import { replaceCDNUrl } from "src/modules/worksheet/components/blocks/helpers";
import { StickerFunctions } from "../../../defaultValue";

const { Panel } = Collapse;
const interactionKeys = [
    { key: "DRAG_DROP", label: "DRAG_DROP" },
    // { key: "CLICK", label: "CLICK" },
    // Add more interaction keys here as needed
];

const Settings = (props) => {
    const {
        block,
        setBlock,
        disableWrite,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
        renderLinkSelect,
    } = props;
    const {
        data: {
            component_functions,
            component_settings,
            scale_balance_settings,
        },
    } = block;
    const [openFeedbackModal, setOpenFeedbackModal] = useState("");

    const missingComponentFunctions = StickerFunctions?.BalanceGame?.filter(
        (stickerFunction) =>
            !component_functions?.some(
                (item: any) => item.name === stickerFunction.name,
            ),
    );
    if (missingComponentFunctions.length > 0) {
        const tmpBlock = _.cloneDeep(block);
        _.set(
            tmpBlock,
            ["data", "component_functions"],
            [...component_functions, ...missingComponentFunctions],
        );

        setBlock(tmpBlock);
    }

    const handleEventChange = (interactionKey: string, value: string[]) => {
        let tmpCell = cloneDeep(block);
        const data =
            scale_balance_settings?.interactionEvents?.interactions ?? {};
        data[interactionKey] = value.map((event) => ({ event }));
        tmpCell = set(
            tmpCell,
            [
                "data",
                "scale_balance_settings",
                "interactionEvents",
                "interactions",
            ],
            data,
        );
        setBlock(tmpCell);
    };

    return (
        <>
            {openFeedbackModal && openFeedbackModal !== "" && (
                <FeedbackPool
                    isModalOpen={true}
                    setIsModalOpen={(value: boolean) => {
                        setOpenFeedbackModal(
                            value === true ? openFeedbackModal : "",
                        );
                    }}
                    name={openFeedbackModal}
                    feedbackPool={scale_balance_settings.feedback_pool}
                    disableWrite={disableWrite}
                    onSave={(value: any) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "scale_balance_settings", "feedback_pool"],
                            value,
                        );
                        setBlock(tmpBlock);
                    }}
                    hasMentions={hasMentions}
                    mentionsList={mentionsList}
                    currentEditor={currentEditor}
                    setCurrentEditor={setCurrentEditor}
                />
            )}
            <Col span={12}>
                <ComponentDetails
                    block={block}
                    setBlock={setBlock}
                    disableWrite={disableWrite}
                />
                <Row>
                    <Col span={12}>
                        <Form.Item label="Lever Image">
                            <RenderUpload
                                singleUpload={true}
                                disabled={disableWrite}
                                addExtension={true}
                                path="home-explore/document/"
                                onChangeCustom={(e) => {
                                    const value = replaceCDNUrl(
                                        e.target.value,
                                        e.target.bucket,
                                    );
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "leverImage",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                                label="upload the file"
                                value={scale_balance_settings.leverImage}
                            />
                            <Input
                                placeholder="Add value here"
                                value={scale_balance_settings.leverImage}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "leverImage",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="base Image">
                            <RenderUpload
                                singleUpload={true}
                                disabled={disableWrite}
                                addExtension={true}
                                path="home-explore/document/"
                                onChangeCustom={(e) => {
                                    const value = replaceCDNUrl(
                                        e.target.value,
                                        e.target.bucket,
                                    );
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "baseImage",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                                label="upload the file"
                                value={scale_balance_settings.baseImage}
                            />
                            <Input
                                placeholder="Add value here"
                                value={scale_balance_settings.baseImage}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "baseImage",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {renderLinkSelect &&
                            renderLinkSelect({
                                value: block.data
                                    ?.linked_global_context_variable?.name,
                                onChange: (value) => {
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "linked_global_context_variable",
                                            "name",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                },
                            })}
                    </Col>
                    <Col>
                        <Form.Item label="Value from variable">
                            <Select
                                value={block.data?.value}
                                placeholder="Select type"
                                options={[
                                    {
                                        label: "none",
                                        value: null,
                                    },
                                    ...mentionsList?.map((v) => ({
                                        label: v.text,
                                        value: `@@${v.text}@@`,
                                    })),
                                ]}
                                style={{
                                    width: "150px",
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["data", "value"],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Tag cluster background transparent">
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={
                                    scale_balance_settings?.tagClusterSettings
                                        ?.isBackgroundTransparent
                                }
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "tagClusterSettings",
                                            "isBackgroundTransparent",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Enable Interaction Events">
                            <Switch
                                disabled={disableWrite}
                                checked={
                                    scale_balance_settings?.interactionEvents
                                        ?.enabled
                                }
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "interactionEvents",
                                            "enabled",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                            />
                        </Form.Item>
                        {scale_balance_settings?.interactionEvents?.enabled && (
                            <>
                                <Form.Item label="Add New Interaction Key">
                                    {interactionKeys.map(({ key, label }) => (
                                        <Button
                                            key={key}
                                            onClick={() => {
                                                if (
                                                    !scale_balance_settings
                                                        ?.interactionEvents
                                                        ?.interactions
                                                ) {
                                                    scale_balance_settings[
                                                        "interactionEvents"
                                                    ]["interactions"] = {};
                                                }
                                                if (
                                                    key &&
                                                    !scale_balance_settings
                                                        ?.interactionEvents
                                                        ?.interactions[key]
                                                ) {
                                                    let tmpCell =
                                                        cloneDeep(block);
                                                    const value =
                                                        scale_balance_settings
                                                            ?.interactionEvents
                                                            ?.interactions ??
                                                        {};
                                                    value[`${key}`] = [];
                                                    tmpCell = set(
                                                        tmpCell,
                                                        [
                                                            "data",
                                                            "scale_balance_settings",
                                                            "interactionEvents",
                                                            "interactions",
                                                        ],
                                                        value,
                                                    );
                                                    setBlock(tmpCell);
                                                }
                                            }}
                                            disabled={disableWrite}
                                            style={{ marginRight: "8px" }}
                                        >
                                            {label}
                                        </Button>
                                    ))}
                                </Form.Item>
                                <Collapse>
                                    {Object.keys(
                                        scale_balance_settings
                                            ?.interactionEvents.interactions ||
                                            {},
                                    ).map((interactionKey) => {
                                        return (
                                            <Panel
                                                header={interactionKey}
                                                key={interactionKey}
                                            >
                                                <Form.Item
                                                    label={`${interactionKey} Events`}
                                                >
                                                    {scale_balance_settings?.interactionEvents.interactions[
                                                        interactionKey
                                                    ]?.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                marginBottom:
                                                                    "8px",
                                                            }}
                                                        >
                                                            <Input
                                                                value={
                                                                    item.event
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    const newEvents =
                                                                        [
                                                                            ...scale_balance_settings
                                                                                ?.interactionEvents
                                                                                .interactions[
                                                                                interactionKey
                                                                            ],
                                                                        ];
                                                                    newEvents[
                                                                        index
                                                                    ].event =
                                                                        e.target.value;
                                                                    handleEventChange(
                                                                        interactionKey,
                                                                        newEvents.map(
                                                                            (
                                                                                event,
                                                                            ) =>
                                                                                event.event,
                                                                        ),
                                                                    );
                                                                }}
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        "8px",
                                                                    flex: 1,
                                                                }}
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    const newEvents =
                                                                        scale_balance_settings?.interactionEvents?.interactions[
                                                                            interactionKey
                                                                        ].filter(
                                                                            (
                                                                                _,
                                                                                i,
                                                                            ) =>
                                                                                i !==
                                                                                index,
                                                                        );
                                                                    handleEventChange(
                                                                        interactionKey,
                                                                        newEvents.map(
                                                                            (
                                                                                event,
                                                                            ) =>
                                                                                event.event,
                                                                        ),
                                                                    );
                                                                }}
                                                                disabled={
                                                                    disableWrite
                                                                }
                                                            >
                                                                -
                                                            </Button>
                                                        </div>
                                                    ))}
                                                    <Button
                                                        onClick={() => {
                                                            const newEvents = [
                                                                ...scale_balance_settings
                                                                    ?.interactionEvents
                                                                    .interactions[
                                                                    interactionKey
                                                                ],
                                                                { event: "" },
                                                            ];
                                                            handleEventChange(
                                                                interactionKey,
                                                                newEvents.map(
                                                                    (event) =>
                                                                        event.event,
                                                                ),
                                                            );
                                                        }}
                                                        disabled={disableWrite}
                                                    >
                                                        +
                                                    </Button>
                                                </Form.Item>
                                            </Panel>
                                        );
                                    })}
                                </Collapse>
                            </>
                        )}
                    </Col>
                </Row>
            </Col>
            <Col span={12}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: "4px",
                    }}
                >
                    <LogicModal
                        disableWrite={disableWrite}
                        computeFUnctions={component_functions}
                        onSave={(val) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", "component_functions"],
                                val,
                            );
                            setBlock(tmpBlock);
                        }}
                        isAllGlobal={true}
                    />
                    <h4>
                        Scale Settings :
                        <CopyIcon
                            disabled={disableWrite}
                            isButton={true}
                            onClick={() => {
                                message.info(`Copied Scale data to clipboard!`);
                                navigator.clipboard.writeText(
                                    JSON.stringify(scale_balance_settings),
                                );
                            }}
                        />
                    </h4>
                    <Select
                        value={scale_balance_settings.evaluation}
                        placeholder="Select type"
                        options={[
                            {
                                label: "One by one",
                                value: "ONE_BY_ONE",
                            },
                            // {
                            //     label: "One by one till first incorrect",
                            //     value: "ONE_BY_ONE_TILL_FIRST_INCORRECT",
                            // },
                            {
                                label: "All in one go",
                                value: "ALL_IN_ONE_GO",
                            },
                        ]}
                        style={{
                            width: "150px",
                            flexGrow: 0,
                        }}
                        onChange={(val) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                [
                                    "data",
                                    "scale_balance_settings",
                                    "evaluation",
                                ],
                                val,
                            );
                            setBlock(tmpBlock);
                        }}
                        disabled={disableWrite}
                    />
                </div>
                <Form.Item
                    label="Feedback Type"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    <Select
                        value={scale_balance_settings.feedbackType}
                        placeholder="Select type"
                        options={[
                            {
                                label: "No Feedback",
                                value: "NO_FEEDBACK",
                            },
                            {
                                label: "Manual Audio",
                                value: "MANUAL_AUDIO",
                            },
                            {
                                label: "Manual Story",
                                value: "MANUAL_STORY",
                            },
                            // {
                            //     label: "Generated Audio",
                            //     value: "GENERATED_AUDIO",
                            // },
                            // {
                            //     label: "Generated Story",
                            //     value: "GENERATED_STORY",
                            // },
                        ]}
                        style={{
                            flexGrow: 0,
                        }}
                        onChange={(val) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                [
                                    "data",
                                    "scale_balance_settings",
                                    "feedbackType",
                                ],
                                val,
                            );
                            setBlock(tmpBlock);
                        }}
                        disabled={disableWrite}
                    />
                </Form.Item>
                <Form.Item
                    label="Animation type"
                    style={{
                        marginTop: "20px",
                    }}
                >
                    <Select
                        value={scale_balance_settings.animation}
                        placeholder="Select animation"
                        options={[
                            {
                                label: "on Submit",
                                value: "submit",
                            },
                            {
                                label: "Auto",
                                value: "auto",
                            },
                        ]}
                        style={{
                            flexGrow: 0,
                        }}
                        onChange={(val) => {
                            let tmpBlock = cloneDeep(block);
                            tmpBlock = set(
                                tmpBlock,
                                ["data", "scale_balance_settings", "animation"],
                                val,
                            );
                            setBlock(tmpBlock);
                        }}
                        disabled={disableWrite}
                    />
                </Form.Item>
                <div
                    style={{
                        display: "flex",
                        gap: "4px",
                        flexWrap: "wrap",
                    }}
                >
                    <Form.Item label="Has Highlight?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={scale_balance_settings.hasHighlight}
                            onChange={(val) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    [
                                        "data",
                                        "scale_balance_settings",
                                        "hasHighlight",
                                    ],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Has Feedback Stroke?">
                        <Switch
                            disabled={disableWrite}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={scale_balance_settings.hasFeedbackStroke}
                            onChange={(val) => {
                                let tmpBlock = cloneDeep(block);
                                tmpBlock = set(
                                    tmpBlock,
                                    [
                                        "data",
                                        "scale_balance_settings",
                                        "hasFeedbackStroke",
                                    ],
                                    val,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                </div>
                <Row>
                    <Col>
                        <Form.Item label="Feedback Pool">
                            <Button
                                type="primary"
                                onClick={() => {
                                    setOpenFeedbackModal("feedback_pool");
                                }}
                                size="small"
                            >
                                Add
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Max rotation">
                            <Input
                                type="number"
                                style={{
                                    minWidth: "100px",
                                }}
                                disabled={disableWrite}
                                value={scale_balance_settings.maxRotation}
                                onChange={(e) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "maxRotation",
                                        ],
                                        e.target.value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Scale background Enabled?">
                            <Switch
                                disabled={disableWrite}
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={
                                    scale_balance_settings?.backgroundSettings
                                        ?.enabled
                                }
                                onChange={(value) => {
                                    let tmpCell = cloneDeep(block);
                                    tmpCell = set(
                                        tmpCell,
                                        [
                                            "data",
                                            "scale_balance_settings",
                                            "backgroundSettings",
                                            "enabled",
                                        ],
                                        value,
                                    );
                                    setBlock(tmpCell);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    {scale_balance_settings?.backgroundSettings?.enabled && (
                        <>
                            <Col>
                                <Form.Item label="Background Image">
                                    <RenderUpload
                                        singleUpload={true}
                                        disabled={disableWrite}
                                        addExtension={true}
                                        path="home-explore/document/"
                                        onChangeCustom={(e) => {
                                            const value = replaceCDNUrl(
                                                e.target.value,
                                                e.target.bucket,
                                            );
                                            let tmpCell = cloneDeep(block);
                                            tmpCell = set(
                                                tmpCell,
                                                [
                                                    "data",
                                                    "scale_balance_settings",
                                                    "backgroundSettings",
                                                    "backgroundImage",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpCell);
                                        }}
                                        label="upload the file"
                                        value={
                                            scale_balance_settings
                                                ?.backgroundSettings
                                                ?.backgroundImage
                                        }
                                    />
                                    <Input
                                        placeholder="Add value here"
                                        value={
                                            scale_balance_settings
                                                ?.backgroundSettings
                                                ?.backgroundImage
                                        }
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            let tmpCell = cloneDeep(block);
                                            tmpCell = set(
                                                tmpCell,
                                                [
                                                    "data",
                                                    "scale_balance_settings",
                                                    "backgroundSettings",
                                                    "backgroundImage",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpCell);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Form.Item label="Size">
                                <Select
                                    disabled={disableWrite}
                                    value={
                                        scale_balance_settings
                                            ?.backgroundSettings?.size
                                    }
                                    onChange={(value) => {
                                        let tmpCell = cloneDeep(block);
                                        tmpCell = set(
                                            tmpCell,
                                            [
                                                "data",
                                                "scale_balance_settings",
                                                "backgroundSettings",
                                                "size",
                                            ],
                                            value,
                                        );
                                        setBlock(tmpCell);
                                    }}
                                >
                                    <Select.Option value="contain">
                                        Contain
                                    </Select.Option>
                                    <Select.Option value="cover">
                                        Cover
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                            <Col>
                                <Form.Item label="Image min height">
                                    <Input
                                        type={"number"}
                                        disabled={disableWrite}
                                        value={
                                            scale_balance_settings
                                                ?.backgroundSettings?.minHeight
                                        }
                                        onChange={(value) => {
                                            let tmpCell = cloneDeep(block);
                                            tmpCell = set(
                                                tmpCell,
                                                [
                                                    "data",
                                                    "scale_balance_settings",
                                                    "backgroundSettings",
                                                    "minHeight",
                                                ],
                                                value.target.value,
                                            );
                                            setBlock(tmpCell);
                                        }}
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item label="Position from top(px)">
                                    <Input
                                        type={"number"}
                                        disabled={disableWrite}
                                        value={
                                            scale_balance_settings
                                                ?.backgroundSettings?.position
                                        }
                                        onChange={(value) => {
                                            let tmpCell = cloneDeep(block);
                                            tmpCell = set(
                                                tmpCell,
                                                [
                                                    "data",
                                                    "scale_balance_settings",
                                                    "backgroundSettings",
                                                    "position",
                                                ],
                                                value.target.value,
                                            );
                                            setBlock(tmpCell);
                                        }}
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item label="Include tag cluster">
                                    <Switch
                                        disabled={disableWrite}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        checked={
                                            scale_balance_settings
                                                ?.backgroundSettings
                                                ?.includeTagCluster
                                        }
                                        onChange={(value) => {
                                            let tmpCell = cloneDeep(block);
                                            tmpCell = set(
                                                tmpCell,
                                                [
                                                    "data",
                                                    "scale_balance_settings",
                                                    "backgroundSettings",
                                                    "includeTagCluster",
                                                ],
                                                value,
                                            );
                                            setBlock(tmpCell);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </>
                    )}
                </Row>
            </Col>
        </>
    );
};

export default Settings;

import { gql } from "@apollo/client";
export default gql`
    query GetBlockData($block_id: Int!) {
        worksheet_block(where: { id: { _eq: $block_id } }) {
            id
            data
            backend
            parent_id
            type
        }
    }
`;

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Divider, Form, Input, Select, Switch, Typography } from "antd";
import { cloneDeep, set } from "lodash";
import { COMPONENT_TYPES } from "../../../defaultValue";
import TopicEventsModal from "./TopicEventsModal";

const {
    // Paragraph,
    Title,
} = Typography;

const ComponentDetails = (props: any) => {
    const { block, setBlock, disableWrite, showTopicEvents = true } = props;
    return (
        <>
            <Title
                level={5}
                editable={
                    disableWrite
                        ? false
                        : {
                              text: block.name,
                              onChange: (val) => {
                                  let tmpBlock = cloneDeep(block);
                                  tmpBlock = set(tmpBlock, ["name"], val);
                                  setBlock(tmpBlock);
                              },
                              triggerType: ["icon", "text"],
                              enterIcon: null,
                          }
                }
            >
                {block.name}
            </Title>
            {/* <Paragraph
                editable={
                    disableWrite
                        ? false
                        : {
                              text: block.description,
                              onChange: (val) => {
                                  let tmpBlock = cloneDeep(block);
                                  tmpBlock = set(
                                      tmpBlock,
                                      ["description"],
                                      val,
                                  );
                                  setBlock(tmpBlock);
                              },
                              triggerType: ["icon", "text"],
                              enterIcon: null,
                          }
                }
            >
                {block.description}
            </Paragraph> */}
            <div
                style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}
            >
                {![COMPONENT_TYPES.RICH_TEXT, COMPONENT_TYPES.TIMER].includes(
                    block?.type,
                ) && (
                    <>
                        <Form.Item label={"Disable Interaction?"}>
                            <Switch
                                disabled={disableWrite}
                                size="small"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={block.disable_interaction}
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["disable_interaction"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                        <Form.Item label={"Skip Evaluation?"}>
                            <Switch
                                disabled={disableWrite}
                                size="small"
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                checked={block.skip_evaluation}
                                onChange={(value) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock = _.set(
                                        tmpBlock,
                                        ["skip_evaluation"],
                                        value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item label={"Show as answer? ( Auto )"}>
                    <Switch
                        disabled={disableWrite}
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={block.auto_show_as_answer}
                        onChange={(value) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["auto_show_as_answer"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
                {block?.auto_show_as_answer && (
                    <>
                        <Form.Item label={"Auto Answer Format"}>
                            <Select
                                value={block.auto_answer_format}
                                placeholder="Select type"
                                options={[
                                    {
                                        label: "sequential",
                                        value: "SEQUENTIAL",
                                    },
                                    {
                                        label: "all combinations",
                                        value: "ALL_COMBINATIONS",
                                    },
                                    {
                                        label: "all combinations for dnd",
                                        value: "ALL_COMBINATIONS_FOR_DND",
                                    },
                                    {
                                        label: "tap ans select",
                                        value: "TAP_AND_SELECT",
                                    },
                                ]}
                                style={{
                                    width: "150px",
                                    flexGrow: 0,
                                }}
                                onChange={(val) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["auto_answer_format"],
                                        val,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                        {block.auto_answer_format === "TAP_AND_SELECT" && (
                            <Form.Item label={"highlight function"}>
                                <Input.TextArea
                                    value={block.highlight_function}
                                    placeholder="highlight function"
                                    style={{
                                        width: "150px",
                                        flexGrow: 0,
                                    }}
                                    onChange={(e) => {
                                        let tmpBlock = cloneDeep(block);
                                        tmpBlock = set(
                                            tmpBlock,
                                            ["highlight_function"],
                                            e.target.value,
                                        );
                                        setBlock(tmpBlock);
                                    }}
                                    disabled={disableWrite}
                                />
                            </Form.Item>
                        )}
                        <Form.Item label={"Max limit for auto answer"}>
                            <Input
                                value={block.max_answer_limit ?? 5}
                                placeholder="Max limit"
                                style={{
                                    width: "150px",
                                    flexGrow: 0,
                                }}
                                onChange={(e) => {
                                    let tmpBlock = cloneDeep(block);
                                    tmpBlock = set(
                                        tmpBlock,
                                        ["max_answer_limit"],
                                        e.target.value,
                                    );
                                    setBlock(tmpBlock);
                                }}
                                disabled={disableWrite}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item label={"Show as answer? ( Manual )"}>
                    <Switch
                        disabled={disableWrite}
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={block.show_as_answer}
                        onChange={(value) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["show_as_answer"],
                                value,
                            );
                            tmpBlock = _.set(
                                tmpBlock,
                                ["disable_interaction"],
                                value,
                            );
                            tmpBlock = _.set(
                                tmpBlock,
                                ["skip_evaluation"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
                {(block?.show_as_answer || block?.auto_show_as_answer) && (
                    <Form.Item label={"Show on all slides"}>
                        <Switch
                            disabled={disableWrite}
                            size="small"
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={block.show_on_all_slides}
                            onChange={(value) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["show_on_all_slides"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                )}
                {block?.show_as_answer && (
                    <Form.Item label={"Answer Slide Number"}>
                        <Input
                            disabled={disableWrite}
                            type="number"
                            style={{ width: 78 }}
                            value={block.answer_slide_number}
                            onChange={(e) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["answer_slide_number"],
                                    e?.target?.value,
                                );
                                setBlock(tmpBlock);
                            }}
                        />
                    </Form.Item>
                )}
                <Form.Item label={"Show in preview?"}>
                    <Switch
                        disabled={disableWrite}
                        size="small"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={block.show_in_preview}
                        onChange={(value) => {
                            let tmpBlock = _.cloneDeep(block);
                            tmpBlock = _.set(
                                tmpBlock,
                                ["show_in_preview"],
                                value,
                            );
                            setBlock(tmpBlock);
                        }}
                    />
                </Form.Item>
            </div>
            {showTopicEvents && (
                <TopicEventsModal
                    disableWrite={disableWrite}
                    topicEventEffectsMap={block?.data?.topicEventEffectsMap}
                    setBlock={(v) => {
                        let tmpBlock = cloneDeep(block);
                        tmpBlock = set(
                            tmpBlock,
                            ["data", "topicEventEffectsMap"],
                            v,
                        );
                        setBlock(tmpBlock);
                    }}
                />
            )}
            <Divider />
        </>
    );
};

export default ComponentDetails;

import React, { useEffect, useState } from "react";
import {
    Modal,
    Button,
    Switch,
    Form,
    Card,
    Popover,
    Row,
    Col,
    InputNumber,
    Select,
    Input,
    message,
    Popconfirm,
} from "antd";
import { v4 as uuid } from "uuid";
import { SlateEditor } from "src/components";

import _ from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import * as yup from "yup";

const explainAndTellYupValidationSchema = yup.object().shape({
    star_cost: yup.number().required(),
    max_usage: yup.number().required(),
    content_type: yup.string().required(),
    content: yup
        .mixed()
        .required()
        .test(
            "content-validation",
            "Content is required and must be valid",
            function (value) {
                const contentType = this.parent.content_type;
                if (contentType === "rich_text") {
                    return Array.isArray(value) && value.length > 0;
                }
                return typeof value === "string" && value.trim().length > 0;
            },
        ),
});

const checkNowYupValidationSchema = yup.object().shape({
    star_cost: yup.number().required(),
    max_usage: yup.number().required(),
});

const helpsList = [
    {
        type: "EXPLAIN",
        name: "Explain Question",
        image: "https://cdn.homeworkapp.ai/assets/test/explain.svg",
        yupValidationSchema: explainAndTellYupValidationSchema,
    },
    {
        type: "TELL",
        name: "Tell Strategy",
        image: "https://cdn.homeworkapp.ai/assets/test/tell-strategy.svg",
        yupValidationSchema: explainAndTellYupValidationSchema,
    },
    {
        type: "CHECK_NOW",
        name: "Check Now",
        image: "https://cdn.homeworkapp.ai/assets/test/check-now.svg",
        yupValidationSchema: checkNowYupValidationSchema,
    },
];

// antd icon with custom svg
const HelpIcon = () => {
    return (
        <img
            src="https://cdn.homeworkapp.ai/assets/test/avatar-overlay.svg"
            style={{ width: "40px", height: "40px" }}
        />
    );
};

const HelpForm = ({ help, setHelp, currentEditor, setCurrentEditor }: any) => {
    return (
        <Row>
            <Col span={12}>
                <Form.Item label="Star Cost">
                    <InputNumber
                        value={help?.star_cost}
                        onChange={(value: any) => {
                            setHelp({
                                ...help,
                                star_cost: value,
                            });
                        }}
                        min={1}
                        max={10}
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Max Usage">
                    <InputNumber
                        value={help?.max_usage}
                        onChange={(value: any) => {
                            setHelp({
                                ...help,
                                max_usage: value,
                            });
                        }}
                        min={1}
                        max={10}
                    />
                </Form.Item>
            </Col>
            {(help?.type === "EXPLAIN" || help?.type === "TELL") && (
                <>
                    <Col span={24}>
                        <Form.Item label="Content Type">
                            <Select
                                value={help?.content_type}
                                onChange={(value: any) => {
                                    setHelp({
                                        ...help,
                                        content_type: value,
                                    });
                                }}
                                options={[
                                    {
                                        label: "Story",
                                        value: "story",
                                    },
                                    {
                                        label: "Rich Text",
                                        value: "rich_text",
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    {help?.content_type === "rich_text" && (
                        <Col span={24}>
                            <SlateEditor
                                id={`${help?.id}-help-content-rich-text`}
                                value={
                                    typeof help?.content === "string"
                                        ? []
                                        : help?.content || []
                                }
                                onChange={(value: any) => {
                                    setHelp({
                                        ...help,
                                        content: value,
                                    });
                                }}
                                isFocused={
                                    currentEditor ===
                                    `${help?.id}-help-content-rich-text`
                                }
                                setEditor={(id: string) => setCurrentEditor(id)}
                                placeholder="Type here..."
                            />
                        </Col>
                    )}
                    {help?.content_type === "story" && (
                        <Col span={24}>
                            <Form.Item label="Story Id">
                                <Input
                                    value={
                                        typeof help?.content === "string"
                                            ? help?.content
                                            : ""
                                    }
                                    onChange={(e: any) => {
                                        setHelp({
                                            ...help,
                                            content: e.target.value,
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={24}>
                        <Form.Item label="Removal Compute Function">
                            <Input.TextArea
                                value={help?.computeFunction}
                                onChange={(e) => {
                                    setHelp({
                                        ...help,
                                        computeFunction: e.target.value,
                                    });
                                }}
                                placeholder="Enter compute function..."
                                rows={4}
                            />
                        </Form.Item>
                    </Col>
                </>
            )}
        </Row>
    );
};

const HelpQuestionFormModal = ({
    helpProvider,
    setHelpProvider,
    currentEditor,
    setCurrentEditor,
}: any) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tempBlock, setTempBlock] = useState<any>(helpProvider || {});
    const [helpListOverlayOpen, setHelpListOverlayOpen] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        if (isModalVisible) {
            setTempBlock(helpProvider || {});
        } else {
            setTempBlock(null);
        }
    }, [isModalVisible]);

    const handleOk = () => {
        // console log the error from shcmea checking below

        const validationStatus = tempBlock?.helps?.every((help: any) =>
            helpsList
                ?.find((helpItem: any) => helpItem.type === help.type)
                ?.yupValidationSchema?.isValidSync(help),
        );
        if (validationStatus) {
            // sort helps in the order
            tempBlock.helps = tempBlock?.helps?.sort(
                (a: any, b: any) =>
                    helpsList?.findIndex((help) => help.type === a.type) -
                    helpsList?.findIndex((help) => help.type === b.type),
            );
            setHelpProvider(tempBlock);
            setIsModalVisible(false);
        } else {
            console.log("Validation errors:", validationStatus);
            message.error("Please fill all the fields");
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleAddHelp = (helpType: any) => {
        let tmpHelpProvider = _.cloneDeep(tempBlock);
        const newHelp = {
            type: helpType,
            // TODO: add id uuid
            id: uuid(),
        };
        tmpHelpProvider = _.set(
            tmpHelpProvider,
            ["helps"],
            [...(tmpHelpProvider?.helps || []), newHelp],
        );
        setTempBlock(tmpHelpProvider);
        setHelpListOverlayOpen(false);
    };

    const modifiedHelpsList = helpsList?.filter(
        (help) =>
            !tempBlock?.helps?.find(
                (helpItem: any) => helpItem.type === help.type,
            ),
    );

    const handleRemoveHelp = (indexToRemove: number) => {
        let tmpHelpProvider = _.cloneDeep(tempBlock);
        // remove help from helps
        tmpHelpProvider = _.set(
            tmpHelpProvider,
            ["helps"],
            tmpHelpProvider?.helps?.filter(
                (help: any, index: number) => index !== indexToRemove,
            ),
        );
        setTempBlock(tmpHelpProvider);
    };

    return (
        <>
            <Button
                style={{
                    height: "50px",
                }}
                type={helpProvider?.enabled ? "primary" : "default"}
                onClick={showModal}
            >
                <HelpIcon /> Help System
            </Button>
            <Modal
                title="Help System"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form.Item label="Block type">
                    <Switch
                        checked={tempBlock?.enabled}
                        onChange={(value: any) => {
                            let tmpHelpProvider = _.cloneDeep(tempBlock);
                            tmpHelpProvider = _.set(
                                tmpHelpProvider,
                                ["enabled"],
                                value,
                            );
                            setTempBlock(tmpHelpProvider);
                        }}
                    />
                </Form.Item>
                {tempBlock?.enabled && (
                    <div
                        style={{
                            border: "1px solid #333",
                        }}
                    >
                        {tempBlock?.helps?.map((help: any, index: number) => {
                            const helpItem = helpsList?.find(
                                (helpItem: any) => helpItem.type === help.type,
                            );
                            return (
                                <Card
                                    title={helpItem?.name}
                                    extra={
                                        <Popconfirm
                                            title="Are you sure to delete this help?"
                                            onConfirm={() =>
                                                handleRemoveHelp(index)
                                            }
                                            onCancel={() => {}}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <CloseOutlined />
                                        </Popconfirm>
                                    }
                                    bodyStyle={{
                                        padding: "5px",
                                        borderBottom: "1px solid #ccc",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <HelpForm
                                        help={help}
                                        setHelp={(value: any) => {
                                            let tmpHelpProvider =
                                                _.cloneDeep(tempBlock);
                                            tmpHelpProvider = _.set(
                                                tmpHelpProvider,
                                                ["helps", index],
                                                value,
                                            );
                                            setTempBlock(tmpHelpProvider);
                                        }}
                                        currentEditor={currentEditor}
                                        setCurrentEditor={setCurrentEditor}
                                    />
                                </Card>
                            );
                        })}
                        {modifiedHelpsList?.length > 0 && (
                            <Popover
                                content={
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns:
                                                "repeat(2, 1fr)",
                                            gap: "10px",
                                        }}
                                    >
                                        {modifiedHelpsList?.map((help) => (
                                            <Col span={12}>
                                                <Card
                                                    hoverable
                                                    style={{
                                                        width: "100px",
                                                    }}
                                                    onClick={() =>
                                                        handleAddHelp(help.type)
                                                    }
                                                    key={help.type}
                                                    bodyStyle={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                        padding: "5px",
                                                        width: "100px",
                                                    }}
                                                >
                                                    <img src={help.image} />
                                                    <div>{help.name}</div>
                                                </Card>
                                            </Col>
                                        ))}
                                    </div>
                                }
                                title="Title"
                                trigger="click"
                                open={helpListOverlayOpen}
                                onOpenChange={(value: any) => {
                                    setHelpListOverlayOpen(value);
                                }}
                            >
                                <Button type="primary">Add Help</Button>
                            </Popover>
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default HelpQuestionFormModal;

import React, { useEffect, useState } from "react";
import Settings from "./Settings";
import {
    Button,
    Card,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    Row,
    message,
} from "antd";
import _, { cloneDeep, set } from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import { SlateEditor } from "src/components";

const ButtonEditor = (props) => {
    const {
        block,
        disableWrite,
        setBlock,
        hasMentions,
        mentionsList = [],
        currentEditor,
        setCurrentEditor,
    } = props;
    console.log(block);
    const {
        id,
        tmpId,
        data: { visible, is_absolute, button_text, full_width },
    } = block;
    const [tempConfig, setTempConfig] = useState("");

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
            }}
        >
            <Card
                style={{
                    minWidth: "600px",
                    // maxWidth: "600px",
                    width: "100%",
                    marginLeft: "8px",
                    flexShrink: 0,
                    height: "85vh",
                    overflow: "auto",
                    scrollbarWidth: "none",
                }}
            >
                <Row>
                    <Settings {...props} />
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label={"Effects"}>
                            <Button
                                ghost
                                type="primary"
                                size="small"
                                style={{
                                    marginBottom: "10px",
                                }}
                                disabled={disableWrite}
                                onClick={() => {
                                    let tmpBlock = _.cloneDeep(block);
                                    if (tmpBlock.data.effects === undefined) {
                                        tmpBlock.data.effects = [
                                            {
                                                event: "",
                                                computeFunction: { output: "" },
                                            },
                                        ];
                                    } else {
                                        tmpBlock.data.effects.push({
                                            event: "",
                                            computeFunction: { output: "" },
                                        });
                                    }
                                    setBlock(tmpBlock);
                                }}
                            >
                                Add New Effects
                            </Button>
                            {block.data?.effects?.map((v: any, key: number) => (
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "1rem",
                                    }}
                                >
                                    <Input
                                        key={key}
                                        disabled={disableWrite}
                                        value={v.event}
                                        placeholder="event"
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock.data.effects[key].event =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                    <Input.TextArea
                                        key={key}
                                        disabled={disableWrite}
                                        value={v.computeFunction.output}
                                        placeholder="computeFunction"
                                        onChange={(e) => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock.data.effects[
                                                key
                                            ].computeFunction.output =
                                                e.target.value;
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                    <Button
                                        disabled={disableWrite}
                                        icon={<DeleteOutlined />}
                                        type="primary"
                                        shape="circle"
                                        danger
                                        size="small"
                                        onClick={() => {
                                            let tmpBlock = _.cloneDeep(block);
                                            tmpBlock.data.effects =
                                                tmpBlock.data.effects.filter(
                                                    (v: any, index: number) =>
                                                        index !== key,
                                                );
                                            setBlock(tmpBlock);
                                        }}
                                    />
                                </div>
                            ))}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="On Button Click Function :"
                            labelCol={{ span: 24 }}
                        >
                            <Input.TextArea
                                rows={6}
                                disabled={disableWrite}
                                value={block.data.on_click_effects}
                                onChange={(e) => {
                                    let tmpBlock = _.cloneDeep(block);
                                    tmpBlock.data.on_click_effects =
                                        e.target.value;
                                    setBlock(tmpBlock);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <h3
                            style={{
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >
                            Bottom sheet Default text
                        </h3>
                        <SlateEditor
                            disabled={disableWrite}
                            hasMentions={hasMentions}
                            mentionsList={mentionsList}
                            id={`${id || tmpId}_text`}
                            onChange={(value: any) => {
                                let tmpBlock = _.cloneDeep(block);
                                tmpBlock = _.set(
                                    tmpBlock,
                                    ["data", "bottom_sheet_props", "text"],
                                    value,
                                );
                                setBlock(tmpBlock);
                            }}
                            isFocused={currentEditor === `${id || tmpId}_text`}
                            setEditor={(id: string) => setCurrentEditor(id)}
                            value={block.data.bottom_sheet_props.text}
                            placeholder={"Type your text here..."}
                            required={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Divider />
                        {!disableWrite && (
                            <Form.Item label={"Paste Scale config here"}>
                                <Input.TextArea
                                    disabled={disableWrite}
                                    value={tempConfig}
                                    placeholder="Paste Config here"
                                    onChange={(e) =>
                                        setTempConfig(e.target.value)
                                    }
                                />
                                <Button
                                    disabled={disableWrite}
                                    style={{ marginTop: "10px" }}
                                    type="primary"
                                    onClick={() => {
                                        try {
                                            const parsedJson: any =
                                                JSON.parse(tempConfig);
                                            let tmpBlock = cloneDeep(block);
                                            tmpBlock = set(
                                                tmpBlock,
                                                ["data"],
                                                parsedJson,
                                            );
                                            setBlock(tmpBlock);
                                            setTempConfig("");
                                            message.info("Updated!");
                                        } catch (e) {
                                            //  captureException(e)
                                            message.error("Error in JSON!");
                                        }
                                    }}
                                >
                                    Update scale config
                                </Button>
                            </Form.Item>
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default ButtonEditor;

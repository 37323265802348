import { EditOutlined } from "@ant-design/icons";
import { Can } from "src/services/casl";
import { Breadcrumb, Col, Modal, Row } from "antd";
import { WorksheetForm } from "../../../../index";
import { useState } from "react";

export const WorksheetFormPopup = (props: any) => {
    const {
        onFinish,
        showWorksheetPopup: showWorksheetPopupDefault = false,
        worksheet,
        isReadOnlyMode,
        worksheetRefetch,
    } = props;
    const [showWorksheetPopup, toggleWorksheetPopup] = useState(false);
    const [resetValues, toggleResetValues] = useState(false);

    return (
        <>
            <span style={{ cursor: "pointer" }}>
                <Can I="edit" a={"worksheet_details"} passThrough>
                    {(allowed: boolean) => (
                        <Breadcrumb.Item
                            onClick={() => {
                                if (allowed && !isReadOnlyMode) {
                                    worksheetRefetch().then(() =>
                                        toggleWorksheetPopup(true),
                                    );
                                }
                            }}
                        >
                            <EditOutlined /> {worksheet?.title}
                        </Breadcrumb.Item>
                    )}
                </Can>
            </span>

            <Modal
                title="Worksheet Details"
                style={{ top: 20 }}
                open={showWorksheetPopupDefault || showWorksheetPopup}
                onOk={() => toggleWorksheetPopup(false)}
                onCancel={() => {
                    toggleWorksheetPopup(false);
                    toggleResetValues(true);
                }}
                footer={null}
                closable={!showWorksheetPopupDefault}
                maskClosable={!showWorksheetPopupDefault}
                width={1000}
                destroyOnClose
            >
                <Row justify="center" align="middle">
                    <Col xs={12} lg={20} md={24}>
                        <WorksheetForm
                            {...props}
                            onFinish={(values: any) => {
                                onFinish(values);
                                toggleWorksheetPopup(false);
                            }}
                            toggleResetValues={toggleResetValues}
                            resetValues={resetValues}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

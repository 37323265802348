import { renderer } from "src/modules/worksheet/components/blocks/helpers/index";
import {
    createAutoformatPlugin,
    createBoldPlugin,
    createDeserializeCsvPlugin,
    createDeserializeDocxPlugin,
    createDeserializeMdPlugin,
    createDndPlugin,
    createExitBreakPlugin,
    createItalicPlugin,
    createKbdPlugin,
    createLinkPlugin,
    createNodeIdPlugin,
    createParagraphPlugin,
    createPlateUI,
    createPlugins,
    createResetNodePlugin,
    createSelectOnBackspacePlugin,
    createStrikethroughPlugin,
    createTablePlugin,
    createTrailingBlockPlugin,
    createUnderlinePlugin,
    ELEMENT_IMAGE,
    HeadingToolbar,
    Plate,
    PlateProvider,
    createComboboxPlugin,
    createMentionPlugin,
    MentionCombobox,
    withProps,
} from "@udecode/plate";
import { createJuicePlugin } from "@udecode/plate-juice";
import { setNodes, usePlateEditorRef } from "@udecode/plate-core";
import { Checkbox, Form, Input, Modal } from "antd";
import React from "react";
import { findIndex } from "lodash";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactEditor } from "slate-react";
import "tippy.js/dist/tippy.css";
import {
    MarkBallonToolbar,
    ToolbarButtons,
} from "./config/components/Toolbars";
import { withStyledDraggables } from "./config/components/withStyledDraggables";
import { withStyledPlaceHolders } from "./config/components/withStyledPlaceHolders";
import { CONFIG } from "./config/config";
import { ImageElement } from "./plugins/image/ui/";
import { createLatexPlugin, ELEMENT_LATEX } from "./plugins/latex/node";
import { LatexElement } from "./plugins/latex/ui/";
import { createAudioPlugin, ELEMENT_AUDIO } from "./plugins/audio/node";
import { AudioElement } from "./plugins/audio/ui/";
import { createVideoPlugin, ELEMENT_VIDEO } from "./plugins/video/node";
import { VideoElement } from "./plugins/video/ui/";
import { StyledLeaf } from "@udecode/plate-styled-components";

import { createPluginFactory } from "@udecode/plate-core";
import { basicFontPlugins } from "./config/plugins";
import { MARK_DROP_SHADOW } from "./config/plugins/createFontDropShadowPlugin";
import { ELEMENT_BUTTON, createButtonPlugin } from "./plugins/button/node";
import { ButtonElement } from "./plugins/button/ui";

const { TextArea } = Input;

let components = createPlateUI({
    [ELEMENT_IMAGE]: ImageElement,
    [ELEMENT_LATEX]: LatexElement,
    [ELEMENT_AUDIO]: AudioElement,
    [ELEMENT_VIDEO]: VideoElement,
    [ELEMENT_BUTTON]: ButtonElement,

    // mark
    [MARK_DROP_SHADOW]: withProps(StyledLeaf, {
        nodeProps: {
            [MARK_DROP_SHADOW]: ["filter"],
        },
    }),
});
components = withStyledPlaceHolders(components);
components = withStyledDraggables(components);

export const CommentsContext = React.createContext({});

const CommentsPopup = ({ commentPopup, toggleCommentPopup, element }: any) => {
    const editor = usePlateEditorRef();
    const [comment, setComment] = React.useState(
        element?.comment ? element.comment.value : "",
    );

    const [resolved, setResolved] = React.useState(
        element?.comment?.resolved && true,
    );

    React.useEffect(() => {
        setResolved(element?.comment?.resolved);
        setComment(element?.comment?.value);
    }, [element?.comment?.resolved]);

    const handleOk = () => {
        if (!editor) return;
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        setNodes(
            editor,
            {
                comment: { value: comment, resolved },
            },
            { at: path },
        );
        toggleCommentPopup(false);
    };

    const handleCancel = () => {
        toggleCommentPopup(false);
    };

    return (
        <Modal
            title="Add Comment"
            visible={commentPopup}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form.Item
                label={"Comment"}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <div style={{ border: "1px solid #dbd7d2" }}>
                    <TextArea
                        value={comment}
                        onChange={(e: any) => {
                            setComment(e.target.value);
                        }}
                    />
                </div>
            </Form.Item>

            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Checkbox
                    onChange={(e) => setResolved(e.target.checked)}
                    checked={resolved}
                >
                    is_resolved
                </Checkbox>
            </Form.Item>
        </Modal>
    );
};

/**
 * Enables support for images.
 */
export const createImagePlugin = createPluginFactory({
    key: ELEMENT_IMAGE,
    isElement: true,
    isVoid: true,
    //   withOverrides: withImageUpload,
    then: (editor, { type }) => ({
        deserializeHtml: {
            rules: [
                {
                    validNodeName: "IMG",
                },
            ],
            getNode: (el) => ({
                type,
                url: el.getAttribute("src"),
            }),
        },
    }),
});

export const BaseSlateEditor = ({
    id,
    value,
    onChange,
    allowComments = false,
    isFocused = false,
    mentionsList = [],
    hasMentions = false,
    v2 = false,
    onlyEditor = false,
}: {
    id: string;
    value: any;
    onChange: any;
    allowComments?: boolean;
    isFocused?: boolean;
    mentionsList?: any;
    hasMentions?: boolean;
    v2?: boolean;
    onlyEditor?: boolean;
}) => {
    const [commentPopup, toggleCommentPopup] = React.useState(false);
    const [currentElement, setCurrentElement] = React.useState(null);
    const commentContext = {
        commentPopup,
        toggleCommentPopup,
        currentElement,
        setCurrentElement,
        allowComments,
    };

    const mentionPlugins = hasMentions
        ? [createComboboxPlugin(), createMentionPlugin()]
        : [];
    const pluginsV2: any = [...basicFontPlugins];

    const plugins = createPlugins(
        [
            createParagraphPlugin(),
            createImagePlugin(),
            createLinkPlugin(),
            createTablePlugin(),
            createBoldPlugin(),
            createItalicPlugin(),
            createUnderlinePlugin(),
            createStrikethroughPlugin(),
            createKbdPlugin(),
            createNodeIdPlugin(),
            createDndPlugin(),
            createAutoformatPlugin(CONFIG.autoformat),
            createResetNodePlugin(CONFIG.resetBlockType),
            createExitBreakPlugin(CONFIG.exitBreak),
            createTrailingBlockPlugin(CONFIG.trailingBlock),
            createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
            createDeserializeMdPlugin(),
            createDeserializeCsvPlugin(),
            createDeserializeDocxPlugin(),
            createJuicePlugin(),
            createLatexPlugin(),

            // ...mentionPlugins,
            createAudioPlugin(),
            createVideoPlugin(),
            createButtonPlugin(),
            ...(v2 ? pluginsV2 : []),
        ],
        {
            components,
        },
    );

    const plateValue =
        value && value.length
            ? [...value]
            : [{ type: "p", children: [{ text: "" }] }];

    return isFocused ? (
        onlyEditor ? (
            <>
                <PlateProvider
                    id={id}
                    value={plateValue}
                    plugins={plugins}
                    onChange={(values) => {
                        const newValues = [...values];
                        const index = findIndex(newValues, function (e: any) {
                            return e.type == "img" && e.url == "";
                        });
                        if (index > -1) {
                            newValues.splice(index, 1);
                        }
                        onChange(newValues);
                    }}
                >
                    <HeadingToolbar className="headingToolbar">
                        <ToolbarButtons
                            isFocused={isFocused}
                            v2={v2}
                            onlyEditor={onlyEditor}
                        />
                    </HeadingToolbar>
                    <Plate
                        id={id}
                        editableProps={{
                            ...CONFIG.editableProps,
                            placeholder:
                                CONFIG.editableProps.placeholder +
                                (mentionsList.length > 0
                                    ? "\nRecall information using @\n"
                                    : ""),
                        }}
                    >
                        <MarkBallonToolbar />

                        {/* {mentionsList.length > 0 && (
            <MentionCombobox
                styles={{
                    root: {
                        zIndex: 9999,
                    },
                }}
                trigger="@"
                items={mentionsList}
                onRenderItem={({ item }: any) => item.data}
            />
        )} */}

                        {allowComments && (
                            <CommentsPopup
                                commentPopup={commentPopup}
                                toggleCommentPopup={toggleCommentPopup}
                                element={currentElement}
                            />
                        )}
                    </Plate>
                </PlateProvider>
            </>
        ) : (
            <CommentsContext.Provider value={commentContext}>
                <DndProvider backend={HTML5Backend}>
                    <PlateProvider
                        id={id}
                        value={plateValue}
                        plugins={plugins}
                        onChange={(values) => {
                            const newValues = [...values];
                            const index = findIndex(
                                newValues,
                                function (e: any) {
                                    return e.type == "img" && e.url == "";
                                },
                            );
                            if (index > -1) {
                                newValues.splice(index, 1);
                            }
                            onChange(newValues);
                        }}
                    >
                        <HeadingToolbar className="headingToolbar">
                            <ToolbarButtons isFocused={isFocused} v2={v2} />
                        </HeadingToolbar>
                        <Plate
                            id={id}
                            editableProps={{
                                ...CONFIG.editableProps,
                                placeholder:
                                    CONFIG.editableProps.placeholder +
                                    (mentionsList.length > 0
                                        ? "\nRecall information using @\n"
                                        : ""),
                            }}
                        >
                            <MarkBallonToolbar />

                            {/* {mentionsList.length > 0 && (
                            <MentionCombobox
                                styles={{
                                    root: {
                                        zIndex: 9999,
                                    },
                                }}
                                trigger="@"
                                items={mentionsList}
                                onRenderItem={({ item }: any) => item.data}
                            />
                        )} */}

                            {allowComments && (
                                <CommentsPopup
                                    commentPopup={commentPopup}
                                    toggleCommentPopup={toggleCommentPopup}
                                    element={currentElement}
                                />
                            )}
                        </Plate>
                    </PlateProvider>
                </DndProvider>
            </CommentsContext.Provider>
        )
    ) : null;
};

export const SlateEditor = (props: any) => {
    const {
        id,
        isFocused = true,
        setEditor = () => {},
        value,
        isOption = false,
        placeholder = null,
        textStyle = {},
        addAsterisk,
        mentionStyle,
        disabled,
        required = true,
    } = props;

    const isEmpty = () => {
        if (!value.length) return true;
        return value.every((i: any) => {
            if (
                ["p"].includes(i.type) &&
                i.children.every(({ text, value }: any) => !text && !value)
            )
                return true;

            if (i.type == "latex" && !i.latex) return true;
            if (i.type == "audio" && !i.audio) return true;
            if (i.type == "video" && !i.video) return true;

            if (i.type == "img" && !i.url) return true;

            return false;
        });
    };

    const help =
        required && isEmpty() ? (
            <p style={{ padding: "0px 24px" }}>Field cannot be empty</p>
        ) : (
            ""
        );

    return (
        <div style={{ marginBottom: isOption ? "0px" : "24px" }}>
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Form.Item
                    rules={[
                        {
                            required: required,
                        },
                    ]}
                    validateStatus={required && isEmpty() ? "error" : ""}
                    {...(help == "" ? {} : { help })}
                    style={{ marginBottom: "0px" }}
                >
                    <div
                        style={{
                            border:
                                required && isEmpty()
                                    ? "1px red"
                                    : isFocused
                                    ? "1px #41A9FF"
                                    : "1px lightgrey",
                            margin: "0 auto",
                            padding: "0 10px",
                        }}
                    >
                        <div style={{ display: isFocused ? "block" : "none" }}>
                            <BaseSlateEditor {...props} />
                        </div>

                        <div
                            onClick={() => {
                                if (disabled) return;
                                setEditor(id);
                            }}
                            style={{
                                display: isFocused ? "none" : "block",
                                padding: isFocused
                                    ? "0px"
                                    : `4px ${isOption ? "4px" : "14px"}`,
                                ...textStyle,
                            }}
                        >
                            {!isEmpty()
                                ? renderer(value, mentionStyle)
                                : placeholder ?? "Click here to write"}
                            {addAsterisk && "*"}
                        </div>
                    </div>
                </Form.Item>
            </div>
        </div>
    );
};

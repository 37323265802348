import { get } from "lodash";
import { isEditorEmpty, BLOCK_TYPES } from "../../common";
import { isValidCallbackV2Stories } from "../V2Stories";
import { COMPONENT_TYPES } from "./defaultValue";
import { jumpLogicValid } from "../ProgressLevel/isValid";
const { ALL_IN_ONE } = BLOCK_TYPES;

const isValidCallback = ({
    block,
    worksheet,
    chunkIndex,
    chunkSlug,
    blockIndex,
}: any) => {
    if (!block || !block?.data?.[ALL_IN_ONE])
        return {
            isValid: true,
            error: "",
        };
    const {
        id,
        data: {
            slug: dataSlug,
            [ALL_IN_ONE]: { components = [], logic },
        },
        backend,
    } = block;

    let slug =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.slug
            : dataSlug;
    let compute_functions =
        worksheet?.type === "personalized_learning_v4"
            ? backend?.compute_functions || []
            : logic?.compute_functions;

    let jumpValid = jumpLogicValid({
        functions: compute_functions,
        chunkIndex,
        chunkSlug,
        blockIndex,
        blockSlug: slug,
    });

    const checkId = worksheet?.type === "personalized_learning_v4" ? true : id;
    return {
        isValid:
            jumpValid &&
            checkId &&
            checkQuestionValid(block, worksheet) &&
            checkComponentsValid(components),
        error: <></>,
    };
};

export const checkQuestionValid = (
    block: {
        data: {
            other?:
                | {
                      description?: any;
                      stories?: any;
                  }
                | undefined;
        };
    },
    worksheet: any,
) => {
    const {
        data: { other: { description = [], stories = [] } = {} },
    } = block;
    return !isEditorEmpty(description) && checkStoriesValid(stories, worksheet);
};

const checkStoriesValid = (stories: any[], worksheet: any) => {
    for (let index = 0; index < stories.length; index++) {
        const element = isValidCallbackV2Stories({
            block: stories[index],
            worksheet,
        }).isValid;
        if (!element) return false;
    }
    return true;
};

const checkComponentsValid = (components: any[]) => {
    if (!components?.length) return false;

    for (let index = 0; index < components.length; index++) {
        const element = checkComponentValid(components[index]).isValid;
        if (!element) return false;
    }

    return true;
};

export const checkComponentValid = (component: {
    type?: any;
    data?: any;
    figma?: any;
}) => {
    let element = true;
    switch (component?.type) {
        case COMPONENT_TYPES.RICH_TEXT:
            element = !isEditorEmpty(component?.data?.text || []);
            break;
        case COMPONENT_TYPES.CONDITION:
            element =
                component?.data?.conditions?.length &&
                component?.data?.conditions?.every(
                    (v: { text: any }) => !isEditorEmpty(v?.text || []),
                );
            break;
        case COMPONENT_TYPES.TIMER: {
            const {
                figma: { variables },
            } = component;
            for (let index = 0; index < variables.length; index++) {
                const varVal = variables[index];
                if (
                    [
                        "endTime",
                        "startTime",
                        // "timerType",
                        // "format",
                        // "startTrigger",
                        // "endTrigger",
                    ].includes(varVal?.name) &&
                    !varVal?.value
                ) {
                    element = false;
                    break;
                }
            }
            break;
        }
        case COMPONENT_TYPES.MULTI_SELECT_INPUT: {
            const {
                figma: { variables },
            } = component;
            const varVal = variables?.find(
                (v: { name: string }) => v.name === "schema",
            );
            const value =
                typeof varVal.value === "string"
                    ? JSON.parse(varVal.value)
                    : varVal.value;
            const {
                correct_values,
                props: { keyboard, feedback_type, placeholder },
            } = value;
            if (
                !correct_values?.value?.length ||
                !feedback_type?.value?.trim()
            ) {
                element = false;
            }
            break;
        }
        case COMPONENT_TYPES.TAP_AND_SELECT: {
            const {
                figma: { variables },
            } = component;
            let varMap: any = {};
            variables.forEach((v: { name: string | number; value: any }) => {
                varMap[v?.name] = v.value;
            });
            if (
                !varMap["toastMessage"]?.trim() ||
                !varMap["layout"]?.trim() ||
                !varMap["feedback_type"]?.trim() ||
                !varMap["check_type"]?.trim() ||
                !varMap["Components"]?.length ||
                varMap["Components"]?.some(
                    (v: {
                        props: {
                            text_value: { value: any };
                            text: { value: any };
                        };
                    }) => {
                        if (
                            (v?.props?.text_value &&
                                !v?.props?.text_value?.value) ||
                            isEditorEmpty(v?.props?.text?.value || [])
                        ) {
                            return true;
                        }
                        return false;
                    },
                )
            ) {
                element = false;
            }
            break;
        }
        case COMPONENT_TYPES.DRAG_DROP: {
            const {
                figma: { variables },
            } = component;
            let varMap: any = {};
            variables.forEach((v: { name: string | number; value: any }) => {
                varMap[v?.name] = v.value;
            });
            let clusterName = "dndData:agtlsg12l6pv1z";
            if (!varMap[clusterName]?.length) {
                element = false;
            } else {
                for (
                    let index = 0;
                    index < varMap[clusterName].length;
                    index++
                ) {
                    const val = checkDnDClusterValid(
                        varMap[clusterName][index],
                    );
                    if (!val) {
                        element = false;
                        break;
                    }
                }
            }
            break;
        }
        case COMPONENT_TYPES.GENERIC_TABLE: {
            const {
                figma: { variables },
            } = component;
            let varMap: any = {};
            variables.forEach((v: { name: string | number; value: any }) => {
                varMap[v?.name] = v.value;
            });
            if (!varMap["table_and_tags"]?.length) {
                element = false;
            } else {
                for (
                    let index = 0;
                    index < varMap["table_and_tags"].length;
                    index++
                ) {
                    const val = checkGenericClusterValid(
                        varMap["table_and_tags"][index],
                    );
                    if (!val) {
                        element = false;
                        break;
                    }
                }
            }
            break;
        }
        case COMPONENT_TYPES.MULTI_INPUT: {
            const {
                figma: { variables },
            } = component;
            let varMap: any = {};
            variables.forEach((v: { name: string | number; value: any }) => {
                varMap[v?.name] = v.value;
            });
            if (!varMap["inputs_and_tags"]?.length) {
                element = false;
            } else {
                for (
                    let index = 0;
                    index < varMap["inputs_and_tags"].length;
                    index++
                ) {
                    const val = checkGenericClusterValid(
                        varMap["inputs_and_tags"][index],
                    );
                    if (!val) {
                        element = false;
                        break;
                    }
                }
            }
            break;
        }
        case COMPONENT_TYPES.GEOGEBRA: {
            const {
                figma: { variables },
            } = component;
            let varMap: any = {};
            variables.forEach((v: { name: string | number; value: any }) => {
                varMap[v?.name] = v.value;
            });
            if (
                !varMap["material_id"]?.trim() ||
                !varMap["feedback_type"]?.trim()
            ) {
                element = false;
            }
            break;
        }
        default:
            break;
    }
    if (!element) return { isValid: false };
    return { isValid: true };
};

export const checkGenericClusterValid = (cluster: { type: string }) => {
    switch (cluster?.type) {
        case "tags_collection": {
            const tags = get(cluster, [
                "props",
                "tags_list",
                "value",
                "variables",
                "value",
                "variables",
            ])?.find((v: { name: string }) => v.name === "options")?.value;
            if (typeof tags === "string") return true;
            const val = checkTagClusterValid(tags);
            if (!val) return false;
            break;
        }
        case "table_collection": {
            const table = get(cluster, [
                "props",
                "table",
                "value",
                "variables",
                "value",
                "variables",
            ])?.find((v: { name: string }) => v?.name === "tableData")?.value;
            if (typeof table === "string") return true;
            if (!table?.cells?.length || !table?.cells[0]?.length) return false;
            break;
        }
        case "generic_collection": {
            const items = get(cluster, [
                "props",
                "inputs_list",
                "value",
                "variables",
                "value",
                "variables",
            ])?.find((v: { name: string }) => v.name === "Components")?.value;
            if (typeof items === "string") return true;
            if (!items?.length) return false;
            // const val = checkTagClusterValid(tags);
            // if (!val) return false;
            break;
        }
        case "buttons_collection": {
            const items = get(cluster, [
                "props",
                "buttons_list",
                "value",
                "variables",
                "value",
                "variables",
            ])?.find((v: { name: string }) => v.name === "options")?.value;
            if (typeof items === "string") return true;
            if (!items?.length) return false;
            // const val = checkTagClusterValid(tags);
            // if (!val) return false;
            break;
        }
        default:
            break;
    }
    return true;
};

export const checkDnDClusterValid = (cluster: {
    type: any;
    srcs: { value: any };
    value: any;
}) => {
    switch (cluster?.type) {
        case "tag_cluster": {
            if (cluster?.srcs?.value) return true;
            const tags = get(cluster, ["srcs", "items"]);
            const val = checkDnDTagsValid(tags);
            if (!val) return false;
            break;
        }
        case "item_cluster": {
            if (cluster?.value) return true;
            const items = get(cluster, ["items"]);
            const val = checkDnDItemsValid(items);
            if (!val) return false;
            break;
        }
        default:
            break;
    }
    return true;
};

const checkDnDTagsValid = (tags: any[]) => {
    if (!tags?.length) return false;
    for (let index = 0; index < tags.length; index++) {
        const element = tags[index];
        const tag = {
            text: element?.data?.find((v: { text: any }) => v.text)?.text || [],
            asset: element?.data?.find((v: { img: any }) => v.img)?.img?.asset,
            order: element.data
                ?.filter(
                    (v: { showImg: any; showText: any }) =>
                        v.showImg || v.showText,
                )
                ?.map((v: { text: any }) => (v.text ? "text" : "image")),
            category: element?.category,
            // width: element?.data?.find((v) => v.img)?.width,
            // height: element?.data?.find((v) => v.img)?.height,
        };

        if (
            (isEditorEmpty(tag.text) && !tag.asset) ||
            !tag.order?.length ||
            !tag.category // ||
            // !tag.width ||
            // !tag.height
        )
            return false;
    }
    return true;
};

const checkDnDItemsValid = (items: any[]) => {
    if (!items?.length) return false;
    for (let index = 0; index < items.length; index++) {
        const element = items[index];
        const item = {
            category: element?.category?.length ? element?.category : [],
            order: element.zones
                ?.filter(
                    (v: { showImg: any; showText: any; showItems: any }) =>
                        v.showImg || v.showText || v.showItems,
                )
                ?.map((v: { text: any; img: any }) =>
                    v.text ? "text" : v.img ? "image" : "drop",
                ),
            // width: element?.zones?.find((v: { schema: any }) => v.schema)
            // ?.width,
        };
        if (!item.order?.length || !item.category?.length)
            // || !item.width)
            return false;
    }
    return true;
};

const checkTagClusterValid = (tags: any[]) => {
    if (!tags?.length) return false;
    for (let index = 0; index < tags.length; index++) {
        const element = tags[index];
        if (!element?.props?.drop_value?.value) return false;
    }
    return true;
};

export default isValidCallback;

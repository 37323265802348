import { Typography, Modal, List, Button } from "antd";
import React from "react";

const { Text, Title } = Typography;

interface ChangesModalProps {
    changesList: Record<string, any>;
    isModalOpen: boolean;
    setIsModalOpen: (open: boolean) => void;
    setChangesList: (changes: Record<string, any>) => void; // Add a setter for changesList
}

const ChangesModal: React.FC<ChangesModalProps> = ({
    changesList = {},
    isModalOpen,
    setIsModalOpen,
    setChangesList,
}) => {
    const changesData = Object.entries(changesList);

    const hideModal = () => {
        setIsModalOpen(false);
    };

    const handleRevert = (key: string) => {
        const updatedChangesList = { ...changesList };
        delete updatedChangesList[key]; // Remove the change by key
        setChangesList(updatedChangesList); // Update the changesList state
    };

    return (
        <Modal
            title="Changes List"
            style={{ top: 20 }}
            open={isModalOpen}
            onOk={hideModal}
            onCancel={hideModal}
            footer={null}
            closable={true}
            maskClosable={true}
            width={600}
            destroyOnClose
        >
            {changesData.length === 0 ? (
                <Title type="danger" level={5}>
                    No changes available.
                </Title>
            ) : (
                <List
                    size="large"
                    bordered
                    dataSource={changesData}
                    renderItem={([key, _]: [string, any], index: number) => (
                        <List.Item key={index}>
                            <List.Item.Meta title={<Text strong>{key}</Text>} />
                            <Button
                                type="default"
                                onClick={() => handleRevert(key)}
                            >
                                Revert
                            </Button>
                        </List.Item>
                    )}
                />
            )}
        </Modal>
    );
};

export default ChangesModal;

import React, { useState } from "react";
import { setNodes, unsetNodes } from "@udecode/plate-core";
import { Transforms } from "slate";
import { getRootProps } from "@udecode/plate-styled-components";

import { ReactEditor, useFocused, useSelected } from "slate-react";
import { ButtonInsertComponent } from "../ButtonInsertComponent";

export const ButtonElement = (props: any) => {
    const { attributes, children, element, nodeProps, editor } = props;

    const rootProps = getRootProps(props);

    const { button, config, align = "flex-start" } = element;

    const onChangeButton = (val: string) => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        setNodes(editor, { ...element, ...val }, { at: path });
    };

    const onChangeAlign = (newAlign: "flex-start" | "center" | "flex-end") => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        setNodes(editor, { ...element, align: newAlign }, { at: path });
    };

    const onDeleteButton = () => {
        const path = ReactEditor.findPath(editor as ReactEditor, element);
        // console.log(path);
        Transforms.delete(editor, { at: path });
    };

    return (
        <div {...attributes} {...rootProps} {...nodeProps}>
            <div
                contentEditable={false}
                // style={{
                //   userSelect: "none",
                //   border: selected ? "1px solid #ACCEF7" : "none",
                // }}
            >
                <ButtonInsertComponent
                    onChange={onChangeButton}
                    onDelete={onDeleteButton}
                    value={button}
                    config={config}
                    align={align}
                    onChangeAlign={onChangeAlign}
                />
            </div>
            {children}
        </div>
    );
};

function calculateMultiStepMovementVectorsWithCachedGaps(cells) {
    if (!Array.isArray(cells) || !cells.every((row) => Array.isArray(row))) {
        throw new Error("Input must be a 2D array.");
    }

    let firstCell;
    for (const row of cells) {
        for (const cell of row) {
            if (cell?.dragDrop?.enabled) {
                firstCell = cell;
                break;
            }
        }
        if (firstCell) break;
    }
    if (!firstCell) firstCell = cells[0][0];
    if (
        !firstCell?.drawing?.dimension?.width ||
        !firstCell.drawing.dimension?.height
    ) {
        throw new Error("Cell dimensions are missing in the first cell.");
    }

    // Get dimensions of the first cell
    const width = firstCell.drawing.dimension.width;
    const height = firstCell.drawing.dimension.height;

    const { x: firstX, y: firstY } = firstCell.drawing.position;

    // Create Sets to store all unique x and y values
    const xSet = new Set();
    const ySet = new Set();

    cells.forEach((row) =>
        row.forEach((cell) => {
            if (cell?.dragDrop?.enabled) {
                const { x, y } = cell.drawing.position;
                xSet.add(x);
                ySet.add(y);
            }
        }),
    );

    // Convert Sets to Sorted Arrays
    const sortedX = Array.from(xSet).sort((a, b) => Number(a) - Number(b));
    const sortedY = Array.from(ySet).sort((a, b) => Number(a) - Number(b));

    // Find the nearest larger x and y
    const nextX = sortedX.find((val) => Number(val) >= firstX + width);
    const nextY = sortedY.find((val) => Number(val) >= firstY + height);

    const horizontalGap =
        nextX !== undefined ? Number(nextX) - (firstX + width) : null;
    const verticalGap =
        nextY !== undefined ? Number(nextY) - (firstY + height) : null;

    if (horizontalGap === null || verticalGap === null) {
        throw new Error("Unable to calculate gaps between cells.");
    }

    // Flatten the grid for easier lookup
    const flatCells = cells.flat();

    // Helper function to find a cell by its position
    const findCellByPosition = (targetX, targetY) =>
        flatCells.find((cell) => {
            if (!cell?.drawing?.position) return false;
            if (!cell?.dragDrop?.enabled) return false;
            const { x, y } = cell.drawing?.position || {};
            const margin = 5; // Allowable margin of error
            return (
                x !== undefined &&
                y !== undefined &&
                Math.abs(x - targetX) <= margin &&
                Math.abs(y - targetY) <= margin
            );
        });

    // Iterate over all cells to calculate movement vectors
    const updatedCells = cells.map((row) =>
        row.map((cell) => {
            if (cell?.dragDrop?.enabled) {
                const { x, y } = cell.drawing.position;

                // Define possible movement directions
                const directions = [
                    { dx: width + horizontalGap, dy: 0 }, // Right
                    { dx: -(width + horizontalGap), dy: 0 }, // Left
                    { dx: 0, dy: height + verticalGap }, // Down
                    { dx: 0, dy: -(height + verticalGap) }, // Up
                ];

                const validVectors = [];

                // Explore each direction for multi-step movement
                directions.forEach(({ dx, dy }) => {
                    let step = 1;
                    let reachable = true;

                    while (reachable) {
                        const targetX = x + dx * step;
                        const targetY = y + dy * step;
                        const targetCell = findCellByPosition(targetX, targetY);

                        if (!targetCell || targetCell.dragDrop?.droppedSource) {
                            reachable = false; // Stop if cell is out of bounds or occupied
                        } else {
                            validVectors.push({ dx: dx * step, dy: dy * step });
                        }

                        step++;
                    }
                });

                // Update the cell's dragDrop object with valid movement vectors
                return {
                    ...cell,
                    dragDrop: {
                        ...cell.dragDrop,
                        movementVectors: validVectors,
                    },
                };
            }
            return cell;
        }),
    );

    return {
        cells: updatedCells,
        width,
        height,
        horizontalGap,
        verticalGap,
    };
}

function modifyMatchingCells(cells) {
    // Ensure the target array is valid
    if (!Array.isArray(cells) || !Array.isArray(cells[0])) {
        throw new Error("Invalid cells structure");
    }

    const seenGenericIds = new Map();

    // Iterate through all cells
    cells[0].forEach((cell, index) => {
        if (!cell?.dragDrop?.droppedSource) return;

        const { genericId = "" } = cell.dragDrop.droppedSource;

        if (seenGenericIds.has(genericId)) {
            const occurrences = seenGenericIds.get(genericId);

            // Determine the number of characters to remove based on frequency
            const charsToRemove = occurrences.length + 1; // +1 for the current occurrence

            // Remove characters from the current cell's genericId
            if (genericId) {
                cell.dragDrop.droppedSource.genericId = genericId.slice(
                    0,
                    -charsToRemove,
                );
            }

            occurrences.push(index);
        } else {
            seenGenericIds.set(genericId, [index]);
        }
    });

    return cells;
}

function copyGenericIdToSourceId(array2D: any) {
    let updatedArray2D = modifyMatchingCells(array2D);
    updatedArray2D = array2D.map((row: any) =>
        row.map((item: any) => {
            if (item.dragDrop?.droppedSource) {
                return {
                    ...item,
                    dragDrop: {
                        ...item.dragDrop,
                        droppedSource: {
                            ...item.dragDrop.droppedSource,
                            source_id: item.dragDrop.droppedSource.genericId,
                        },
                    },
                };
            }
            return item;
        }),
    );
    console.log(updatedArray2D);
    return updatedArray2D;
}

export {
    calculateMultiStepMovementVectorsWithCachedGaps,
    copyGenericIdToSourceId,
};

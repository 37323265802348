import CancelIcon from "src/components/CancelIcon";
import { Form, Input, Button, Card, Row, Col, Switch, Radio } from "antd";
import { useEffect, useRef, useState } from "react";
import { AudioGenerateComponent } from "src/modules/worksheet/components/WorksheetEditor/components/Modals/AudioGenerateComponent";
import { SlateEditor } from "src/components/plate";
const { TextArea } = Input;
const FormItem = Form.Item;

const alignmentOptions = [
    { label: "Left", value: "flex-start" },
    { label: "Center", value: "center" },
    { label: "Right", value: "flex-end" },
];

type ButtonConfig = {
    description?: any[];
};

type ButtonInsertProps = {
    onChange: (val: {
        button: string;
        config: ButtonConfig;
        align: string;
    }) => void;
    onDelete: () => void;
    value: string;
    align?: string;
    config?: ButtonConfig;
};

export const ButtonInsertComponent = (props: ButtonInsertProps) => {
    const {
        onChange,
        onDelete,
        value,
        align: initialAlign = "flex-start",
    } = props;
    const inputRef = useRef<any>();

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const [val, setVal] = useState(value);
    const [align, setAlign] = useState(initialAlign);
    const [config, setConfig] = useState(props.config ?? {});
    const updateVal = (val: any) => {
        /* Make update synchronous, to avoid caret jumping when the value doesn't change asynchronously */
        setVal(val);
        /* Make the real update afterwards */
        onChange({ button: val, config });
    };
    const updateConfig = (type, value) => {
        setConfig((prev: any) => {
            onChange({ button: val, config: { ...prev, [type]: value } });
            return { ...prev, [type]: value };
        });
    };

    const updateAlign = (newAlign: string) => {
        setAlign(newAlign);
        onChange({ button: val, config, align: newAlign });
    };

    return (
        <Card>
            <Form layout={"inline"} component={false}>
                <Row gutter={12}>
                    <Col span={12}>
                        <FormItem className="audio-insert-form-item">
                            <TextArea
                                placeholder="type button text"
                                value={val}
                                onChange={(e) => updateVal(e.target.value)}
                                ref={inputRef}
                                autoSize
                            />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem label="Alignment">
                            <Radio.Group
                                value={align}
                                onChange={(e) => updateAlign(e.target.value)}
                                buttonStyle="solid"
                            >
                                {alignmentOptions.map((option) => (
                                    <Radio.Button
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </FormItem>
                    </Col>

                    <Col span={24}>
                        <FormItem label={"Bottom sheet Description"}>
                            <SlateEditor
                                // disabled={disableWrite}
                                // hasMentions={hasMentions}
                                // mentionsList={mentionsList}
                                id={`_description`}
                                onChange={(value: any) => {
                                    updateConfig("description", value);
                                }}
                                isFocused={true}
                                // setEditor={(id: string) => setCurrentEditor(id)}
                                value={config?.description ?? []}
                                placeholder={"Type your description here..."}
                                onlyEditor={true}
                            />
                        </FormItem>
                    </Col>

                    <Col>
                        <FormItem
                            className="audio-insert-form-item"
                            style={{ marginLeft: "10px" }}
                        >
                            <CancelIcon
                                isButton={true}
                                onClick={() => {
                                    onDelete();
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

import {
    getPluginType,
    insertNodes,
    PlateEditor,
    TElement,
} from "@udecode/plate-core";
import { ELEMENT_BUTTON } from "../createButtonPlugin";

export const insertButton = (editor: PlateEditor, buttonString: string) => {
    const text = { text: buttonString };
    const button = {
        type: getPluginType(editor, ELEMENT_BUTTON),
        children: [text],
    };
    insertNodes<TElement>(editor, button);
};

import { useEffect, useState } from "react";
import { Button, Modal, List, Space, message, Card } from "antd";
import _, { cloneDeep, set } from "lodash";
import { addNewStory } from "src/modules/worksheet/components/blocks/helpers/story";
import { isDynamicText } from "../../../helpers/generateAudios";
import { DeleteIcon } from "src/components";
import { useLazyQuery } from "@apollo/client";
import SyncBlockSearchQuery from "src/modules/worksheet/graphql/SyncBlockSearchQuery";
import { applyChanges } from "src/modules/worksheet/views/update/container";

export const StoryJobs = ({
    storyJobs,
    worksheetStoryJobs,
    isReadOnlyMode,
    setBlock,
    blocks: levelBlocks,
    updateStoriesJob,
    updateAudiosJob,
    audioJobs,
    worksheetAudioJobs,
    setBlocks,
    toggleHasChanged,
    setCurrentBlock,
    setCurrentSubBlock,
    setCurrentChunkBlock,
    worksheet,
}: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const [getSyncBlocks, { data }] = useLazyQuery(SyncBlockSearchQuery);
    const [syncBlocks, setSyncBlocks] = useState([]);
    useEffect(() => {
        getSyncBlocks();
    }, []); // Only runs if getSyncBlocks or localSelectedFlag changes

    useEffect(() => {
        if (data?.blocks) {
            setSyncBlocks(data.blocks); // Set syncBlocks state when data is available
        }
    }, [data]); // Runs when data changes

    useEffect(() => {
        processLevelBlocks(levelBlocks, syncBlocks);
    }, [syncBlocks, levelBlocks]);

    const processLevelBlocks = (levelBlocks, syncBlocks) => {
        // Array to store all variables lists from processed children
        const variablesListPerBlock = {};

        // Traverse function to recursively process each object
        const traverse = (obj, blockId) => {
            if (Array.isArray(obj)) {
                obj.forEach((child) => traverse(child, blockId));
            } else if (obj && typeof obj === "object") {
                // Check if the object is a "chunk" with children
                if (obj.type === "chunk" && Array.isArray(obj.children)) {
                    obj.children.forEach((child, index) => {
                        // Check if child's data is just an `id` field
                        if (
                            (child?.data && child.data.id) ||
                            child.data.changes_list
                        ) {
                            const childId = child.data.id;

                            // Find matching sync block by ID
                            const matchingSyncBlock = syncBlocks.find(
                                (syncBlock) => syncBlock.id === childId,
                            );

                            if (matchingSyncBlock) {
                                let newMatchingSyncBlock = applyChanges(
                                    matchingSyncBlock,
                                    child?.data?.changes_list,
                                );
                                // Add the variables_list if it exists

                                if (newMatchingSyncBlock.data?.variables_list) {
                                    if (!variablesListPerBlock[blockId]) {
                                        variablesListPerBlock[blockId] = [];
                                    }
                                    variablesListPerBlock[blockId].push(
                                        newMatchingSyncBlock?.data
                                            ?.variables_list,
                                    );
                                }
                                // Create a new child object with the data from the sync block
                                obj.children[index] = {
                                    ...child,
                                    data: {
                                        ...newMatchingSyncBlock.data,
                                        changes_list: child.data.changes_list,
                                    }, // shallow copy to avoid modifying read-only properties
                                    backend: {
                                        ...newMatchingSyncBlock.backend,
                                    },
                                    is_modified: true,
                                    master_block: newMatchingSyncBlock.id,
                                };
                            }
                        }
                    });
                }

                // Recursively process other properties of the object if necessary
                Object.values(obj).forEach((value) => traverse(value, blockId));
            }
        };

        // Start traversal from the root object

        levelBlocks.forEach((block) => {
            traverse(block, block.id); // Pass the block ID to track variables per block
        });

        levelBlocks.forEach((block) => {
            const blockId = block.id;

            if (variablesListPerBlock[blockId]) {
                const variablesData = variablesListPerBlock[blockId];

                // Merge base variables
                block.backend.global_context_variables.base_variables = [
                    ...(block.backend.global_context_variables.base_variables ||
                        []),
                    ...[].concat(
                        ...variablesData.map(
                            (item) => item.base_variables || [],
                        ),
                    ), // Merge base_variables
                ];

                // Merge derived variables
                block.backend.global_context_variables.derived_variables = [
                    ...(block.backend.global_context_variables
                        .derived_variables || []),
                    ...[].concat(
                        ...variablesData.map(
                            (item) => item.derived_variables || [],
                        ),
                    ), // Merge derived_variables
                ];
            }
        });

        // Return the updated levelBlocks after processing
        return levelBlocks;
    };

    const onStorySync = async (v: any) => {
        for (let i = 0; i < levelBlocks.length; i++) {
            const { children = [] } = levelBlocks[i];
            for (let j = 0; j < children.length; j++) {
                const {
                    data: {
                        chunk: { blocks: v3Blocks = [] },
                    },
                    children: v4Blocks = [],
                } = children[j];

                let blocks =
                    worksheet?.type === "personalized_learning_v4"
                        ? v4Blocks
                        : v3Blocks;
                for (let k = 0; k < blocks.length; k++) {
                    const block = blocks[k];
                    if (
                        block?.id === v?.path[0] ||
                        block?.data?.storiesId === v?.path[0]
                    ) {
                        let newStories = storyJobs
                            ?.find((s: { id: any }) => s.id == v.job_id)
                            ?.data?.results?.filter((v) => v?.name)
                            .map((v, i) =>
                                addNewStory({
                                    ...v,
                                    order: i + 1,
                                }),
                            );
                        if (!newStories?.length) {
                            message.info("No Stories found!");
                            return;
                        }
                        let tmpBlock = cloneDeep(levelBlocks[i]);
                        if (v?.path?.length === 1) {
                            tmpBlock = set(
                                tmpBlock,
                                worksheet?.type === "personalized_learning_v4"
                                    ? [
                                          "children",
                                          j,
                                          "children",
                                          k,
                                          "data",
                                          "children",
                                      ]
                                    : [
                                          "children",
                                          j,
                                          "data",
                                          "chunk",
                                          "blocks",
                                          k,
                                          "data",
                                          "children",
                                      ],
                                newStories,
                            );
                        } else {
                            const l = block?.data?.other?.stories?.findIndex(
                                (vid: { id: any }) =>
                                    vid.id == v?.path[v?.path?.length - 1],
                            );
                            tmpBlock = set(
                                tmpBlock,
                                worksheet?.type === "personalized_learning_v4"
                                    ? [
                                          "children",
                                          j,
                                          "children",
                                          k,
                                          "data",
                                          "other",
                                          "stories",
                                          l,
                                          "data",
                                          "children",
                                      ]
                                    : [
                                          "children",
                                          j,
                                          "data",
                                          "chunk",
                                          "blocks",
                                          k,
                                          "data",
                                          "other",
                                          "stories",
                                          l,
                                          "data",
                                          "children",
                                      ],
                                newStories,
                            );
                        }
                        setBlock(tmpBlock, i);
                        const retVal = await updateStoriesJob({
                            job_id: v?.job_id,
                        });
                        if (retVal) {
                            message.success("Stories Added!");
                            setCurrentBlock(i);
                            setCurrentSubBlock(j);
                            setCurrentChunkBlock(k);
                        } else {
                            message.error("Could not add stories!");
                        }
                        return;
                    }
                }
            }
        }
        message.info("Block not found!");
    };

    const onAudioSync = async (v: any) => {
        // NEEDS UPDATE
        const TEXT_PATH = ["audio", "audio_texts", "0", "text"];
        const AUDIO_PATH = [
            "audio",
            "audio_texts",
            "0",
            "audio_urls",
            "0",
            "url",
        ];
        const ACTIVE_PATH = [
            "audio",
            "audio_texts",
            "0",
            "audio_urls",
            "0",
            "is_active",
        ];

        const BLOCKS_PATH =
            worksheet?.type === "personalized_learning_v4"
                ? ["children"]
                : ["data", "chunk", "blocks"];
        const ALL_IN_ONE_PATH = ["data", "all_in_one"];
        const V2_STORY_PATH = ["data", "v2_story"];
        const BG_AUDIO_LIST_PATH = ["bg_audio_list"];
        const QUESTION_AUDIO_PATH = ["question_audio"];
        const PREVIEW_PATH = ["data", "other"];
        const STORIES_PATH = ["data", "other", "stories"];
        const STORY_BLOCK_PATH = ["data", "children"];
        const STORY_BLOCK_STORY_PATH = ["data", "other"];

        const currJob = audioJobs?.find((s: { id: any }) => s.id == v.job_id);
        const audioMap = currJob?.data?.textToAudioMap ?? {};
        const levels = _.cloneDeep(levelBlocks);

        levels.forEach((b: any, index: number) => {
            // const blockPath = [index, ...BLOCKS_PATH];
            // const allBlocks = _.get(blocks, blockPath);
            const chunks = b?.children;
            chunks?.forEach((chunk: any) => {
                const allBlocks = _.get(chunk, BLOCKS_PATH);
                allBlocks?.forEach((block: any) => {
                    switch (block.type) {
                        case "all_in_one": {
                            const previewTextPath = [
                                ...PREVIEW_PATH,
                                ...TEXT_PATH,
                            ];
                            const previewAudioPath = [
                                ...PREVIEW_PATH,
                                ...AUDIO_PATH,
                            ];
                            const previewAudioActivePath = [
                                ...PREVIEW_PATH,
                                ...ACTIVE_PATH,
                            ];

                            const audio = _.get(block, previewAudioPath);
                            const previewAudioText = _.get(
                                block,
                                previewTextPath,
                            );
                            const generatedAudio = audioMap[previewAudioText];
                            const dynamicText =
                                previewAudioText &&
                                isDynamicText(previewAudioText);
                            if (!audio && (generatedAudio || dynamicText)) {
                                const audio_url = dynamicText
                                    ? ""
                                    : generatedAudio.audio_url;
                                _.set(block, previewAudioPath, audio_url);
                                _.set(block, previewAudioActivePath, true);
                                // console.log("preview audio", _.set(block, previewAudioActivePath, true));
                            }

                            const questionAudioPath = [
                                ...ALL_IN_ONE_PATH,
                                ...QUESTION_AUDIO_PATH,
                            ];
                            const questionAudios = _.get(
                                block,
                                questionAudioPath,
                            );

                            questionAudios?.forEach((qa: any) => {
                                const audioText = _.get(qa, TEXT_PATH);
                                const audio = _.get(qa, AUDIO_PATH);
                                const generatedAudio = audioMap[audioText];
                                const dynamicText =
                                    audioText && isDynamicText(audioText);

                                if (!audio && (generatedAudio || dynamicText)) {
                                    const audio_url = dynamicText
                                        ? ""
                                        : generatedAudio.audio_url;
                                    _.set(qa, AUDIO_PATH, audio_url);
                                    _.set(qa, ACTIVE_PATH, true);
                                    // console.log("question audio", _.set(qa, ACTIVE_PATH, true));
                                }
                            });

                            const stories = _.get(block, STORIES_PATH);
                            stories?.forEach((story: any) => {
                                const storyChildren = _.get(
                                    story,
                                    STORY_BLOCK_PATH,
                                );
                                storyChildren?.forEach((storyChild: any) => {
                                    const textPath = [
                                        ...STORY_BLOCK_STORY_PATH,
                                        ...TEXT_PATH,
                                    ];
                                    const audioPath = [
                                        ...STORY_BLOCK_STORY_PATH,
                                        ...AUDIO_PATH,
                                    ];
                                    const activePath = [
                                        ...STORY_BLOCK_STORY_PATH,
                                        ...ACTIVE_PATH,
                                    ];

                                    const audioText = _.get(
                                        storyChild,
                                        textPath,
                                    );
                                    if (audioText) {
                                        const audio = _.get(
                                            storyChild,
                                            audioPath,
                                        );
                                        const generatedAudio =
                                            audioMap[audioText];
                                        const dynamicText =
                                            isDynamicText(audioText);

                                        if (
                                            !audio &&
                                            (generatedAudio || dynamicText)
                                        ) {
                                            const audio_url = dynamicText
                                                ? ""
                                                : generatedAudio.audio_url;
                                            _.set(
                                                storyChild,
                                                audioPath,
                                                audio_url,
                                            );
                                            _.set(storyChild, activePath, true);
                                            // console.log("question story", _.set(storyChild, activePath, true));
                                        }
                                    }

                                    const bgAudioListPath = [
                                        ...V2_STORY_PATH,
                                        ...BG_AUDIO_LIST_PATH,
                                    ];
                                    const bgAudiosList = _.get(
                                        storyChild,
                                        bgAudioListPath,
                                    );
                                    bgAudiosList?.forEach((qa: any) => {
                                        const audioText = _.get(qa, TEXT_PATH);
                                        const audio = _.get(qa, AUDIO_PATH);

                                        const generatedAudio =
                                            audioMap[audioText];
                                        const dynamicText =
                                            audioText &&
                                            isDynamicText(audioText);

                                        if (
                                            !audio &&
                                            (generatedAudio || dynamicText)
                                        ) {
                                            const audio_url = dynamicText
                                                ? ""
                                                : generatedAudio?.audio_url;
                                            _.set(qa, AUDIO_PATH, audio_url);
                                            _.set(qa, ACTIVE_PATH, true);
                                            // console.log("question audio", _.set(qa, ACTIVE_PATH, true));
                                        }
                                    });
                                });
                            });

                            break;
                        }
                        case "v2_stories": {
                            const storyChildren = _.get(
                                block,
                                STORY_BLOCK_PATH,
                            );
                            storyChildren?.forEach((storyChild: any) => {
                                const textPath = [
                                    ...STORY_BLOCK_STORY_PATH,
                                    ...TEXT_PATH,
                                ];
                                const audioPath = [
                                    ...STORY_BLOCK_STORY_PATH,
                                    ...AUDIO_PATH,
                                ];
                                const activePath = [
                                    ...STORY_BLOCK_STORY_PATH,
                                    ...ACTIVE_PATH,
                                ];

                                const audioText = _.get(storyChild, textPath);
                                if (audioText) {
                                    const audio = _.get(storyChild, audioPath);
                                    const generatedAudio = audioMap[audioText];
                                    const dynamicText =
                                        isDynamicText(audioText);

                                    if (
                                        !audio &&
                                        (generatedAudio || dynamicText)
                                    ) {
                                        const audio_url = dynamicText
                                            ? ""
                                            : generatedAudio.audio_url;
                                        _.set(storyChild, audioPath, audio_url);
                                        _.set(storyChild, activePath, true);
                                        // console.log("story block", _.set(storyChild, activePath, true));
                                    }
                                }
                                const bgAudioListPath = [
                                    ...V2_STORY_PATH,
                                    ...BG_AUDIO_LIST_PATH,
                                ];
                                const bgAudiosList = _.get(
                                    storyChild,
                                    bgAudioListPath,
                                );
                                bgAudiosList?.forEach((qa: any) => {
                                    const audioText = _.get(qa, TEXT_PATH);
                                    const audio = _.get(qa, AUDIO_PATH);

                                    const generatedAudio = audioMap[audioText];
                                    const dynamicText =
                                        audioText && isDynamicText(audioText);

                                    if (
                                        !audio &&
                                        (generatedAudio || dynamicText)
                                    ) {
                                        const audio_url = dynamicText
                                            ? ""
                                            : generatedAudio?.audio_url;
                                        _.set(qa, AUDIO_PATH, audio_url);
                                        _.set(qa, ACTIVE_PATH, true);
                                        // console.log("question audio", _.set(qa, ACTIVE_PATH, true));
                                    }
                                });
                            });
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                });
            });
        });
        setBlocks(levels);
        toggleHasChanged(true);
        let res = await updateAudiosJob({ job_id: v?.job_id });
        if (res) message.success("Audios Added! Please publish!");
    };

    const hasUnsyncedJobs =
        (worksheetStoryJobs?.length &&
            worksheetStoryJobs.some((v: { is_sync: any }) => !v.is_sync)) ||
        (worksheetAudioJobs?.length &&
            worksheetAudioJobs.some((v: { is_sync: any }) => !v.is_sync));
    // Inside your component
    const [blockStates, setBlockStates] = useState<{ [key: string]: boolean }>(
        {},
    );
    const calculateOutline = (blockId: string) => {
        // Check the state for the blockId to determine if it has an empty backend
        return blockStates[blockId] ? "2px solid red" : "none";
    };

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Space>
                <Button
                    type="primary"
                    disabled={isReadOnlyMode}
                    danger={!!hasUnsyncedJobs}
                    onClick={showModal}
                >
                    Story & Audio Jobs
                </Button>
                {!!hasUnsyncedJobs && (
                    <span style={{ color: "red" }}>Not synced</span>
                )}
            </Space>
            <Modal
                title="Story & Audio Jobs"
                open={isModalOpen}
                onCancel={handleOk}
                footer={null}
                destroyOnClose
            >
                <Card title="Audio Jobs">
                    <List
                        itemLayout="horizontal"
                        dataSource={worksheetAudioJobs || []}
                        renderItem={(v: any) => (
                            <List.Item
                                actions={[
                                    v.is_sync ? (
                                        <span>Synced</span>
                                    ) : audioJobs?.find(
                                          (s: { id: any }) => s.id == v.job_id,
                                      )?.status == "success" ? (
                                        <Button
                                            type="ghost"
                                            onClick={() => onAudioSync(v)}
                                        >
                                            Sync
                                        </Button>
                                    ) : (
                                        <span>
                                            {audioJobs?.find(
                                                (s: { id: any }) =>
                                                    s.id == v.job_id,
                                            )?.status || "ongoing"}
                                        </span>
                                    ),
                                    <DeleteIcon
                                        onConfirm={async () => {
                                            await updateAudiosJob({
                                                job_id: v?.job_id,
                                                is_delete: true,
                                            });
                                            message.success(
                                                "successfully removed",
                                            );
                                        }}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    title={`${v.job_id}: Audios for worksheet`}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
                <Card title="Story Jobs">
                    <List
                        itemLayout="horizontal"
                        dataSource={worksheetStoryJobs || []}
                        renderItem={(v: any) => (
                            <List.Item
                                actions={[
                                    v.is_sync ? (
                                        <span>Synced</span>
                                    ) : storyJobs?.find(
                                          (s: { id: any }) => s.id == v.job_id,
                                      )?.status == "success" ? (
                                        <Button
                                            type="primary"
                                            ghost
                                            onClick={() => onStorySync(v)}
                                        >
                                            Sync
                                        </Button>
                                    ) : (
                                        <span>
                                            {storyJobs?.find(
                                                (s: { id: any }) =>
                                                    s.id == v.job_id,
                                            )?.status || "ongoing"}
                                        </span>
                                    ),
                                    <DeleteIcon
                                        onConfirm={async () => {
                                            await updateStoriesJob({
                                                job_id: v?.job_id,
                                                is_delete: true,
                                            });
                                            message.success(
                                                "successfully removed",
                                            );
                                        }}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                // Reset the states for all blocks
                                                setBlockStates({});

                                                for (
                                                    let i = 0;
                                                    i < levelBlocks.length;
                                                    i++
                                                ) {
                                                    const { children = [] } =
                                                        levelBlocks[i];
                                                    for (
                                                        let j = 0;
                                                        j < children.length;
                                                        j++
                                                    ) {
                                                        const {
                                                            data: {
                                                                chunk: {
                                                                    blocks = [],
                                                                },
                                                            },
                                                        } = children[j];
                                                        for (
                                                            let k = 0;
                                                            k < blocks.length;
                                                            k++
                                                        ) {
                                                            const block =
                                                                blocks[k];
                                                            if (
                                                                block?.id ===
                                                                v?.path[0]
                                                            ) {
                                                                // Set hasEmptyBackend for the block based on whether backend exists or not
                                                                setBlockStates(
                                                                    (
                                                                        prevStates,
                                                                    ) => ({
                                                                        ...prevStates,
                                                                        [block.id]:
                                                                            !block.backend, // Mark true if backend is empty
                                                                    }),
                                                                );

                                                                setCurrentBlock(
                                                                    i,
                                                                );
                                                                setCurrentSubBlock(
                                                                    j,
                                                                );
                                                                setCurrentChunkBlock(
                                                                    k,
                                                                );
                                                                handleOk();
                                                                return;
                                                            }
                                                        }
                                                    }
                                                }
                                            }}
                                            style={{
                                                outline: calculateOutline(
                                                    v?.path[0],
                                                ), // Use the calculated outline here
                                                outlineOffset: "2px", // Optional: Add spacing around the outline
                                            }}
                                        >
                                            {/* Button Text */}
                                            {v.name
                                                ? `${v.job_id}: ${v.name} `
                                                : `Job ID: ${v.job_id}`}
                                        </Button>
                                    }
                                    description={
                                        v.name
                                            ? ``
                                            : `Path: ${v?.path?.join(",")}`
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </Modal>
        </div>
    );
};
